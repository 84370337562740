import { GetNftsInfoResponse, GetNftsSummaryResponse } from '../generated'

export type GetNftsInfoQuery = {
  __typename: 'Query'
  getNftsInfo: GetNftsInfoResponse
}

export const GetNftsInfoDocument = `
query GetNftsInfo($lastEvaluatedHk: String, $walletAddress: ChainAddress!) {
  getNftsInfo(lastEvaluatedHk: $lastEvaluatedHk, walletAddress: $walletAddress) {
    ... on GetNftsInfoResponseSuccess {
      lastEvaluatedHk
      nfts {
        tokenId
        contractName
        blockNumber
        metadata {
          image_transparent
          brain_genus
        }
      }
      __typename
    }
    ... on GetNftsInfoResponseFailure {
      reason {
        __typename
      }
    }
  }
}`

export type GetNftsSummaryQuery = {
  __typename: 'Query'
  getNftsSummary: GetNftsSummaryResponse
}

export const GetNftsSummaryDocument = `
query GetNftsSummary($walletAddress: ChainAddress!) {
  getNftsSummary(walletAddress: $walletAddress) {
    ... on GetNftsSummaryResponseSuccess {
      __typename
      numberOfBrains
      numberOfGen2Brains
      numberOfGenesisBrains
    }
    ... on GetNftsSummaryResponseFailure {
      reason {
        __typename
      }
    }
  }
}`
