import { useFlags } from 'launchdarkly-react-client-sdk'
import { useEffect, useState } from 'react'

const useAutoRefreshPopup = (timeout: number) => {
  const { currentJsVersion } = useFlags()
  const [isOpen, setIsOpen] = useState(false)

  // refresh page if new js version comes
  const [savedJsVersion, setSavedJsVersion] = useState('')
  useEffect(() => {
    // disabled or hasn't loaded LP value
    if (currentJsVersion === '' || currentJsVersion === undefined) {
      return
    }

    // first updating the JS version
    if (savedJsVersion === '' && currentJsVersion) {
      setSavedJsVersion(currentJsVersion)
    }
    // refresh if version is different
    else if (currentJsVersion !== savedJsVersion) {
      setSavedJsVersion(currentJsVersion)
      setIsOpen(true)
    }
  }, [currentJsVersion, setIsOpen, savedJsVersion])

  // countdown logic
  const [countdown, setCountdown] = useState(timeout)
  useEffect(() => {
    if (isOpen) {
      const timer = setInterval(() => {
        setCountdown(time => time - 1)
      }, 1000)
      return () => {
        clearInterval(timer)
      }
    }
  }, [isOpen])

  return { countdown, isOpen }
}

export default useAutoRefreshPopup
