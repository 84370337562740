import { Link } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import { styled, css } from '@mui/material/styles'

import { Paths } from 'src/utils/routes'
import ASMLogo from 'src/assets/images/asm-logo.png'
import { ReactComponent as DiscordIcon } from 'src/assets/icons/social-icons/discord.svg'
import { ReactComponent as InstagramIcon } from 'src/assets/icons/social-icons/instagram.svg'
import { ReactComponent as LinkedInIcon } from 'src/assets/icons/social-icons/linkedin.svg'
import { ReactComponent as MediumIcon } from 'src/assets/icons/social-icons/medium.svg'
import { ReactComponent as TwitchIcon } from 'src/assets/icons/social-icons/twitch.svg'
import { ReactComponent as TwitterIcon } from 'src/assets/icons/social-icons/twitter.svg'
import { ReactComponent as YoutubeIcon } from 'src/assets/icons/social-icons/youtube.svg'

const BREAKPOINT = 750

const FooterContainer = styled('footer')(
  ({ theme }) =>
    css`
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: ${theme.spacing(8, 0, 0)};
      color: ${theme.palette.primary.main};

      ${theme.breakpoints.down(BREAKPOINT)} {
        flex-direction: column;
        margin: ${theme.spacing(5, 0, 2)};
      }
    `,
)

const SocialsContainer = styled('div')(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    align-items: flex-end;

    ${theme.breakpoints.down(BREAKPOINT)} {
      order: 2;
      margin: ${theme.spacing(4, 0)};
    }
  `,
)

const SocialLink = styled('a')(
  ({ theme }) => css`
    margin-bottom: -5px;
    margin-right: ${theme.spacing(1)};

    :last-child {
      margin-right: 0;
    }

    svg {
      color: ${theme.palette.grey[200]};
      font-size: ${theme.typography.pxToRem(24)};

      :hover {
        color: ${theme.palette.primary.main};
        path {
          fill: ${theme.palette.primary.main};
        }
        ellipse {
          fill: ${theme.palette.primary.main};
        }
      }
    }
    ${theme.breakpoints.down(BREAKPOINT)} {
      font-size: ${theme.typography.pxToRem(20)};
    }
  `,
)

const CenterItem = styled('div')(
  ({ theme }) => css`
    flex: 1;

    display: flex;
    align-items: center;
    flex-direction: column;

    ${theme.breakpoints.down(BREAKPOINT)} {
      order: 1;
      align-items: flex-start;
    }
  `,
)

const TermsContainer = styled('div')(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;

    ${theme.breakpoints.down(BREAKPOINT)} {
      order: 3;
      justify-content: flex-start;
    }
  `,
)

const StyledLinkText = styled(Typography)(
  ({ theme }) => css`
    text-decoration: underline;

    :hover {
      color: ${theme.palette.primary.main};
    }
  `,
)

const socials = [
  {
    icon: TwitterIcon,
    name: 'twitter',
    href: 'https://twitter.com/altstatemachine',
  },
  {
    icon: DiscordIcon,
    name: 'discord',
    href: 'https://discord.gg/alteredstatemachine',
  },
  {
    icon: InstagramIcon,
    name: 'instagram',
    href: 'https://www.instagram.com/alteredstatemachine',
  },
  {
    icon: MediumIcon,
    name: 'Medium',
    href: 'https://medium.com/@alteredstatemachine',
  },
  {
    icon: LinkedInIcon,
    name: 'linkedin',
    href: 'https://www.linkedin.com/company/altered-state-machine',
  },
  {
    icon: TwitchIcon,
    name: 'twitch',
    href: 'https://www.twitch.tv/alteredstatemachine',
  },
  {
    icon: YoutubeIcon,
    name: 'youTube',
    href: 'https://www.youtube.com/channel/UCm71FrVtFi2KXdaiWzjdkUg',
  },
]

const year = new Date().getFullYear()

export const Footer = () => (
  <FooterContainer>
    <SocialsContainer>
      {socials.map(({ href, name, icon: Icon }) => (
        <SocialLink
          key={name}
          href={href}
          target="_blank"
          rel="noreferrer"
          aria-label={name}
        >
          <Icon />
        </SocialLink>
      ))}
    </SocialsContainer>
    <CenterItem>
      <Box alignItems="center" display="flex" mb={2}>
        <img src={ASMLogo} alt="Altered State Machine" />
      </Box>
      <Typography
        component="p"
        variant="body3"
        color="grey.200"
        textAlign="center"
        data-testid="footer-copyright"
      >
        © Copyright Altered State Machine Ltd {year}
      </Typography>
    </CenterItem>
    <TermsContainer>
      <Link to={Paths.TermsAndConditions}>
        <StyledLinkText variant="body3" color="grey.200">
          Terms of use
        </StyledLinkText>
      </Link>
    </TermsContainer>
  </FooterContainer>
)

export default Footer
