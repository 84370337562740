/* eslint-disable camelcase */
import { useMemo } from 'react'

import { ChainENVs, CHAIN_ENV } from 'src/utils/web3'
import { useWeb3LibraryProvider } from '../useWeb3LibraryProvider'
import { Converter__factory } from 'smart-contracts/gen/factories/Converter__factory'
import { ConverterTest__factory } from 'smart-contracts/gen/factories/ConverterTest__factory'

import localhostABI from 'smart-contracts/abi/localhost.json'
import mainnetABI from 'smart-contracts/abi/mainnet.json'
import goerliStagingABI from 'smart-contracts/abi/goerli-staging.json'
import goerliABI from 'smart-contracts/abi/goerli.json'
// import kovanABI from 'smart-contracts/abi/kovan.json'

export const addresses: Record<ChainENVs, string> = {
  localhost: localhostABI.contracts.ConverterTest.address,
  mainnet: mainnetABI.contracts.Converter.address,
  goerliStaging: goerliStagingABI.contracts.ConverterTest.address,
  goerli: goerliABI.contracts.ConverterTest.address,
  kovan: '',
}

const isMainnet = CHAIN_ENV === ChainENVs.Mainnet
// Use test factory on envs other than mainnet
const factory = isMainnet ? Converter__factory : ConverterTest__factory

const useEnergyConverterContract = () => {
  const address = addresses[CHAIN_ENV]
  const rpcProvider = useWeb3LibraryProvider()

  const contract = useMemo(
    () => factory.connect(address, rpcProvider),
    [address, rpcProvider],
  )
  return contract
}

export default useEnergyConverterContract
