import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { ThemeProviders } from 'component-library/src/utils/ThemeProviders'
import { AppBackground } from './modules/AppBackground'
import Web3Providers from './Web3Providers'
import { withLDProvider } from 'launchdarkly-react-client-sdk'
import { ldFeatureFlags } from 'src/utils/featureFlags'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    },
  },
})

const Providers: React.FC = ({ children }) => {
  return (
    <ThemeProviders>
      <AppBackground>
        <Web3Providers>
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools
              initialIsOpen={false}
              // display none to prevent appearance in tests
              toggleButtonProps={{
                style: {
                  display: 'none',
                },
              }}
            />
            <Router>{children}</Router>
          </QueryClientProvider>
        </Web3Providers>
      </AppBackground>
    </ThemeProviders>
  )
}

const ProvidersWithLD = withLDProvider({
  clientSideID: process.env.REACT_APP_LD_CLIENT_SIDE_ID!,
  options: {
    bootstrap: 'localStorage',
  },
  flags: ldFeatureFlags,
})(Providers)

export default ProvidersWithLD
