import { useTranslation } from 'react-i18next'
import { styled, keyframes } from '@mui/material/styles'
import {
  ButtonBase,
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
  Typography,
  css,
} from '@mui/material'

import useAutoRefreshPopup from 'src/hooks/useAutoRefreshPopup'
import { useEffect } from 'react'
import IconFactory from 'component-library/src/components/icons/IconFactory'

const rotate = keyframes`
  from, 0% { transform: rotate(0); }    
  100%, to { transform: rotate(-360deg); }
`

const RefreshIcon = styled(IconFactory)`
  margin: 18px;
  animation: ${rotate} normal 1.5s infinite linear;
`

const ActionContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    width: 530px;
    height: 75px;
    align-items: center;
    justify-content: flex-start;
    background: ${theme.palette.grey['800']};
    border: 1px solid ${theme.palette.grey['700']};
  `,
)

const RefreshButton = styled(ButtonBase)`
  position: absolute;
  right: 18px;
`

interface AutoRefreshPopupProps {
  timeout?: number
  snackbarProps?: Partial<MuiSnackbarProps>
}

const refreshPage = () => window.location.reload()

const AutoRefreshPopup = ({
  timeout = 30,
  snackbarProps,
}: AutoRefreshPopupProps) => {
  const { t } = useTranslation()
  const { countdown, isOpen } = useAutoRefreshPopup(timeout)
  useEffect(() => {
    if (countdown === 0) {
      refreshPage()
    }
  }, [countdown])

  return (
    <MuiSnackbar
      open={isOpen}
      sx={{ width: 'auto' }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...snackbarProps}
    >
      <ActionContainer data-testid="auto-refresh-popup">
        <RefreshIcon name="reload" color="#fff" width={30} />
        <Typography variant="body1" color="text.grey">
          {t('auto-refresh-text')}
          <Typography
            variant="body1"
            color="text.secondary"
            component="span"
            data-testid="auto-refresh-countdown"
          >
            <strong>
              {countdown && t('auto-refresh-countdown', { count: countdown })}
            </strong>
          </Typography>
          {'.'}
        </Typography>
        <RefreshButton onClick={refreshPage}>
          <Typography
            variant="body1"
            color="text.grey"
            component="span"
            style={{
              textDecoration: 'underline',
            }}
          >
            {t('auto-refresh-button')}
          </Typography>
        </RefreshButton>
      </ActionContainer>
    </MuiSnackbar>
  )
}
export default AutoRefreshPopup
