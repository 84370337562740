import { useWeb3React } from 'src/hooks/useWeb3React'
import { useQuery } from 'react-query'
import useEnergyConverterContract from './contracts/useEnergyConverterContract'
import { formatUnits } from 'ethers/lib/utils'

const useAvailableASTOEnergy = () => {
  const { account } = useWeb3React()
  const energyConverterContract = useEnergyConverterContract()

  const availableASTOEnergyQuery = useQuery(
    ['asto-energy', 'balance', account],
    async () => {
      if (!account) {
        return '0'
      }

      const availableEnergyBN = await energyConverterContract.getEnergy(account)

      const availableEnergy = formatUnits(availableEnergyBN, 36)
      return availableEnergy
    },
    { enabled: !!account, placeholderData: '0' },
  )

  return availableASTOEnergyQuery
}

export default useAvailableASTOEnergy
