import { styled, css } from '@mui/material/styles'
import { Typography, TypographyProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import ConnectWalletButton from 'src/components/button/ConnectWalletButton'
import type { ImageProps } from 'src/types/ImageProps'

import connectionBackgroundImage from 'src/assets/images/connection-background.png'
import connectionRequiredImage from 'src/assets/images/connection-required.gif'

const SMALL_BREAKPOINT = 720

const Container = styled('div')(
  ({ theme }) => css`
    display: grid;
    width: 100%;
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: 2px solid ${theme.palette.primary.main};
    border-radius: 8px;
    background: ${theme.palette.grey['800']};
    overflow: hidden;
    grid-template-columns: 1fr minmax(40%, 1fr);

    ${theme.breakpoints.down(SMALL_BREAKPOINT)} {
      grid-template-columns: 1fr;
    }
  `,
)

const ImageSection = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: flex-end;
    padding-top: ${theme.spacing(8)};
    > img {
      margin-left: -2px;
      margin-bottom: -2px;
      object-fit: contain;
      object-position: bottom;
    }

    ${theme.breakpoints.down(SMALL_BREAKPOINT)} {
      padding-right: ${theme.spacing(4)};
    }
  `,
)

const CenteredImageSection = styled(ImageSection)(
  ({ theme }) => css`
    margin: auto;
    padding-top: 0;
    ${theme.breakpoints.down(SMALL_BREAKPOINT)} {
      padding-right: ${theme.spacing(0)};
      padding-top: ${theme.spacing(9)};
      padding-bottom: ${theme.spacing(3)};
    }
  `,
)

const Content = styled('div')(
  ({ theme }) => css`
    align-self: center;
    padding: ${theme.spacing(7)};
    max-width: 600px;
    min-width: min-content;
    color: ${theme.palette.text.secondary};

    > .MuiButton-root {
      margin-top: ${theme.spacing(3)};
      width: 240px;
    }

    ${theme.breakpoints.down(1000)} {
      padding: ${theme.spacing(5)};
    }

    ${theme.breakpoints.down(SMALL_BREAKPOINT)} {
      max-width: unset;
      text-align: center;
    }
  `,
)

const Title = styled(Typography)(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamilySecondary};
    font-weight: lighter;
    white-space: nowrap;
    font-size: 32px;
    line-height: 42px;
    ${theme.breakpoints.down(1200)} {
      font-size: 24px;
      line-height: 32px;
    }
  `,
)

const Description: React.FC<TypographyProps & { component: 'div' }> = styled(
  Typography,
)(
  ({ theme }) => css`
    ${theme.breakpoints.down(1200)} {
      font-size: 14px;
      line-height: 20px;
    }
  `,
)

interface DisconnectedWalletProps {
  title?: string
  image?: ImageProps
  isImageCenteredAligned?: boolean
  backgroundImage?: string
}

const DisconnectedWallet: React.FC<DisconnectedWalletProps> = ({
  title,
  image = { src: connectionRequiredImage },
  backgroundImage = connectionBackgroundImage,
  isImageCenteredAligned = true,
  children,
}) => {
  const ImageSectionComponent = isImageCenteredAligned
    ? CenteredImageSection
    : ImageSection

  const { t } = useTranslation()

  return (
    <Container
      data-testid="disconnected-wallet"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
      }}
    >
      <ImageSectionComponent>
        <img src={image.src} alt={image.alt} />
      </ImageSectionComponent>
      <Content>
        <Title variant="h3">
          {title ?? t('genome-mining.connect-wallet.title')}
        </Title>
        <Description variant="body1" component="div">
          {children}
        </Description>
        <ConnectWalletButton fullWidth />
      </Content>
    </Container>
  )
}

export default DisconnectedWallet
