export enum MapRegion {
  NonMineableArea = 0,
  Outlands = 1,
  TheEyeOfRuin = 2,
  Frostfell = 3,
  Megacity = 4,
  GrezenDul = 5,
  HakalNarr = 6,
  DancingSeas = 7,
  Wyldsprite = 8,
  Melascor = 9,
}

export const MapRegionNames = {
  [MapRegion.NonMineableArea]: 'Non-mineable area',
  [MapRegion.Outlands]: 'TheOutlands',
  [MapRegion.TheEyeOfRuin]: 'TheEyeOfRuin',
  [MapRegion.Frostfell]: 'Frostfell',
  [MapRegion.Megacity]: 'Megacity',
  [MapRegion.GrezenDul]: 'GrezenDul',
  [MapRegion.HakalNarr]: 'HakalNarr',
  [MapRegion.DancingSeas]: 'DancingSeas',
  [MapRegion.Wyldsprite]: 'Wyldsprite',
  [MapRegion.Melascor]: 'Melascor',
} as const

export const MapRegionColors = {
  [MapRegion.NonMineableArea]: '#FF0000',
  [MapRegion.Outlands]: '#806001',
  [MapRegion.TheEyeOfRuin]: '#2F5496',
  [MapRegion.Frostfell]: '#FFFFFF',
  [MapRegion.Megacity]: '#D0CECE',
  [MapRegion.GrezenDul]: '#7130A0',
  [MapRegion.HakalNarr]: '#404040',
  [MapRegion.DancingSeas]: '#00B0F0',
  [MapRegion.Wyldsprite]: '#00B050',
  [MapRegion.Melascor]: '#FFC002',
}

export enum NonMineableAreaSubregions {
  NonMineableArea = 1,
}

export enum DancingSeasSubregions {
  Coralhome = 1,
  KohalPoDepths = 2,
  Landsreach = 3,
  PolaAtiAtoll = 4,
  SokorikiCity = 5,
  WestDives = 6,
}

export enum TheEyeOfRuinSubregions {
  TheEyeOfRuin = 1,
}
export enum FrostfellSubregions {
  CapitalDistrict = 1,
  NorthernFloes = 2,
  WallDistrict = 3,
  WestValleys = 4,
  WoodlandHills = 5,
}
export enum GrezenDulSubregions {
  TheCalls = 1,
  CentralBogs = 2,
  TheCreep = 3,
  HorrorHills = 4,
  MountainsOfMadness = 5,
  PassesOfTheNameless = 6,
}

export enum HakalNarrSubregions {
  EastLavaFields = 1,
  MtDevil = 2,
  SafeShores = 3,
  ValleyOf100Infernos = 4,
}

export enum MegacitySubregions {
  CordisDistrict = 1,
  EastHaven = 2,
  WestGate = 3,
}

export enum MelascorSubregions {
  AlkeshPeninsula = 1,
  CentralGate = 2,
  EasternRange = 3,
  TheFlats = 4,
  MallePrefecture = 5,
  NaMorgo = 6,
  SaheaSeas = 7,
  SouthernWatch = 8,
  ThrasisHold = 9,
  Waterways = 10,
}

export enum OutlandsSubregions {
  EasternDusts = 1,
  NorthernFields = 2,
  SettlementCircle = 3,
  SouthernWastes = 4,
  WesternReaches = 5,
}

export enum WyldspriteSubregions {
  TheDowns = 1,
  Greyshades = 2,
  Hollows = 3,
  TheLowlands = 4,
  Lystvale = 5,
  Roothome = 6,
}

export type TileType = {
  isSelectable: boolean
  x: number
  y: number
  region: MapRegion
  subregion: number
}
