import { css, Skeleton, styled } from '@mui/material'
import Grid from 'component-library/src/components/grid/Grid'

const StyledSkeleton = styled(Skeleton)(
  ({ theme }) => css`
    display: flex;
    position: relative;
    border-radius: 8px;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 280px;
    background-color: ${theme.palette.grey['800']};

    ${theme.breakpoints.down('sm')} {
      flex-direction: row;
      height: 100px;
      background-color: ${theme.palette.grey['600']};
    }
  `,
)

export const AssetListSkeleton = () => (
  <Grid container spacing={1}>
    {[...Array(12)].map((_, i) => (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={i}>
        <StyledSkeleton variant="rectangular" />
      </Grid>
    ))}
  </Grid>
)
