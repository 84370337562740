import config from './config'

export enum FeatureFlags {
  AllStarClaim = 'all-star-claim',
}

export const localStorageKey = {
  [FeatureFlags.AllStarClaim]: 'asm-cortex:feature-flag:enable-star-claim',
}

export const configFlag = {
  [FeatureFlags.AllStarClaim]: config.REACT_APP_FEATURE_FLAG_ALLSTAR_CLAIM,
}

export const getFeatureValue = (key: FeatureFlags): boolean => {
  const localStorageValue =
    localStorage.getItem(localStorageKey[key]) === 'true'

  return localStorageValue || configFlag[key]
}

export const ldFeatureFlags = {
  'cor-mining-map-coordinates-finder-temp-220815': false,
  'revealed-regions': {
    NonMineableArea: false,
    TheOutlands: false,
    TheEyeOfRuin: false,
    Frostfell: false,
    Megacity: false,
    GrezenDul: false,
    HakalNarr: false,
    DancingSeas: false,
    Wyldsprite: false,
    Melascor: false,
  },
  'inventory-search-bar-temp-041022': false,
  'current-js-version': '',
  'lba-migration-temp-221201': true,
  'mining-maintenance-window': false,
  'void-protocol-temp-221123': false,
}
