import React from 'react'
import ReactDOM from 'react-dom'
import LogRocket from 'logrocket'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import setupLogRocketReact from 'logrocket-react'

import App from 'src/modules/App'
import Providers from 'src/Providers'
import { initAnalytics } from './utils/ga'
import reportWebVitals from 'src/reportWebVitals'

import 'src/i18n'

const logrocketAppId = process.env.REACT_APP_LOGROCKET_APP_ID

if (logrocketAppId) {
  LogRocket.init(logrocketAppId, {})
  setupLogRocketReact(LogRocket)
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_SAMPLE_RATE || 1.0),
    beforeSend: (event, hint) => {
      console.error(hint?.originalException ?? event.exception?.values?.[0])
      return event
    },
  })

  if (logrocketAppId)
    LogRocket.getSessionURL(sessionURL => {
      Sentry.configureScope(scope => {
        scope.setExtra('sessionURL', sessionURL)
      })
    })
}

window.addEventListener('load', () => {
  if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
    initAnalytics(process.env.REACT_APP_GOOGLE_ANALYTICS_ID)
  }
})

// msw mocking
// if (process.env.NODE_ENV === 'development') {
//   const { worker } = require('./mocks/browser')
//   worker.start()
// }

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary
      showDialog
      onError={error => {
        console.error(error)
      }}
    >
      <Providers>
        <App />
      </Providers>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('app-root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
