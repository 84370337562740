import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import {
  number,
  option,
  ord,
  readonlyArray,
  separated,
  task,
  taskEither,
} from 'fp-ts'
import { flow, pipe } from 'fp-ts/lib/function'
import { JsonRpcProvider } from '@ethersproject/providers'
import { useAlertbar } from 'src/hooks/store/useAlertbar'
import { captureException, setContext } from '@sentry/react'

export type RPCUrlContextType =
  | { type: 'resolving' }
  | { type: 'resolved'; url: string; provider: JsonRpcProvider }
  | { type: 'unresolved' }

const RPCUrlContext = createContext<RPCUrlContextType>({ type: 'resolving' })

const rpcCall = async (rpcUrl: string, id: number, method: string) => {
  await fetch(rpcUrl, {
    body: JSON.stringify({
      method,
      params: [],
      id,
      jsonrpc: '2.0',
    }),
    method: 'POST',
  }).then(res => {
    if (res.status >= 400) {
      throw new Error(`Error fetching ${method}} with status: ${res.status}`)
    }
  })
}

// Get current block number to check availability of JSON RPC api.
const rpcHealthCheck = (
  rpcUrl: string,
): taskEither.TaskEither<string, string> => {
  return taskEither.tryCatch(
    async () => {
      await rpcCall(rpcUrl, 42, 'eth_chainId')
      await rpcCall(rpcUrl, 43, 'eth_blockNumber')

      return rpcUrl
    },
    reason => {
      captureException(new Error(`Cannot connect to ${rpcUrl}`), {
        tags: { jsonRPCProvider: new URL(rpcUrl).hostname },
      })

      setContext('provider', {
        url: rpcUrl,
        reason,
      })

      return `${reason}`
    },
  )
}

const pickRPCUrl = (
  rpcUrls: readonly string[],
): task.Task<option.Option<string>> => {
  return pipe(
    rpcUrls,
    task.traverseArray(rpcHealthCheck),
    task.map(flow(readonlyArray.separate, separated.right, readonlyArray.head)),
  )
}

const envRegex = /^REACT_APP_RPC_URL(_(?<idx>[0-9]))?/
const getRpcUrlsFromEnv = (env: Record<string, string | undefined>) =>
  pipe(
    Object.entries(env),
    readonlyArray.chain(([key, value]) => {
      const match = envRegex.exec(key)
      if (!match || !value) {
        return []
      }

      const idx = Number(match.groups?.idx) ?? '0'
      return [[idx, value]] as const
    }),
    readonlyArray.sort(
      ord.contramap<number, readonly [number, string]>(([idx]) => idx)(
        number.Ord,
      ),
    ),
    readonlyArray.map(([, value]) => value),
  )

export const RPCUrlProvider: React.FC = ({ children }) => {
  const [rpcUrl, setRpcUrl] = useState<RPCUrlContextType>({ type: 'resolving' })

  const rpcUrls = useMemo(() => getRpcUrlsFromEnv(process.env), [])

  useEffect(() => {
    const pickRpcUrlTask = pipe(
      pickRPCUrl(rpcUrls),
      task.map(
        flow(
          option.fold<string, RPCUrlContextType>(
            () => ({ type: 'unresolved' }),
            url => ({
              type: 'resolved',
              url,
              provider: new JsonRpcProvider(url),
            }),
          ),
          setRpcUrl,
        ),
      ),
    )

    pickRpcUrlTask().catch(error => console.error(error))
  }, [rpcUrls, setRpcUrl])

  return (
    <RPCUrlContext.Provider value={rpcUrl}>{children}</RPCUrlContext.Provider>
  )
}

export const useRpcUrlResolution = () => useContext(RPCUrlContext)

// A component that conditionally render children only after the RPC url has been resolved.
export const WithInitializedRPCUrl: React.FC = ({ children }) => {
  const resolvedRpc = useRpcUrlResolution()
  const { openAlertbar } = useAlertbar()

  useEffect(() => {
    if (resolvedRpc.type === 'unresolved') {
      openAlertbar({
        message:
          'Having trouble connecting to Ethereum nodes, please try again.',
        severity: 'error',
      })
      captureException(new Error('Ethereum network unreachable'))
    }
  }, [resolvedRpc.type, openAlertbar])

  if (resolvedRpc.type !== 'resolved') {
    // TODO(CW-616): show loading screen here.
    return <></>
  }

  return <>{children}</>
}

export const useRpcUrl = () => {
  const rpcUrl = useRpcUrlResolution()
  if (rpcUrl.type !== 'resolved') {
    throw new Error(
      `RPC url state is ${rpcUrl.type}. Plese use within <WithInitializedRPCUrl /> to ensure RPC url is resolved`,
    )
  }

  return rpcUrl.url
}

export const useSimpleJsonRpcProvider = () => {
  const rpcUrl = useRpcUrlResolution()
  if (rpcUrl.type !== 'resolved') {
    throw new Error(
      `RPC url state is ${rpcUrl.type}. Plese use within <WithInitializedRPCUrl /> to ensure RPC url is resolved`,
    )
  }

  return rpcUrl.provider
}
