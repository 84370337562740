import { css, styled } from '@mui/material'
import { BrainTotalBox } from 'src/components/BrainTotalBox'
import { OpenSeaMenu } from 'src/components/OpenSeaMenu'
import useInventoryStore from './hooks/useInventoryStore'

const Container = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-content: center;
    align-items: flex-end;
    justify-content: space-between;
    ${theme.breakpoints.down('md')} {
      justify-content: center;
    }
  `,
)

const OpenSeaMenuContainer = styled('div')(
  ({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `,
)
export const InventoryControlBar = () => {
  const { brainsCount } = useInventoryStore(state => ({
    brainsCount: state.brainsCount,
  }))

  return (
    <Container data-testid="inventory-control-bar">
      <BrainTotalBox
        genesisCount={brainsCount.genesisCount}
        genIICount={brainsCount.genIICount}
      />
      <OpenSeaMenuContainer>
        <OpenSeaMenu />
      </OpenSeaMenuContainer>
    </Container>
  )
}
