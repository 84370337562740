export enum Paths {
  Home = '/',
  Discovery = '/discovery',
  Rewards = '/rewards',
  AllStarClaim = '/all-star-claim',
  GenomeMining = '/genome-mining',
  LunarisMachine = '/lunaris-machine',
  BrainClaimCheck = '/claimed-check',
  TermsAndConditions = '/terms',
  Inventory = '/inventory',
}

export enum RewardsPaths {
  Details = 'details',
  History = 'history',
  Schedule = 'schedule',
  Discovery = 'discovery',
}

export enum DiscoveryStakingPaths {
  Deposit = 'deposit',
  Withdraw = 'withdraw',
}

export enum DiscoveryPaths {
  GenomeMining = 'genome-mining',
}

export enum LunarisMachinePaths {
  Add = 'charge',
  Remove = 'discharge',
}

export enum Slug {
  TokenId = 'id',
  ContractName = 'contractName',
}
