import { useState, MouseEvent, useCallback } from 'react'
import { Menu, MenuItem } from '@mui/material'
import { styled, css } from '@mui/material/styles'

import { useWallet } from 'src/hooks/useWallet'
import { useWeb3React } from 'src/hooks/useWeb3React'
import { useWalletsDialog } from 'src/hooks/store/useWalletsDialog'
import { WalletButton } from 'component-library/src/components/status/WalletButton'

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    width: 145px;
    background-color: ${theme.palette.primary.main};

    :hover {
      background-color: ${theme.palette.primary.dark};
    }
  `,
)

const Account: React.FC<{ className?: string }> = ({ className }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const { active, account } = useWeb3React()
  const { openWalletsDialog } = useWalletsDialog()

  const onWalletClick = useCallback(
    () => openWalletsDialog(),
    [openWalletsDialog],
  )

  const { disconnectWallet } = useWallet()

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const handleDisconnectWallet = () => {
    disconnectWallet()
    closeMenu()
  }
  return (
    <div className={className}>
      <WalletButton
        data-cy="connect-wallet"
        accountNumber={account}
        onClick={active ? openMenu : onWalletClick}
      />
      <Menu anchorEl={anchorEl} onClose={closeMenu} open={Boolean(anchorEl)}>
        <StyledMenuItem
          data-cy="disconnect-wallet"
          onClick={handleDisconnectWallet}
        >
          Disconnect
        </StyledMenuItem>
      </Menu>
    </div>
  )
}

export default Account
