import { useCallback, useEffect, useMemo, useState } from 'react'
import { useWeb3React } from 'src/hooks/useWeb3React'
import { useQuery } from 'react-query'
import { NftItem, QueryGetNftsInfoArgs } from 'src/graphql/generated'
import { ChainAddress } from 'common/src/types/api-interfaces'
import { gqlFetcher } from 'src/utils/gqlFetcher'
import {
  GetNftsInfoQuery,
  GetNftsInfoDocument,
} from 'src/graphql/query/getNftsInfo'
import {
  checkGen2Genus,
  setBrainsFilter,
  sortBrains,
} from 'src/utils/inventory'
import useInventoryStore from './useInventoryStore'
import { useASMBrainsSummary } from './useASMBrainsSummary'

const ITEMS_PER_PAGE = 12

export const useASMBrainsList = (itemsPerPage = ITEMS_PER_PAGE) => {
  const {
    fullPageList,
    currentPage,
    pageLastKey,
    filterValue,
    sortValue,
    setPageLastKey,
    setGenusFilters,
    setFullPageList,
  } = useInventoryStore(state => ({
    currentPage: state.currentPage,
    pageLastKey: state.pageLastKey,
    filterValue: state.filterValue,
    sortValue: state.sortValue,
    fullPageList: state.fullPageList,
    setPageLastKey: state.setPageLastKey,
    setGenusFilters: state.setGenusFilters,
    setFullPageList: state.setFullPageList,
  }))

  const { account } = useWeb3React()
  const [nftsList, setNftsList] = useState<NftItem[] | undefined>([])
  const [totalPageCount, setTotalPageCount] = useState(0)

  const brainsSummaryQuery = useASMBrainsSummary()

  const brainsQuery = useQuery(
    ['brain', 'list', account, pageLastKey],
    async () => {
      const res = await gqlFetcher<GetNftsInfoQuery, QueryGetNftsInfoArgs>(
        GetNftsInfoDocument,
        {
          lastEvaluatedHk: pageLastKey,
          walletAddress: account as ChainAddress,
        },
      )
      if (res.getNftsInfo.__typename === 'GetNftsInfoResponseSuccess') {
        return res.getNftsInfo
      }
      if (res.getNftsInfo.__typename === 'GetNftsInfoResponseFailure') {
        throw new Error(res.getNftsInfo.__typename)
      }
    },
    {
      enabled: !!account,
      onSuccess: data => {
        if (data?.nfts) {
          let newList
          // Merging the new pages into current pages
          if (data.lastEvaluatedHk !== pageLastKey) {
            newList = fullPageList ? [...fullPageList, ...data.nfts] : data.nfts
          } else {
            newList = data.nfts
          }
          setFullPageList(newList)
          setPageLastKey(data.lastEvaluatedHk)

          // This is dirty implementation for Filter in Backend pagination
          // If there is more pages, just enable all the filter options
          // Otherwise checking each genus via the normal solution
          if (pageLastKey || data.lastEvaluatedHk) {
            setGenusFilters({
              enableUnearthed: true,
              enableAscended: true,
            })
          } else {
            const enableUnearthed = checkGen2Genus(newList, 'Unearthed')
            const enableAscended = checkGen2Genus(newList, 'Ascended')
            setGenusFilters({
              enableUnearthed,
              enableAscended,
            })
          }
        }
      },
    },
  )

  const sortNftsList = useCallback(() => {
    setNftsList(currentNftsList => {
      const newList = currentNftsList
        ? sortBrains(currentNftsList, sortValue)
        : []
      return newList
    })
  }, [sortValue])

  useEffect(() => {
    if (fullPageList) {
      const filteredList = setBrainsFilter(fullPageList, filterValue)
      setNftsList(filteredList)
      sortNftsList()

      switch (filterValue) {
        case '1': {
          setTotalPageCount(brainsSummaryQuery.data?.numberOfBrains ?? 0)
          break
        }
        case '2': {
          setTotalPageCount(brainsSummaryQuery.data?.numberOfGenesisBrains ?? 0)
          break
        }
        case '3': {
          setTotalPageCount(filteredList?.length ?? 0)
          break
        }
        case '4': {
          setTotalPageCount(filteredList?.length ?? 0)
          break
        }
      }
    }
  }, [brainsSummaryQuery.data, filterValue, fullPageList, sortNftsList])

  useEffect(() => {
    sortNftsList()
  }, [sortNftsList])

  const currentPageBrains = useMemo(() => {
    const startIndex = currentPage * itemsPerPage
    const endIndex = (currentPage + 1) * itemsPerPage
    return nftsList?.slice(startIndex, endIndex)
  }, [currentPage, itemsPerPage, nftsList])

  return {
    brainsQuery,
    currentPageBrains,
    currentPage,
    totalPageCount,
    totalBrainsCount: brainsSummaryQuery.data?.numberOfBrains,
  }
}
