import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'
import { css, styled } from '@mui/material/styles'

import { Paths } from 'src/utils/routes'
import Button from 'component-library/src/components/button/Button'

import planetImg from 'src/assets/images/planet.png'

const Container = styled('div')(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    overflow-y: auto;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(6, 3)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(4, 2)};
    }
  `,
)

const PlanetImage = styled('img')(
  ({ theme }) => css`
    width: 320px;
    margin: ${theme.spacing(8, 0)};

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(4, 0)};
    }
  `,
)

const NotFound: React.FC = () => (
  <Container>
    <Helmet>
      <title>Not found | ASM</title>
      <meta
        name="description"
        content="The page you're looking for could not be found."
      />
    </Helmet>
    <Typography
      mb={2}
      variant="h4"
      component="h1"
      textAlign="center"
      color="text.secondary"
      data-testid="404-title"
    >
      Error 404!
    </Typography>
    <Typography
      variant="h2"
      textAlign="center"
      color="text.secondary"
      sx={{ fontFamily: 'fontFamilySecondary' }}
    >
      Oops! You&lsquo;ve wandered back to reality
    </Typography>
    <PlanetImage src={planetImg} alt="Planet" />
    <Button component={Link} to={Paths.Home} data-testid="404-home-link">
      Head home to the metaverse
    </Button>
  </Container>
)

export default NotFound
