import {
  Tooltip as MuiTooltip,
  TooltipProps as MuiTooltipProps,
} from '@mui/material'

const Tooltip = (props: MuiTooltipProps) => {
  return <MuiTooltip {...props} />
}

export default Tooltip
export type { MuiTooltipProps as TooltipProps }
