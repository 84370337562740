import { styled, css, Typography } from '@mui/material'
import { ReactComponent as OpenseaIcon } from 'component-library/src/assets/icons/opensea.svg'
import Skeleton from 'component-library/src/components/skeleton/Skeleton'
import Link from 'component-library/src/components/link/Link'
import { useNavigate } from 'react-router-dom'
import { reportEvent } from 'src/utils/ga'
import { BrainContractName } from 'src/utils/inventory'
import { Paths } from 'src/utils/routes'
import transientPropCheck from 'src/utils/transientPropCheck'
import useImage from 'use-image'
import { ContentNotAvailablePlaceholder } from './ContentNotAvailablePlaceholder'

const Container = styled('div')(
  ({ theme }) => css`
    display: flex;
    position: relative;
    border-radius: 8px;
    align-content: center;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    height: 280px;
    background-color: ${theme.palette.grey['800']};

    ${theme.breakpoints.down('sm')} {
      flex-direction: row;
      height: 100px;
      background-color: ${theme.palette.grey['600']};
    }

    :hover {
      cursor: pointer;
    }
  `,
)

const StyledLink = styled(Link)(
  ({ theme }) => css`
    display: flex;
    margin-left: 8px;
    color: inherit;
    position: absolute;
    right: 10px;
    top: 10px;
    &:hover {
      opacity: 60%;
    }
    ${theme.breakpoints.down('sm')} {
      position: relative;
      top: auto;
      right: auto;
      margin-left: 0;
    }
  `,
)

interface BrainImageProps {
  $imageURL?: string
}

const BrainImage = styled(
  'div',
  transientPropCheck,
)<BrainImageProps>(
  ({ $imageURL, theme }) => css`
    width: 100%;
    height: 100%;
    ${$imageURL &&
    `background-image: url(${$imageURL});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    ${theme.breakpoints.down('sm')} {
      background-size: 120%;
      background-position: 40% 30%;
    }
    `};
  `,
)

const BrainImageContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 212px;
    ${theme.breakpoints.down('sm')} {
      width: 55%;
      height: 100%;
      order: 2;
    }
  `,
)

const DescriptionContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    flex: 1;
    padding-left: 13px;
    background-color: ${theme.palette.grey['600']};
    ${theme.breakpoints.down('sm')} {
      background: none;
      order: 1;
    }
  `,
)

export interface AssetModel {
  id: string
  blockNumber?: string
  name: string
  imageURL?: string
  externalURL?: string
}

type AssetCardProps = AssetModel

const getBrainNameByContractName = (contractName: string): string => {
  if (contractName === BrainContractName.ASMBrain) {
    return 'Genesis Brain'
  } else {
    return 'Gen II Brain'
  }
}

export const AssetCard = ({
  id,
  name,
  imageURL,
  externalURL,
}: AssetCardProps) => {
  const brainName = getBrainNameByContractName(name)
  const navigate = useNavigate()

  const gotoFocusPage = () => {
    navigate(`${Paths.Inventory}/${name}/${id}`)
  }

  const [, status] = useImage(imageURL!)

  return (
    <Container
      role="button"
      aria-label={`${brainName} #${id}`}
      data-cy={`asset-${name.toLocaleLowerCase()}-${id}`}
      onClick={gotoFocusPage}
    >
      <BrainImageContainer>
        {(status === 'loading' || status === 'failed') && imageURL && (
          <Skeleton
            variant="circular"
            sx={{
              bgcolor: 'grey.900',
              borderRadius: {
                xs: '0',
                sm: '50%',
              },
              width: {
                xs: '100%',
                sm: '140px',
              },
              height: {
                xs: '100%',
                sm: '140px',
              },
            }}
          />
        )}
        {!imageURL && <ContentNotAvailablePlaceholder />}
        {status === 'loaded' && imageURL && <BrainImage $imageURL={imageURL} />}
      </BrainImageContainer>
      <DescriptionContainer>
        <Typography variant="h5" color="white" sx={{ fontWeight: 600 }}>
          {brainName}
        </Typography>
        <Typography variant="body2" color="#C2C2C4" sx={{ lineHeight: '24px' }}>
          #{id}
        </Typography>
        {externalURL && (
          <StyledLink
            data-cy="external-url"
            href={externalURL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => {
              e.stopPropagation()
              reportEvent('button_click', {
                button_name: 'OpenSea_icons',
                page_title: 'Inventory',
              })
            }}
          >
            <OpenseaIcon />
          </StyledLink>
        )}
      </DescriptionContainer>
    </Container>
  )
}
