import create from 'zustand'

type Status = 'success' | 'info' | 'error'

interface useStatusbarState {
  isStatusbarOpen: boolean
  status?: Status
  message?: string
  actionComponent?: React.ReactNode
  onClose?: () => void
  openStatusbar: ({
    message,
    actionComponent,
    onClose,
    status,
  }: {
    message: string
    actionComponent?: React.ReactNode
    status?: Status
    onClose?: () => void
  }) => void
  closeStatusbar: () => void
}

const useStatusbar = create<useStatusbarState>((set, get) => ({
  isStatusbarOpen: false,
  closeStatusbar: () => {
    const onClose = get()?.onClose
    onClose && onClose()
    set({
      isStatusbarOpen: false,
      message: undefined,
      actionComponent: undefined,
      onClose: undefined,
      status: undefined,
    })
  },
  openStatusbar: ({ message, actionComponent, status = 'info', onClose }) => {
    set({ message, actionComponent, status, isStatusbarOpen: true, onClose })
  },
}))

export default useStatusbar
