import { styled, css } from '@mui/material/styles'
import { transientPropCheck } from 'component-library/src/styles/utils'

import { ReactComponent as BookIcon } from '../../assets/icons/book.svg'
import { ReactComponent as MultiplierIcon } from '../../assets/icons/multiplier.svg'
import { ReactComponent as MultiplierDoneIcon } from '../../assets/icons/multiplier-done.svg'
import { ReactComponent as LockIcon } from '../../assets/icons/lock-circle.svg'
import { ReactComponent as AlertIcon } from '../../assets/icons/alert.svg'
import { ReactComponent as LayersIcon } from '../../assets/icons/layers.svg'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import { ReactComponent as ChevronLeftIcon } from '../../assets/icons/chevron-left.svg'
import { ReactComponent as ChevronRightIcon } from '../../assets/icons/chevron-right.svg'
import { ReactComponent as ChevronUpIcon } from '../../assets/icons/chevron-up.svg'
import { ReactComponent as ChevronDownIcon } from '../../assets/icons/chevron-down.svg'
import { ReactComponent as MinusIcon } from '../../assets/icons/minus.svg'
import { ReactComponent as PlusIcon } from '../../assets/icons/plus.svg'
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { ReactComponent as ArrowRightMd } from '../../assets/icons/arrow-right-md.svg'
import { ReactComponent as ArrowRightSm } from '../../assets/icons/arrow-right-sm.svg'
import { ReactComponent as Reload } from '../../assets/icons/reload.svg'
import { ReactComponent as WarningTriangle } from '../../assets/icons/warning_triangle.svg'
import LpParticipationIcon from '../../assets/icons/lp-participation@2x.png'
import ASTOLogoPng from '../../assets/icons/asto@2x.png'
import EnergyIcon from '../../assets/icons/energy@2x.png'
import USDCoin from '../../assets/icons/usdcoin.png'
import EnergyIcon2 from '../../assets/icons/energy_v2.png'
import BrainIcon from '../../assets/icons/brain.png'
import VoidIcon from '../../assets/icons/void.png'

export type IconName =
  | 'lock'
  | 'book'
  | 'asto'
  | 'usd-coin'
  | 'energy'
  | 'energy2'
  | 'brain'
  | 'lp-participation'
  | 'multiplier'
  | 'multiplier-done'
  | 'alert'
  | 'layers'
  | 'close'
  | 'chevron-left'
  | 'chevron-right'
  | 'minus'
  | 'plus'
  | 'search'
  | 'chevron-up'
  | 'chevron-down'
  | 'arrow-right-md'
  | 'arrow-right-sm'
  | 'reload'
  | 'void'
  | 'warning-triangle'

interface IconFactoryProps {
  name: IconName
  color?: string
  width?: string | number
}

const IconWrapper = styled(
  'div',
  transientPropCheck
)(
  ({ $width, $color }: { $width: string; $color?: string }) => css`
    width: ${$width};
    min-width: ${$width};
    display: flex;
    align-items: center;

    > svg,
    img {
      width: 100%;
      height: auto;
    }

    ${$color &&
    css`
      > svg {
        color: ${$color};
      }
    `}
  `
)

const Icon = ({ name }: { name: IconFactoryProps['name'] }) => {
  switch (name) {
    case 'lock':
      return <LockIcon />
    case 'book':
      return <BookIcon />
    case 'lp-participation':
      return <img src={LpParticipationIcon} alt="LP Participation Logo" />
    case 'energy':
      return <img src={EnergyIcon} alt="Energy Logo" />
    case 'energy2':
      return <img src={EnergyIcon2} alt="Energy Logo" />
    case 'brain':
      return <img src={BrainIcon} alt="Brain Logo" />
    case 'asto':
      return <img src={ASTOLogoPng} alt="ASTO Logo" />
    case 'usd-coin':
      return <img src={USDCoin} alt="USD Coin" />
    case 'void':
      return <img src={VoidIcon} alt="Void Logo" />
    case 'multiplier':
      return <MultiplierIcon />
    case 'multiplier-done':
      return <MultiplierDoneIcon />
    case 'alert':
      return <AlertIcon />
    case 'layers':
      return <LayersIcon />
    case 'close':
      return <CloseIcon />
    case 'chevron-left':
      return <ChevronLeftIcon />
    case 'chevron-right':
      return <ChevronRightIcon />
    case 'chevron-up':
      return <ChevronUpIcon />
    case 'chevron-down':
      return <ChevronDownIcon />
    case 'minus':
      return <MinusIcon />
    case 'plus':
      return <PlusIcon />
    case 'search':
      return <SearchIcon />
    case 'arrow-right-md':
      return <ArrowRightMd />
    case 'arrow-right-sm':
      return <ArrowRightSm />
    case 'reload':
      return <Reload />
    case 'warning-triangle':
      return <WarningTriangle />
  }
}

// IconFactory component can be used to centralise the way we import svg icons
//  and make easier to switch between icons just by changing the iconName property.
export const IconFactory: React.FC<IconFactoryProps> = ({
  name,
  color,
  width = '45px',
  ...props
}) => {
  return (
    <IconWrapper
      data-testid={`icon-${name}`}
      role={`icon-${name}`}
      $width={typeof width === 'number' ? `${width}px` : width}
      $color={color}
      {...props}
    >
      <Icon name={name} />
    </IconWrapper>
  )
}

export default IconFactory

export type { IconFactoryProps }
