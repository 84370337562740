/* eslint-disable camelcase */
import { useMemo } from 'react'

import { ChainENVs, CHAIN_ENV } from 'src/utils/web3'

import localhostABI from 'smart-contracts/abi/localhost.json'
import { Contract } from 'ethers'
import { useWeb3LibraryProvider } from '../useWeb3LibraryProvider'

// USDC addresses
export const addresses: Record<ChainENVs, string> = {
  localhost: localhostABI.contracts.MockedERC20.address,
  mainnet: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  goerliStaging: '0x2f3A40A3db8a7e3D09B0adfEfbCe4f6F81927557',
  goerli: '0x2f3A40A3db8a7e3D09B0adfEfbCe4f6F81927557',
  kovan: '0x2F375e94FC336Cdec2Dc0cCB5277FE59CBf1cAe5',
}

export const address = addresses[CHAIN_ENV]

const abi = [
  'function approve(address spender, uint256 amount)',
  'function allowance(address owner, address spender) view returns (uint256 allowance)',
  'function balanceOf(address account) view returns (uint256 balance)',
]

export const useUSDCContract = () => {
  const rpcProvider = useWeb3LibraryProvider()

  const contract = useMemo(
    () => new Contract(address, abi, rpcProvider),
    [rpcProvider],
  )

  return contract
}
