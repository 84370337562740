import {
  Collapse,
  MenuItem,
  ListItemIcon,
  MenuItemProps,
  ListItemText as MuiListItemText,
} from '@mui/material'
import React, { useState } from 'react'
import { css, styled } from '@mui/material/styles'
import { Link, useMatch } from 'react-router-dom'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import useNavigationBar from 'src/hooks/store/useNavigationBar'
import { ReactComponent as ArrowSvg } from 'component-library/src/assets/icons/arrow_up_right.svg'
import List from 'component-library/src/components/list/List'

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    border-radius: 4px;
    color: ${theme.palette.grey['500']};

    .MuiListItemIcon-root,
    .MuiTypography-root,
    > .MuiSvgIcon-root {
      color: ${theme.palette.grey['500']};
    }

    :hover {
      background-color: ${theme.palette.grey[700]};
    }

    .MuiTypography-root {
      font-weight: bold;
    }

    &.Mui-selected {
      .MuiListItemIcon-root {
        color: #e6a8e2;
      }

      .MuiTypography-root {
        font-weight: bold;
      }

      .MuiListItemText-root > span.MuiTypography-root {
        background-image: ${theme.palette.gradients.rosePurple};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  `,
)

export type MainNavItemProps = {
  to: string
  text: React.ReactNode
  exact?: boolean
  isExternalLink?: boolean
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  disableExternalLinkIcon?: boolean
} & MenuItemProps

export const MainNavItem: React.FC<MainNavItemProps> = ({
  text,
  to,
  children,
  icon: Icon,
  exact = true,
  isExternalLink = false,
  disableExternalLinkIcon = false,
  ...props
}) => {
  const { closeNavigationBar } = useNavigationBar()
  const match = useMatch({ path: to, end: exact })

  const handleClick = () => {
    closeNavigationBar()
  }

  if (isExternalLink) {
    return (
      <StyledMenuItem selected={!!match} onClick={handleClick} {...props}>
        <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
        <a
          style={{ display: 'contents' }}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MuiListItemText
            data-percy-css={
              match ? 'webkit-override-background-clip-text' : null
            }
          >
            {text}
          </MuiListItemText>
          {isExternalLink && !disableExternalLinkIcon && <ArrowSvg />}
        </a>
      </StyledMenuItem>
    )
  }

  // if disabled it will change to a component that can not be clicked
  const { disabled } = props
  const LinkComponent = disabled ? `span` : Link

  return (
    <LinkComponent to={to}>
      <StyledMenuItem selected={!!match} onClick={handleClick} {...props}>
        <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
        <MuiListItemText
          data-percy-css={match ? 'webkit-override-background-clip-text' : null}
        >
          {text}
        </MuiListItemText>
        {isExternalLink && <ArrowSvg />}
      </StyledMenuItem>
    </LinkComponent>
  )
}

interface MainNavItemGroupProps {
  text: string
  to?: string
  icon?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}

export const MainNavItemGroup: React.FC<MainNavItemGroupProps> = ({
  text,
  to = '',
  icon: Icon,
  children,
}) => {
  const [open, setOpen] = useState(false)
  const match = useMatch({ path: to, end: false })

  const handleClick = () => {
    setOpen(!open)
  }

  return (
    <>
      <StyledMenuItem
        onClick={handleClick}
        selected={!!to && !!match}
        role="menuitem"
        aria-hidden="false"
      >
        <ListItemIcon>{Icon && <Icon />}</ListItemIcon>
        <MuiListItemText>{text}</MuiListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </StyledMenuItem>
      {children && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>{children}</List>
        </Collapse>
      )}
    </>
  )
}
