import { FC } from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import GlobalStyles from '../styles/GlobalStyles'
import theme from '../styles/theme'

export const ThemeProviders: FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <GlobalStyles />
        <CssBaseline />
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  )
}

export default ThemeProviders
