import { Box, keyframes, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import ASMLogo from 'component-library/src/components/icons/ASMLogo'

const Loading: React.FC = () => {
  const blink = keyframes`
    from, 0% { opacity: 0.1; }    
    50% { opacity:1; }
    100%, to { opacity: 0.1; }
  `

  const BlinkedBox = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto 0;
    animation: ${blink} normal 3s infinite ease-in-out;
  `

  return (
    <BlinkedBox>
      <Box
        sx={{
          marginTop: -10,
          display: 'flex',
          alignItems: 'center',
          borderRightStyle: 'solid',
        }}
      >
        <ASMLogo width={64} />
        <Typography
          variant="h2"
          ml={1}
          color="white"
          marginLeft={3}
          fontWeight="normal"
        >
          asm
        </Typography>
      </Box>
    </BlinkedBox>
  )
}

export default Loading
