import { useQuery } from 'react-query'

import { QueryGetNftsSummaryArgs } from 'src/graphql/generated'
import { useWeb3React } from 'src/hooks/useWeb3React'
import { ChainAddress } from 'common/src/types/api-interfaces'
import { gqlFetcher } from 'src/utils/gqlFetcher'
import {
  GetNftsSummaryQuery,
  GetNftsSummaryDocument,
} from 'src/graphql/query/getNftsInfo'
import useInventoryStore from './useInventoryStore'

export const useASMBrainsSummary = () => {
  const { account } = useWeb3React()
  const { setBrainsCount } = useInventoryStore(state => ({
    setBrainsCount: state.setBrainsCount,
  }))

  const brainsSummaryQuery = useQuery(
    ['brain', 'summary', account],
    async () => {
      const res = await gqlFetcher<
        GetNftsSummaryQuery,
        QueryGetNftsSummaryArgs
      >(GetNftsSummaryDocument, {
        walletAddress: account as ChainAddress,
      })
      if (res.getNftsSummary.__typename === 'GetNftsSummaryResponseSuccess') {
        return res.getNftsSummary
      }
      if (res.getNftsSummary.__typename === 'GetNftsSummaryResponseFailure') {
        throw new Error(res.getNftsSummary.__typename)
      }
    },
    {
      enabled: !!account,
      onSuccess: data => {
        setBrainsCount({
          genesisCount: data?.numberOfGenesisBrains ?? 0,
          genIICount: data?.numberOfGen2Brains ?? 0,
        })
      },
    },
  )

  return brainsSummaryQuery
}
