import { Box, css, styled, Typography } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

import Button from 'component-library/src/components/button/Button'
import Chip from 'component-library/src/components/chip/Chip'
import Copy from 'component-library/src/components/icons/Copy'
import IconFactory from 'component-library/src/components/icons/IconFactory'
import { LoadingSkeleton } from 'component-library/src/components/skeleton/LoadingSkeleton'

import { makeOpenseaAssetURL } from 'src/utils/openseaURL'
import { Paths, Slug } from 'src/utils/routes'
import { GradientText } from 'src/components/typography/GradientText'
import { useTranslation } from 'react-i18next'
import {
  BrainContractName,
  isValidBrainContractName,
} from 'src/utils/inventory'
import useASMBrainMetadata, { Brain } from './hooks/useASMBrainMetadata'
import NotFound from '../core/NotFound'
import { ContentNotAvailablePlaceholder } from './assets/ContentNotAvailablePlaceholder'
import useMiningMap from '../mining/hooks/useMiningMap'
import { reportEvent } from 'src/utils/ga'

const Container = styled('div')(
  ({ theme }) => css`
    overflow: hidden;
    border: 1px solid ${theme.palette.grey['700']};
    background-color: ${theme.palette.grey['900']};
    border-radius: 8px;
    width: 100%;
    padding: ${theme.spacing(3)};
    display: flex;
    align-items: center;
  `,
)

const InnerContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex: 1;

    ${theme.breakpoints.down('lg')} {
      align-items: center;
      flex-direction: column;
    }
  `,
)

const BrainContainer = styled('div')(
  ({ theme }) => css`
    position: relative;
    width: 560px;
    aspect-ratio: 1 / 1;

    ${theme.breakpoints.down('md')} {
      width: 480px;
    }
    ${theme.breakpoints.down('sm')} {
      width: 100%;
    }
  `,
)

const GLBViewer = styled('div')(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    position: relative;

    ${theme.breakpoints.down('lg')} {
      width: 100%;
    }
  `,
)

const Detail = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: ${theme.spacing(3, 5)};
    text-align: left;
    width: 100%;

    ${theme.breakpoints.down('lg')} {
      padding: ${theme.spacing(3, 2)};
    }

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(3, 0)};
    }
  `,
)

const DetailSection = styled('div')(
  ({ theme }) => css`
    padding-top: ${theme.spacing(1)};
    padding-bottom: ${theme.spacing(2)};
    border-bottom: 1px solid ${theme.palette.grey['600']};
    &:first-of-type {
      padding-top: 0px;
    }
    &:last-child {
      padding-bottom: 0px;
      border-bottom: 0px;
    }
  `,
)

const StyledChip = styled(Chip)(
  ({ theme }) => css`
    margin: ${theme.spacing(1)};
    margin-left: 0px;
    color: ${theme.palette.text.secondary};

    &.MuiChip-clickable:hover {
      background-color: ${theme.palette.primary.main};
    }
  `,
)

const Title = styled(Typography)(
  ({ theme }) => css`
    font-family: ${theme.typography.fontFamilySecondary};
    font-size: 32px;
    line-height: 42px;
    letter-spacing: 0.25px;
    white-space: pre-line;
  `,
)

const BackButton = styled(Button)(
  ({ theme }) => css`
    position: absolute;
    top: 0px;
    left: 0px;
    margin: ${theme.spacing(2)};

    .MuiButton-startIcon {
      margin-right: ${theme.spacing(0.5)};
    }
  `,
)

const DetailsLine = ({ label, value }: { label: string; value: string }) => {
  return (
    <Typography
      variant="subtitle2"
      component="p"
      noWrap
      sx={{ textOverflow: 'ellipsis' }}
    >
      {label}:&nbsp;
      <Typography
        variant="subtitle1"
        component="span"
        data-cy={`details-line-${label.toLocaleLowerCase().replace(' ', '-')}`}
      >
        {value}
      </Typography>
    </Typography>
  )
}

interface FocusedViewProps {
  brain?: Brain
  tokenId: number
  contractName: BrainContractName
}

const isClickable = (traitType: string) => {
  return traitType === 'Coordinates'
}

const getTraitValue = (traitType: string, value: string) => {
  if (traitType !== 'Coordinates') {
    return value
  }

  const nospaceValue = value.replace(' ', '')
  return [nospaceValue.slice(0, 4), ' ', nospaceValue.slice(4)].join('')
}

const FocusedViewContent = ({
  brain,
  tokenId,
  contractName,
}: FocusedViewProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)
  const { setSearchTile } = useMiningMap(s => ({
    setSearchTile: s.setSearchTile,
  }))

  const contractAddress = brain?.contractAddress

  const handleCopy = useCallback(() => {
    if (!contractAddress) return
    navigator.clipboard.writeText(contractAddress).then(() => {
      setCopied(true)
      setTimeout(() => {
        setCopied(false)
      }, 2000)
    })
  }, [contractAddress])

  const viewOnOpensea = useCallback(() => {
    if (!contractAddress) return
    reportEvent('button_click', {
      button_name: 'InvF_OpenSea',
      page_title: 'Inventory Focused View',
    })
    const openseaURL = makeOpenseaAssetURL(contractAddress, tokenId)
    window.open(openseaURL, 'noopener')
  }, [contractAddress, tokenId])

  const handleGoBack = useCallback(() => {
    // checking if user has arrived from another page, otherwise send them to the inventory page
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1)
    } else {
      navigate(Paths.Inventory)
    }
  }, [navigate])

  // track page view when navigating between pages
  useEffect(() => {
    reportEvent('page_view', {
      page_title: 'Inventory Focused View | ASM',
    })
  }, [])

  const handleTraitClick = useCallback(
    (traitType: string, value: string) => {
      if (traitType === 'Coordinates') {
        const [x, y] = value.replace(' ', '').match(/.{4}/g) ?? []
        // convert from 8 digit to 6
        const searchTile = {
          x: parseInt(x.slice(0, 3)),
          y: parseInt(y.slice(0, 3)),
        }
        setSearchTile(searchTile)
        navigate(`${Paths.GenomeMining}`, {
          state: { from: 'inventory' },
        })
      }
    },
    [navigate, setSearchTile],
  )

  const generation = t(
    contractName === BrainContractName.ASMBrain ? 'genesis' : 'gen-2',
  )

  return (
    <Container data-testid="inventory-focused-view">
      <Helmet>
        <title>Inventory Focused View | ASM</title>
      </Helmet>
      <InnerContainer>
        <GLBViewer>
          <LoadingSkeleton
            loading={!brain}
            variant="rectangular"
            sx={{ backgroundColor: 'grey.800', borderRadius: '8px' }}
          >
            <BrainContainer>
              {brain &&
                (brain.animationUrl ? (
                  <iframe
                    src={brain?.animationUrl}
                    title={`Brain #${tokenId}`}
                    frameBorder="0"
                    width="100%"
                    height="100%"
                  ></iframe>
                ) : (
                  <ContentNotAvailablePlaceholder />
                ))}
            </BrainContainer>
          </LoadingSkeleton>
          <BackButton
            data-cy={`back-button`}
            startIcon={<IconFactory name="arrow-right-sm" width={24} />}
            sx={{
              backgroundColor: 'grey.700',
              '&:hover': { background: 'unset', backgroundColor: 'grey.600' },
            }}
            onClick={handleGoBack}
          >
            {t('inventory.focused-view.go-back')}
          </BackButton>
        </GLBViewer>
        <Detail>
          <DetailSection>
            <LoadingSkeleton
              loading={!brain}
              width="100%"
              sx={{ marginBottom: 1 }}
              variant="text"
            >
              <Title>{generation}</Title>
              <Title gutterBottom>
                {t('brain')}
                <GradientText
                  variant="h4"
                  component="span"
                >{` #${tokenId}`}</GradientText>
              </Title>
            </LoadingSkeleton>
            <Button
              onClick={viewOnOpensea}
              data-testid={'view-on-opensea'}
              sx={{ marginBottom: 2 }}
              disabled={!brain}
            >
              {t('inventory.focused-view.view-on-opensea')}
            </Button>
          </DetailSection>
          <DetailSection sx={{ flex: '1' }}>
            <LoadingSkeleton loading={!brain} width="100%">
              <Typography variant="h5">
                {t('inventory.focused-view.properties')}
              </Typography>
            </LoadingSkeleton>
            {brain && (
              <Box sx={{ maxWidth: '600px' }}>
                {brain?.attributes.map(({ traitType, value }) => (
                  <StyledChip
                    key={traitType}
                    data-cy={`attributes-${traitType.toLocaleLowerCase()}`}
                    variant="outlined"
                    label={`${traitType} - ${getTraitValue(traitType, value)}`}
                    onClick={
                      isClickable(traitType)
                        ? () => handleTraitClick(traitType, value)
                        : undefined
                    }
                  />
                ))}
              </Box>
            )}
          </DetailSection>
          <DetailSection>
            <LoadingSkeleton loading={!brain} width="100%" variant="text">
              <div
                style={{
                  display: 'inline-flex',
                  maxWidth: '300px',
                }}
              >
                <DetailsLine
                  label="Contract Address"
                  value={contractAddress || ''}
                />
                <span style={{ marginLeft: '.5rem' }}>
                  <Copy onClick={handleCopy} copied={copied} />
                </span>
              </div>
              <DetailsLine label="Token ID" value={tokenId?.toString()} />
              <DetailsLine label="Token Standard" value="ERC-721" />
              <DetailsLine label="Blockchain" value="Ethereum" />
            </LoadingSkeleton>
          </DetailSection>
        </Detail>
      </InnerContainer>
    </Container>
  )
}
interface FocusedViewLoaderProps {
  tokenId: number
  contractName: BrainContractName
}

const FocusedViewLoader = ({
  tokenId,
  contractName,
}: FocusedViewLoaderProps) => {
  const { brainMetadataQuery } = useASMBrainMetadata(tokenId, contractName)
  if (brainMetadataQuery.isError) {
    return <NotFound />
  }

  return (
    <FocusedViewContent
      brain={brainMetadataQuery.data}
      tokenId={tokenId}
      contractName={contractName}
    />
  )
}

// perform basic validation of tokenId and brainContractName
export const FocusedView = () => {
  const params = useParams()
  const tokenId = params[Slug.TokenId]
  const contractName = params[Slug.ContractName]
  const tokenIdInt = Number(tokenId)

  const isValidId = !(!tokenId || isNaN(tokenIdInt))
  const isValidGen = contractName && isValidBrainContractName(contractName)

  if (!isValidId || !isValidGen) return <NotFound />

  return <FocusedViewLoader contractName={contractName} tokenId={tokenIdInt} />
}
