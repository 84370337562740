import { styled, css } from '@mui/material/styles'
import Breadcrumbs from 'component-library/src/components/navigation/Breadcrumbs'
import { useMemo } from 'react'

import {
  Link,
  useLocation,
  RouteObject,
  PathMatch,
  matchPath,
} from 'react-router-dom'

const crumbsDefinitions: RoutePathDefinition[] = [
  //   { path: '/', label: 'Home' },
  {
    path: '/genome-mining',
    label: 'Genome Mining',
    children: [{ path: 'lunaris-machine', label: 'Lunaris Machine' }],
  },
  //   { path: '/discovery', label: 'Discovery' },
  //   { path: '/all-star-claim', label: 'All Star Claim' },
  { path: '/inventory', label: 'Inventory' },
  //   { path: '/brain-claim-check', label: 'Brain Claim Check' },
  //   { path: '/rewards', label: 'Rewards' },
]

export interface RoutePathDefinition extends RouteObject {
  label: string
  children?: RoutePathDefinition[]
  path: string
}

export interface ActiveRoutePath {
  match: PathMatch<string>
  definition: RoutePathDefinition
}

const joinPaths = (paths: string[]): string => {
  return paths.join('/').replace(/\/\/+/g, '/')
}

const matchRouteDefinitions = (
  definitions: RoutePathDefinition[],
  locationPathname: string,
  parentPath: string = '',
): ActiveRoutePath[] => {
  const crumbs: ActiveRoutePath[] = []

  definitions.forEach(definition => {
    const pathPatternWithParent = joinPaths([parentPath, definition.path])

    const match = matchPath(
      { path: pathPatternWithParent, end: false },
      locationPathname,
    )
    if (match) {
      crumbs.push({
        match: match,
        definition: definition,
      })

      if (definition.children) {
        const nestedMatches = matchRouteDefinitions(
          definition.children,
          locationPathname,
          pathPatternWithParent,
        )

        crumbs.push(...nestedMatches)
      }
    }
  })

  return crumbs
}

const useActiveRoutePaths = (
  routes: RoutePathDefinition[],
): ActiveRoutePath[] => {
  const location = useLocation()
  const activeRoutePaths = useMemo(
    () => matchRouteDefinitions(routes, location.pathname),
    [location, routes],
  )
  return activeRoutePaths
}

const StyledCrumb = styled(Link)``

const StyledActiveCrumb = styled('span')`
  font-weight: bold;
  color: white;
`

const StyledBreadcrumbsContainer = styled('div')(
  ({ theme }) => css`
    ${theme.breakpoints.down('md')} {
      display: none;
    }
  `,
)

export const BreadcrumbsBar = () => {
  const activeRoutePaths: ActiveRoutePath[] =
    useActiveRoutePaths(crumbsDefinitions)

  return (
    <div>
      {/* only displays breadcrumbs when has more than 1 active route */}
      {activeRoutePaths.length > 1 && (
        <StyledBreadcrumbsContainer data-testid="breadcrumbs">
          <Breadcrumbs>
            {activeRoutePaths.map((active, index, { length }) => {
              return index !== length - 1 ? (
                <StyledCrumb to={active.match.pathname} key={index}>
                  {active.definition.label}
                </StyledCrumb>
              ) : (
                <StyledActiveCrumb
                  data-testid="current-breadcrumb"
                  key="current-breadcrumb"
                >
                  {active.definition.label}
                </StyledActiveCrumb>
              )
            })}
          </Breadcrumbs>
        </StyledBreadcrumbsContainer>
      )}
    </div>
  )
}
