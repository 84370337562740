import { css, styled } from '@mui/material/styles'
import Loading from 'src/components/Loading'

const Container = styled('div')(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    overflow-y: auto;
    align-items: center;
    flex-direction: column;
    padding: ${theme.spacing(6, 3)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(4, 2)};
    }
  `,
)

const PageLoading: React.FC = () => {
  return (
    <Container>
      <Loading />
    </Container>
  )
}

export default PageLoading
