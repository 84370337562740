import {
  css,
  MenuItem as MuiMenuItem,
  MenuItemProps,
  styled,
  Typography,
} from '@mui/material'

const StyledMenuItem = styled(MuiMenuItem)(
  ({ theme }) => css`
    background-color: ${theme.palette.grey['700']};
    padding: 9px;
  `
)

const SelectItem: React.FC<MenuItemProps> = ({ children, ...props }) => {
  return (
    <StyledMenuItem {...props}>
      <Typography variant="body1" component="span">
        {children}
      </Typography>
    </StyledMenuItem>
  )
}

export default SelectItem
