import {
  styled,
  css,
  alpha,
  OutlinedInput as MuiOutlinedInput,
} from '@mui/material'

const StyledInput = styled(MuiOutlinedInput)(
  ({ theme }) => css`
    border-radius: 12px;
    --bg-color: ${alpha(theme.palette.grey['600'], 0.1)};
    --border-color: ${theme.palette.grey['600']};

    &.MuiInputBase-root {
      color: white;
      background: var(--bg-color);
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--border-color);
    }
    &.MuiOutlinedInput-root:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: var(--border-color);
      }
    }

    &.Mui-focused {
      --bg-color: ${alpha(theme.palette.primary.main, 0.1)};
      --border-color: ${theme.palette.primary.main};
    }

    &.Mui-error {
      --bg-color: ${alpha(theme.palette.error.main, 0.1)};
      --border-color: ${theme.palette.error.main};
    }
  `
)
export default StyledInput
