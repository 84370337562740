const enUS = {
  // common
  'connect-wallet': 'Connect Wallet',
  available: 'Available',
  asto: 'ASTO',
  lp: 'LP',
  usdc: 'USDC',
  'asto-auction': 'ASTO Auction',
  'ae-per-day': 'Æ per day',
  'asto-usdc': 'ASTO-USDC',
  'lp-balance': 'LP Balance',
  'lp-tokens': 'LP Tokens',
  'lp-participation': 'LP Participation',
  'lba-participation': 'ASTO Auction Participation',
  added: 'Added',
  removed: 'Removed',
  'token-available': 'Available {{token}}',
  'token-added': '{{token}} added',
  'token-removed-amount': '<large>{{amount}}</large> {{token}} Removed',
  'token-added-amount': '<large>{{amount}}</large> {{token}} Added',
  view: 'View',
  'value-with-unit': '{{amount}}',
  'value-with-unit_asto': '{{amount}} ASTO',
  'value-with-unit_lp': '{{amount}} LP',
  'value-with-unit_ae': '{{amount}} Æ',
  'value-with-unit_ae-daily': '{{amount}} Æ per day',
  'lp-multiplier': 'LP Multiplier',
  'available-ae': 'Available Æ',
  radius: 'Radius',
  sweep: 'Sweep',
  cancel: 'Cancel',
  'minutes-with-count_one': '{{count}} minute',
  'minutes-with-count_other': '{{count}} minutes',
  'hours-with-count_one': '{{count}} hour',
  'hours-with-count_other': '{{count}} hours',
  'days-with-count_one': '{{count}} day',
  'days-with-count_other': '{{count}} days',
  'weeks-with-count_one': '{{count}} week',
  'weeks-with-count_other': '{{count}} weeks',
  'brains-with-count_one': '{{count}} Brain',
  'brains-with-count_other': '{{count}} Brains',
  brain: 'Brain',
  genesis: 'Genesis',
  'gen-2': 'Gen II',
  closed: 'Closed',
  'closing-soon': 'Closing Soon',

  // connect wallet dialog
  'connect-wallet.title': 'Select wallet to connect',
  'connect-wallet.description':
    'You need an Ethereum wallet to access the dashboard.',
  'connect-wallet.multiple-wallet-warning':
    'Multiple wallet browser extensions have been detected which may prevent you from switching between them. We recommend that you have only one wallet browser extension enabled.',
  // main navigation
  'navigation.dashboard': 'Cortex Home',
  'navigation.lunaris-machine': 'Lunaris Machine',
  'navigation.genome-mining': 'Genome Mining',
  'navigation.genome-mining-stake': 'Stake ASTO',
  'navigation.all-star-claim': 'All-Star Claim',
  'navigation.inventory': 'Inventory',
  'navigation.discovery-auction': 'Discovery Auction',
  'navigation.rewards': 'Rewards',
  'navigation.blog': 'Medium',
  'navigation.asto-faqs': '$ASTO FAQs',
  'navigation.genome-mining-faqs': 'GM FAQs',
  'navigation.auction-video': 'Auction Video',
  'navigation.open-menu': 'Open Menu',
  'navigation.void-faq': 'Voids FAQs',

  // main Hub
  'hub.genome-mining-started': 'Get Started',

  // all star claim page
  'all-star-claim.title': 'All-Star Claim Overview',
  'all-star-claim.description':
    'Stake your All-Stars to earn rewards based on rarity and how long you wait to claim.',
  'all-star-claim.approve-contract-title':
    'Approve Contract to start staking your All-Stars and earning ASTO rewards ',
  'all-star-claim.approve-contract-description':
    'A reason why contract needs to be approved. It will cost a small amount of gas, usually around $30USD to successful approve contract for interaction',
  'all-star-claim.approve-contract-button': 'Approve Contract',

  // all star page connect wallet
  'all-star-claim.connect-wallet.title': "Let's Get Connected",
  'all-star-claim.connect-wallet.description1':
    'A wallet must be connected to stake your All-Star',
  'all-star-claim.connect-wallet.description2':
    "Don't have an All-Star yet? All-Stars can be purchased on the secondary markets, like OpenSea.",

  // all star page how it works
  'all-star-claim.how-it-works.block-title': 'How it works',
  'all-star-claim.how-it-works.title-1': 'Stake Your All-Stars',
  'all-star-claim.how-it-works.description-1':
    'Select eligible all-Stars and stake them in the Reverse Claim Event.',
  'all-star-claim.how-it-works.title-2': 'Let the rewards accumulate',
  'all-star-claim.how-it-works.description-2':
    'Once you have stake your All-Star they will start to accumulate rewards day to day on a weekly basis.',
  'all-star-claim.how-it-works.title-3': 'Rewards can be claimed at any time',
  'all-star-claim.how-it-works.description-3':
    'Claim rewards immediately unstakes the associated All-Stars and distributes any remaining weekly rewards to staked participants. All-Stars may be restaked at any time but will not accumulate ASTO rewards until the start of the next week.',
  'all-star-claim.how-it-works.placeholder-image-alt': 'placeholder image',

  // allstar claim page popup
  'all-star-claim.popup.title': 'What is Reverse <1>Claim?</1>',
  'all-star-claim.popup.highlight': 'reverse claim',
  'all-star-claim.popup.description': `Stake All-Stars to earn ASTO rewards. The longer you wait to claim, the higher the reward! 
  Claim now or wait... <1>the choice is yours!</1>`,
  'all-star-claim.popup.button': 'See Reverse Claim',

  // genome mining overview page
  'genome-mining.overview.energy-title':
    'Generate ASTO-Energy\n in the Lunaris Machine',
  'genome-mining.overview.energy-description':
    'Generate ASTO-Energy by charging the Lunaris Machine\n with ASTO or ASTO-USDC LP.',
  'genome-mining.overview.energy-tip.generation': 'Your generation per day',
  'genome-mining.overview.energy-tip.total-generation': 'Your total generation',
  'genome-mining.overview.energy-tip.days-remaining':
    'Time remaining to generate AE in PC{{cycle}}',
  'genome-mining.overview.daily-production-cycle': 'Production',
  'genome-mining.overview.daily-production-cycle-tooltip':
    'The Lunaris Machine is the only known device that can generate ASTO-Energy. It was created by me, Professor Jenny Brian.',
  'genome-mining.overview.daily-production-rate-tooltip':
    'The production cycle lasts for 60 days in total. This allows the Generated ASTO-Energy to Stabilize before use.',
  'genome-mining.overview.daily-production-rate': 'Daily Production rate',
  'genome-mining.overview.daily-production-rate-value': '{{value}} Æ per day',
  'genome-mining.overview.your-ae': 'Your ASTO Energy',
  'genome-mining.overview.your-ae-value': '{{value}} Æ',
  'genome-mining.overview.total-ae-used': 'Total Æ used',
  'genome-mining.overview.remaining': 'remaining',
  'genome-mining.overview.remaining-this-cycle': 'Remaining this cycle',
  'genome-mining.overview.exploration-title':
    'Alleriya Exploration\n and Genome Mining',
  'genome-mining.overview.exploration-description':
    'Explore the Alcherin Island and harness your ASTO-Energy to mine unique Gen ll Brains.',
  'genome-mining.overview.exploration-tip.cost':
    'Price to mine one Gen II Brain',
  'genome-mining.overview.exploration-tip.brains-available':
    'Brain supply left in MC{{cycle}}',
  'genome-mining.overview.exploration-tip.days-remaining':
    'Time left to mine in MC{{cycle}}',
  'genome-mining.overview.multiplier-tracker-tooltip':
    'On day 60, your final rate of ASTO-Energy generation will be calculated based on an average of the highest daily value of ASTO on the DEX across the current Production Cycle. \nMultipliers will be applied to the final calculation at the end of the Production Cycle.',
  'genome-mining.overview.multiplier-tracker-lp-tooltip':
    'The multiplier for ASTO-USDC LP contribution is a static 1.2x across all Production Cycles. \n\nAt the end of each Production Cycle, your final rate of ASTO-Energy generation will be calculated based on an average of the highest daily value of ASTO on the DEX.',
  'genome-mining.overview.mining-description':
    'Harness your generated ASTO-Energy from the Lunaris Machine to mine unique Gen ll Brains. Explore and reveal Alleriya’s many regions with the community as you mine.',
  'genome-mining.overview.mining-insufficient-ae-warning':
    'You currently have insufficient ASTO-Energy for mining. You can continue to explore the map of Alleriya as you generate more ASTO-Energy.',
  'genome-mining.overview.mining-inactive-warning':
    'STATUS: GENOME MINING IS CURRENTLY INACTIVE',
  'genome-mining.overview.mining-cycle': 'Mining Cycle {{value}}',
  'genome-mining.overview.mining-cost': 'Brain Cost',
  'genome-mining.overview.brains-available': 'Brains Available',
  'genome-mining.overview.brains-available-value': '{{value}} Brains',

  // genome mining page
  'genome-mining.title': 'Genome Mining',
  'genome-mining.description':
    'Use ASTO and LP Tokens to generate ASTO-Energy and mine Gen ll Brains.',
  'genome-mining.connect-wallet.title': "Let's Get Connected",
  'genome-mining.connect-wallet.description1':
    'Please connect your wallet to participate in this Cortex feature, including staking ASTO to generate ASTO-Energy and participating in Genome Mining.',
  'genome-mining.connect-wallet.description2':
    'ASTO may be purchased from <0>UniSwap</0>. To learn more about Genome Mining read our article <1>here</1>.',
  'genome-mining.hub.description':
    'Generate ASTO-Energy by staking and mine Gen II Brains',
  // genome mining storytelling
  'genome-mining.storytelling.title1': 'Deep in the Recesses of Space...',
  'genome-mining.storytelling.description1':
    'Seekers on an exploratory journey were drawn to a series of high frequency light pulses...',
  'genome-mining.storytelling.title2': 'The Discovery...',
  'genome-mining.storytelling.description2':
    'Arriving at the source of the light, the Seekers discovered what appeared to be a tear in space...\n\nOn its way to the Tear, one of the Seekers observed a research ship floating nearby.',
  'genome-mining.storytelling.title3': 'The Professor',
  'genome-mining.storytelling.description3':
    'Within the ship, a figure stood fixed, studying the results from her invention. A screen illuminates and she springs into action, scanning the data and tapping controls.\n\nOn her lab coat, an embroidered tag reads "Professor Jenny Brian"...',
  'genome-mining.storytelling.title4': 'The Experiment',
  'genome-mining.storytelling.description4':
    'The Professor has just discovered a unique form of energy... ASTO-Energy!\n\nHer invention, the Lunaris Machine, created a way to use ASTO to generate this new energy, harnessing the power of the Tear. ',
  'genome-mining.storytelling.title5': 'ASTO-Energy Production',
  'genome-mining.storytelling.description5':
    'ASTO held within the Lunaris Machine continously generates ASTO-Energy (Æ).\n\nThe stored Æ is released at the end of a 60 day Production Cycle, at which point it is ready to be used.\n\nThe Professor and Brain 1 have finally found an energy source to mine a new generation of Brains...',
  'genome-mining.storytelling.button5': 'Charge the Lunaris Machine',
  'genome-mining.storytelling.explore-button': 'Explore',
  'genome-mining.storytelling.discover-button': 'Discover',
  'genome-mining.storytelling.next-button': 'Next',
  'genome-mining.storytelling.skip-button': 'Skip Story',

  // lunaris machine
  'lunaris-machine.title': 'Lunaris Machine',
  'lunaris-machine.tooltip':
    'The Lunaris Machine is the only known device that can generate ASTO-Energy. It was created by me, Professor Jenny Brian. ',
  'lunaris-machine.overview': 'Overview',
  'lunaris-machine.overview.lp-subtext': 'ASTO Auction Participation',
  'lunaris-machine.overview.tokens-use': 'Assets in use',
  'lunaris-machine.overview.assets-staked': 'Assets Staked',
  'lunaris-machine.overview.my-total-brains': 'My Total Brains Mined',
  'lunaris-machine.overview.pending-ae': 'Pending Æ from cycle {{value}}',
  'lunaris-machine.overview.current-brain-price': 'Current Brain price',
  'lunaris-machine.overview.total-daily-ae-production':
    'Total daily Æ production',
  'lunaris-machine.overview.total-ae-available': 'Total Æ available',
  'lunaris-machine.overview.lba-participation-tooltip':
    'ASTO Auction LP tokens are automatically generating ASTO-Energy for you. No action required.',
  'lunaris-machine.overview.lp-daily-production': 'Currently generating Æ',
  'lunaris-machine.overview.daily-production': 'Daily Generation',
  'lunaris-machine.overview.current-daily-production':
    'Current Daily Generation',
  'lunaris-machine.overview.daily-ae-production': 'Daily Æ Production',
  'lunaris-machine.overview.asto-energy-generated-total':
    'Total Generated (All Production Cycles)',
  'lunaris-machine.overview.lba-generated-total': 'Total Generated',
  'lunaris-machine.overview.total-ae-generated': 'Total Æ Generated',
  'lunaris-machine.overview.lp-energy-generated-total': 'Total Generated',
  'lunaris-machine.overview.coming-soon': '[Configuring Monitoring Station]',
  'lunaris-machine.overview.monitoring': 'Monitoring',
  'lunaris-machine.overview.my-assets': 'My Assets',
  'lunaris-machine.add': 'Charge Machine',
  'lunaris-machine.add.tip': 'Add tokens to generate ASTO-Energy',
  'lunaris-machine.add.title': 'Add tokens to charge the Lunaris Machine',
  'lunaris-machine.add.button': 'Add Tokens',
  'lunaris-machine.remove-asto': 'Remove ASTO',
  'lunaris-machine.add-asto': 'Add ASTO',
  'lunaris-machine.approve-contract-explanation':
    'Contract needs to be approved before being able\n to add ASTO to the Lunaris Machine',
  'lunaris-machine.approve.button': 'Approve contract',
  'lunaris-machine.increase-allowance.button': 'Increase Allowance',
  'lunaris-machine.remove': 'Discharge Machine',
  'lunaris-machine.remove.tip': 'Withdraw assets from the Lunaris Machine',
  'lunaris-machine.remove.title':
    'Remove tokens to discharge the Lunaris Machine',
  'lunaris-machine.remove.button': 'Extract',
  'lunaris-machine.asto-available': 'Available ASTO',
  'lunaris-machine.earnings-per-day': 'ASTO-Energy return rate',
  'lunaris-machine.projection': 'Projected daily generation',
  'lunaris-machine.jenny-brain': 'Prof. Jenny Brian',
  'lunaris-machine.projection-tooltip':
    'Your daily estimated generation of ASTO-Energy',
  'lunaris-machine.overview.title': 'Welcome to Lunaris Rev 2.0!',
  'lunaris-machine.overview.subtitle':
    'In response to the voidpool emergency, which required immediate generation of ASTO-Energy, Brain 1 created a new revision of the Lunaris Machine in a Megacity facility.  Now ASTO-Energy can be produced on demand, allowing Seekers to access it on the hour. Congratulations on this incredible feat!',
  'lunaris-machine.overview.daily-rate': 'Total Daily ASTO-Energy (Æ)',
  'lunaris-machine.overview.energy-generated': 'Your Total ASTO-Energy (Æ)',
  'lunaris-machine.overview.available-mining-ae': 'Available for mining',
  'lunaris-machine.overview.ae-available': 'Æ available to mine',
  'lunaris-machine.add.confirm-tx-helper.title':
    'Confirm transaction to start generating ASTO-Energy',
  'lunaris-machine.add.confirm-tx-helper.description':
    'Please approve your transaction to commit ASTO and initiate ASTO-Energy generation. Each transaction will incur a gas fee ranging from approx. $11-$100. If the gas fee is too high please return at a later time.',
  'lunaris-machine.add.success':
    'You have successfully supplied charge to the Lunaris Device',
  'lunaris-machine.remove.success':
    'You have successfully removed your assets from the Lunaris Machine',
  'lunaris-machine.remove.confirm-tx-helper.title':
    'Confirm transaction to remove ASTO from the Production Cycle',
  'lunaris-machine.remove.confirm-tx-helper.description':
    'Removal of Tokens from the Production Cycle will require you to sign and approve your transaction. Each transaction will incur a gas fee ranging from approx. $11-$100. If the gas fee is too high please return at a later time.',
  'lunaris-machine.add.success.image-alt': 'Lunaris Device Charged',
  'lunaris-machine.remove.success.image-alt': 'Lunaris Device Empty',
  'lunaris-machine.remove.confirm.title': 'Confirm the removal of tokens',
  'lunaris-machine.remove.confirm.message':
    'Once removed, these tokens no longer produce ASTO-Energy and will be returned to your wallet. ASTO-Energy earned to date will remain available.',
  'lunaris-machine.remove.confirm.yes': 'Yes, Remove Tokens',
  'lunaris-machine.remove.confirm.no': 'Cancel Removal',
  'lunaris-machine.remove.participation-lp-warning':
    'Warning: ASTO-Energy Unstable!!\nWithdrawal of ASTO Auction LP Tokens from Genome Mining will remove all tokens and fortfeit any previously generated ASTO-Energy. Additionally, any multiplier streaks accumulated will be lost, and eligibilty for multiplier streaking in future Production Cycles will be forfeit.',
  'lunaris-machine.add.participation-lp-text1':
    'All ASTO Auction LP tokens have generated ASTO-Energy since the start of Genome Mining. ASTO Auction participants receive streak multipliers upon completion of each 60 Day Production Cycle.',
  'lunaris-machine.add.participation-lp-text2':
    'Æ Generation multiplier streaks for ASTO Auction LP Tokens',
  'lunaris-machine.add.participation-lp-tooltip':
    'ASTO Auction LP multipliers are eligible for a streaking increase in each successive Production Cycle.',
  'lunaris-machine.active-lp-participation.title': 'Active LP Participation',
  'lunaris-machine.active-lp-participation.amount':
    '{{amount}} <small>LP</small>',
  'lunaris-machine.daily-generation.title': 'Daily Generation',
  'lunaris-machine.daily-generation.amount':
    '{{amount}} <small>Æ per day</small>',
  'lunaris-machine.total-generated.title': 'Total generated',
  'lunaris-machine.total-generated.amount': '{{amount}} <small>Æ</small>',
  'lunaris-machine.energy-generation-rate':
    '1 {{token}} = <b>{{multiplier}} Æ per day</b>',
  'lunaris-machine.add-token': 'Add {{token}}',
  'lunaris-machine.remove-token': 'Remove {{token}}',
  'lunaris-machine.remove.confirm.lp-participation.title': 'Are you sure?',
  'lunaris-machine.remove.confirm.lp-participation.message':
    'Due to ASTO-Energy instability when using ASTO Auction LP Tokens, removing these tokens from the Lunaris Machine will forfeit any Æ generating within this Production Cycle. Multiplier streaking eligibility will also be lost.',
  'lunaris-machine.remove.confirm.lp-participation.energy-lost':
    ' ASTO-Energy to be lost: {{amount}}',
  'lunaris-machine.remove.confirm.days-to-release':
    '{{days}} Days remaining until Æ release',
  'lunaris-machine.remove.confirm.less-than-a-day-to-release':
    'Less than a day remaining until Æ release',

  // lunaris-machine story-telling
  'lunaris-machine.story-telling.title1': 'Monitoring Station',
  'lunaris-machine.story-telling.description1':
    'Inundated with the volume of ASTO-Energy pouring through the Tear, Professor Brian knew her next step was to build a device to track the total amount of Energy flowing into the Lunaris Machine. \n\nWhile makeshift in its design, this custom Monitoring Station provided a great level of insight into the progressing Production Cycle, revealing patterns she’d not previously noticed.',
  'lunaris-machine.story-telling.title2': 'Transformation',
  'lunaris-machine.story-telling.description2':
    'As ASTO-Energy continued to pour through the Tear, a rare phenomenon unfolded before the Professor’s eyes. The Energy began to collect and solidify... It was rapidly materializing into a sharp crystal shard!\n\nThe Shard has persisted with its growth as Production progressed, and now both Professor Brian and Brain 1 continue to monitor the situation carefully.',
  'lunaris-machine.story-telling.button2': 'View monitoring',

  // transaction dialog
  'transaction-dialog.successful-approve-message':
    'You have successfully approved the contract',
  'transaction-dialog.view-transaction-link':
    'View Transaction on block explorer',
  'transaction-dialog.successful-claimed-message':
    'You have successfully claimed',
  'transaction-dialog.pending-title': 'Transaction Pending',
  'transaction-dialog.pending-message-line1':
    'This can sometimes take a bit of time.',
  'transaction-dialog.pending-message-line2': 'Just hang tight.',
  'transaction-dialog.succesful-transaction-title': 'Transaction successful',
  'transaction-dialog.close-button': 'Close',

  // vesting history table
  'vesting-history.no-rows': 'Nothing claimed yet',
  'vesting-history.header-date-claimed': 'Date Claimed',
  'vesting-history.header-reward-amount': 'Reward Amount',

  // vesting schedule table
  'vesting-schedule.no-rows': 'No Claims Available',
  'vesting-schedule.header-week': 'Week',
  'vesting-schedule.header-availability': 'Availability',
  'vesting-schedule.header-reward-amount': 'Reward Amount',

  // asto auction page
  'asto-auction.header': 'Total estimate rewards',
  'asto-auction.btn.deposit': 'Deposit and Earn ASTO',
  'asto-auction.btn.view-rewards': 'View available rewards',
  'asto-auction.btn.available-when-finished': 'Available when auction finished',
  'asto-auction.intro.add': 'Add to the auction to earn ASTO',
  'asto-auction.intro.closed': 'Auction Deposits now closed',
  'asto-auction.intro.did-not-participate':
    'You did not participate in the ASTO Auction',
  'asto-auction.intro.every-7-days':
    'Rewards distributed every 7 days after auction closes',
  'asto-auction.info':
    'Est. total rewards from your share\nof the auction (The rewards will\nchange and adjust as more people\nadd to the auction). The rewards are\ndistributed every 7 Days over the\ncourse of 3 months.',
  'asto-auction.balance-reward.pool-weight': 'Pool weight:',
  'asto-auction.balance-reward.estimated-rewards': 'Estimated Rewards:',

  // auction block
  'asto-auction.auction.screen-title': 'Discovery Auction',
  'asto-auction.auction.screen-description':
    'The ASTO Discovery Auction is a way for participants to influence the price of ASTO and receive rewards',
  'asto-auction.auction.header': 'ASTO Discovery Auction',
  'asto-auction.auction.discovery-auction':
    'The price discovery phase of the ASTO token has now ended. The token is now tradable on <0>Uniswap</0>.',
  'asto-auction.auction.asto-usdc': 'ASTO-USDC',
  'asto-auction.auction.auction-finished': '(Auction Finished)',
  'asto-auction.auction.total-allocated-rewards': 'Total Allocated Rewards:',
  'asto-auction.auction.total-allocated-asto': '23,840,000 ASTO',
  'asto-auction.auction.result-message':
    'This is the result at the end of the ASTO price discovery auction.',
  'asto-auction.auction.asto-contract-address': 'ASTO Contract Address',
  'asto-auction.auction.buy-asto': 'Buy ASTO',
  'asto-auction.auction.info-tooltip':
    'The figure shown is the total reward pool for the ASTO Discovery Auction (1% of total ASTO). Total reward is split between ASTO and USDC. Upon conclusion of the Auction, the volume of ASTO and USDC deposited will determine the final reward rate for all participants per ASTO and USDC.',

  // balance
  'asto-auction.balance.title': 'Discovery Auction Balance',
  'asto-auction.balance.description':
    'ASTO Discovery Auction participants have been generating ASTO-Energy from the start of Genome Minining. ASTO Auction LP Tokens can be withdrawn at any time.',
  'genome-mining.balance.committed-asto': 'Committed ASTO',
  'genome-mining.balance.committed-usdc': 'Committed USDC',
  'genome-mining.balance.total-generated-cycle': 'Total Generated',

  // monitoring
  'genome-mining.monitoring.total-energy': 'totalEnergy',
  'genome-mining.monitoring.my-energy': 'myTotalEnergy',
  'genome-mining.monitoring.energy-conversion': 'energyConversion containment',
  'genome-mining.monitoring.production-efficiency': 'Production efficiency:',
  'genome-mining.monitoring.experiment': 'experiment:',

  // mining cycle 1 popup
  'genome-mining.mining-popup.cycle-title': 'Mining Cycle {{period}} Initiated',
  'genome-mining.mining-popup.description':
    'Mining Cycle {{period}} is now active for a total of 60 days, while Production Cycle {{period}} has rolled over to continue Æ generation. At the conclusion of MC01, a new price for Gen ll Brains will be set.',
  'genome-mining.mining-popup.button': 'Explore and Mine',

  // lp live
  'asto-auction.lp-live.title':
    'ASTO Auction LP Tokens are now generating ASTO-Energy!',
  'asto-auction.lp-live.description':
    'ASTO Discovery Auction participants have been automatically generating ASTO-Energy since the beginning of this cycle. ASTO Auction participants are eligible for streak multipliers upon completion of each cycle at which point the generated Æ will be released and available for use.',
  'asto-auction.lp-live.notice-title':
    'Your ASTO Auction LP Tokens are already generating ASTO-Energy. No further action is required.',
  'asto-auction.lp-live.amount': 'LP amount',
  'asto-auction.lp-live.daily-title': 'Total daily Æ Production',
  'asto-auction.lp-live.generated-title': 'Total Generated',
  'asto-auction.lp-live.multiplier-title': 'Cycle Multiplier',

  // ASTO auction participation
  'asto-auction.claim-lp-tokens-block.title': 'Withdraw your LP Tokens',
  'asto-auction.claim-lp-tokens-block.description':
    'ASTO Auction participants may withdraw their LP Tokens at any time. ASTO Auction LP Tokens, once withdrew, forfeit any generated ASTO-Energy that has not been released at the end of each Production Cycle.',
  'asto-auction.claim-lp-tokens-block.lp-available':
    'LP available <0>{{value}} LP</0>',

  // Notification messages
  'lpa-event-end-notification':
    'The ASTO Discovery Auction lock-up period has now ended. Discovery Auction LP Tokens are now automatically generating ASTO-Energy in the Genome Mining event.',

  // mining page
  'genome-mining.go-back': 'Go back',
  'genome-mining.mining.brain-circulating-supply': 'Brain circulating supply',
  'genome-mining.mining.action-tips': 'Action tips',
  'genome-mining.mining.mining-price': 'Mining Price',
  'genome-mining.mining.mining-available': 'Mining Available',
  'genome-mining.mining.return-to-index': 'Return to Index',
  'genome-mining.mining.mining-slots': 'Mining Slots',
  'genome-mining.mining.avaible-mining-quantity': 'Available mining quantity',
  'genome-mining.mining.brains-mined': 'Brains Mined',
  'genome-mining.mining.ascended-mined': 'Ascended Mined',
  'genome-mining.mining.my-brains': 'My Brains mined',
  'genome-mining.mining.mining-history': 'Mining History',
  'genome-mining.mining.mining-selection': 'Mining Selection',
  'genome-mining.mining.insufficient-energy-error':
    'You do not have enough available ASTO-Energy to mine any Gen II Brains this Cycle',
  'genome-mining.mining.mining-amount': 'Mining Amount',
  'genome-mining.mining.mine-location': 'Mine Location',
  'genome-mining.mining.initiating-mining': 'Initiating mining ...',
  'genome-mining.mining.sweep-radius-helper':
    'Sweeps enable you to multiply the amount of Brains you mine from a single tile, up to 30x.\nRadius is an expansion from the selected tile. These two mechanics can be combined. \n\nThe amount of Gen II Brains mined in a single transaction cannot exceed ',
  'genome-mining.mining.confirm.title': 'Confirm Mining Selection',
  'genome-mining.mining.total-brains-selected': 'Total Brains Selected',
  'genome-mining.mining.energy-cost': 'Energy Cost',
  'genome-mining.mining.mining-time': 'Mining Time',
  'genome-mining.mining.hours-per-brain': '1 hour per brain',
  'genome-mining.mining.confirm.description':
    'Order your Seeker to begin mining for Brains! Once signed and confirmed, this action cannot be cancelled.',
  'genome-mining.mining.initiate-mining': 'Initiate mining',
  'genome-mining.mining.pending-confirmation': 'Pending Confirmation',
  'genome-mining.mining.in-progress': 'Imbuing in progress',
  'genome-mining.mining.mint.successful.desc':
    'Congratulations, your ASTO-Energy has created something extraordinary. Your Gen II Brain will be available to view as soon as the imbuing process is complete',
  'genome-mining.mining.mint.successful.brains': 'Gen II Brains',
  // mining map interaction key dialog
  'genome-mining.mining-map.interaction-key-dialog.close-button': 'Close',
  'genome-mining.mining-map.interaction-key-dialog.title': 'Action Keys',
  'genome-mining.mining-map.interaction-key-dialog.content':
    "Map navigation = <0>Arrow Keys, WASD, Click and hold to drag</0><1/>Select grid to mine = <0>Single click on tile</0><1/>Show/Hide panel = <0>Press 'TAB' to toggle panels</0><1/>Show/Hide region icons = <0>Press 'Q' to toggle region icons</0>",
  // mining map rejecting modal
  'genome-mining.mining.reject.title': 'Hold mining selection',
  'genome-mining.mining.reject.nonce-reason':
    'Oops! Mining Error: Nonce too low. Please try again.',
  'genome-mining.mining.reject.description':
    'Your transaction has failed or been cancelled. \nIf Gas prices are high you can choose to hold your selection and initiate mining at a later time. \nCaution: only one mining slot may be held at any one time. Initiating a new selection will reset the held slot.',
  'genome-mining.mining.reject.clear': 'Clear Mining slot',
  'genome-mining.mining.reject.hold': 'Hold mining slot',
  // mining map story telling dialog
  'genome-mining.mining-map.story-telling.title1': 'The Tremor',
  'genome-mining.mining-map.story-telling.description1':
    "An immeasurable burst of light surged out from the core of the Tear, its shockwaves crashed through the ship. \n\nProf. Jenny Brian felt the current ripple through her flesh.  She turned toward the ASTO Shard and watched, powerless to do anything as the crystal's bonds disintegrated before her eyes.\n\nThe Shard liquified and created a new transient state of matter.",
  'genome-mining.mining-map.story-telling.title2': 'A Familiar Moon',
  'genome-mining.mining-map.story-telling.description2':
    "With her notebook irreparably damaged, the Professor sought information from the Seekers who had been exploring nearby planets. \n\nBy a stroke of luck a Seeker had located a nearby moon matching the same rock sample she possessed. With her order, the Seeker was tasked to hastily scan the moon's surface. ",
  'genome-mining.mining-map.story-telling.title3': 'Serendipity',
  'genome-mining.mining-map.story-telling.description3':
    'Maddened by her lack of progress, the Professor hurled her notebook across her lab. The notebook crashed into her workbench and knocked the small vials of Liquid ASTO-Energy she previously extracted from the Lunaris Machine. \n\nAs the contents oozed from the broken glass, something extraordinary unfolded before Prof. Brian. The ASTO-Energy began to fuse with a small rock sample she kept from a previous journey.',
  'genome-mining.mining-map.story-telling.title4': "The Moon's Surface",
  'genome-mining.mining-map.story-telling.description4':
    'In their haste to return to the ship, the Seekers had managed to return a rudimentary scan of the small moon. \n\nProfessor Jenny Brian immediately recognized the topography of the moon, however with her notebook destroyed she could not pinpoint where she had landed all those years ago.',
  'genome-mining.mining-map.story-telling.title5': 'The Expedition Begins!',
  'genome-mining.mining-map.story-telling.description5':
    "The Professor was unable to leave her research vessel. Instead, she engineered a simple mining device that could be attached to any Seeker willing to venture down to the moon's surface.\n\nA group of steadfast Seekers are now called to action, ready to harvest the rare minerals located on this moon with her haphazard device. Aptly equipped for the mining expedition, the Seekers set off.",
  'genome-mining.mining-map.story-telling.button5': 'Start the discovery',
  // mining popup
  'genome-mining.mining.popup.ascended-mined.title':
    'You have successfully mined a Gen II (Ascended)',
  // Details Popup
  'genome-mining.mining.summary-popup.view-summary': '( View summary )',
  'genome-mining.mining.summary-popup.title': 'Production Cycle Recap',
  'genome-mining.mining.summary-popup.description':
    'Below is the culmination of ASTO-Energy generated by you and the community, for use in Mining Cycle 02.',
  'genome-mining.mining.summary-popup.available-brain': 'Available Brains:',
  'genome-mining.mining.summary-popup.mining-price': 'Mining Price:',
  'genome-mining.mining.summary-popup.total-asto-energy':
    'Total Community ASTO-Energy:',
  'genome-mining.mining.summary-popup.my-asto-energy': 'My ASTO-Energy:',
  'genome-mining.mining.summary-popup.available-mining.title':
    'Available Mining',
  'genome-mining.mining.summary-popup.available-mining.description':
    'Quantity of Gen II Brains available for you to Mine, based on your available ASTO-Energy at the conclusion of Production Cycle 02.',

  // inventory
  'inventory.connect-wallet.description':
    'A wallet connection is required to view your inventory.',
  'inventory.title': 'Inventory',
  'inventory.no-brains.title': 'No Brains found',
  'inventory.no-brains.description':
    'Genesis and Gen II Brains are available on OpenSea.<br/>You can mine your own Gen II Brains by Staking ASTO in Genome Mining<br/>Learn more about ASM Brains <0>here</0>.',
  'inventory.no-brains.view-opensea': 'View OpenSea',
  'inventory.no-brains.genome-mining-button': 'Genome Mining',
  'inventory.error.title': 'An error has occurred',
  'inventory.error.description':
    'Failed to load your assets in the Inventory, please refresh your browser.',
  'inventory.error.refresh': 'Refresh Page',
  'inventory.error.notification':
    'There was an error loading your assets. Please refresh.',

  // inventory focused view
  'inventory.focused-view.go-back': 'Go Back',
  'inventory.focused-view.view-on-opensea': 'View on Opensea',
  'inventory.focused-view.properties': 'Properties',
  'inventory.focused-view.title': '{{generation}}',
  'inventory.content-not-available-yet': 'Content not available yet',

  // Region Lore Cards
  'region-lore-card.the-outlands.card-name': 'The Outlands',
  'region-lore-card.the-outlands.card-sub-name':
    'A barren land of ancient memory',
  'region-lore-card.the-outlands.card-description':
    'Few can remember the verdant plains and meandering rivers of Eyrea, or the farming communities that dotted this landscape.  Fewer recall songs sung to the stars or legends of ancient cities with mysterious crystalline structures.<br/><br/>In truth, very few remember anything before the Eye of Ruin.<br/><br/>Today, this area is called The Outlands: a place of barrier mountains that hold within their embrace plains of dust and rock and little else.<br/><br/>Those that dwell here don’t view their home as a wasteland.  To them, it is a challenge to be met and overcome; a point of pride.  A few cities have risen, as have some communities on the periphery.  Yet not all share the visions of glass and steel.<br/><br/>Instead, they lord over the wastelands and carve out new territories and adventures.  Sometimes they even discover legends of old.',

  'region-lore-card.the-eye-of-ruin.card-name': 'Eye of Ruin',
  'region-lore-card.the-eye-of-ruin.card-sub-name':
    'A powerful force from the beyond',
  'region-lore-card.the-eye-of-ruin.card-description':
    'It ripped through space and time. It hurled through the void and infected other worlds with mysteries from beyond. But to the small moon of Alleriya, it landed like a well-placed arrow.<br/><br/>The lush and well-irrigated region known as Eyrea bore the full impact of that meteorite. It carved a massive crater into the proud moon, destroyed farms and homelands, summoned mountains to rise, and smothered the region in thick blankets of dust, debris, and a memory that would never leave.<br/><br/>Some say it hailed from a place of dreams and nightmares and carried within it a powerful message from beyond.<br/><br/>Once things settled, those that remained looked down into the gaping maw of the crater. And there it sat: whole and undisturbed, a large glowing meteorite that seemingly stared back at them with an eerie life of its own.<br/><br/>That area would be forever changed, and so would all things it touched.<br/><br/>For this is the Eye of Ruin.',

  'region-lore-card.frostfell.card-name': 'Frostfell',
  'region-lore-card.frostfell.card-sub-name': 'Frozen land with a warm heart',
  'region-lore-card.frostfell.card-description':
    'One would think winds that bear icy teeth and temperatures that could freeze even the warmest soul would quench the fierce heartfires of the Frostfeller people.  None could be further from the truth.<br /> <br />Inventors of skiing and every reason ever to throw a party, the Frostfeller people are known to celebrate life with zeal and sport.  Various cold-weather spectacles are held throughout the year including timed ice maze competitions, deep dives of the never-frozen Lake Aldfrosa, and ice-climbing of Frostface, the massive cliff that overlooks Megacity to the south.<br /> <br />Truly, their love of sport isn’t even quelled by the dangers that lurk below the surface.  Teams of Frostfellers are rewarded mightily for surviving the challenges of the deepest cavern of all: Thorngoth Cavern, the home of the infamous Rime King.',

  'region-lore-card.megacity.card-name': 'Megacity',
  'region-lore-card.megacity.card-sub-name': 'A sprawling cosmopolitan city',
  'region-lore-card.megacity.card-description':
    'Housing some of Alleriya’s oldest landmarks and technological achievements, this sprawling cosmopolitan city is the most populous region on the continent. <br /> <br /> Within and between its towering landscape lies some of the most illustrious of football arenas, all as diverse as the inhabitants. <br /> <br /> As varied as the All-Stars who inhabit it, so are the challenges the residents must face. <br /> <br /> In East Haven, The Creep presents an encroaching threat from Grezen Dul. West Gate’s old city politics are in danger from growing gangland activity. And in the old Cordis District—the birthplace of All-Star football—portals to places unknown have been reported.',

  'region-lore-card.grezen-dul.card-name': 'Grezen Dul',
  'region-lore-card.grezen-dul.card-sub-name':
    'A corrupted land of dark secrets',
  'region-lore-card.grezen-dul.card-description':
    'As the Eye of Ruin streaked toward The Outlands eons ago, a shard of it landed in a bleak landscape.  Its transformative powers leeched into the land with an outpouring of unknown energy and made Grezen Dul into what it is today: a zombie-filled landscape of weird colours, choking vines, and dense forests that play home to the most wicked of what the island of Alcherin has to offer.<br /> <br />Some believe it was the land that mutated the shard and not the other way around.  Or that the shard was only strong enough to enhance that which already existed, a power from beyond the stars that made Grezen Dul its home long, long ago.<br /> <br />Whatever the case, even a mere glance in the direction of the land of oddity and shadow can make most any All-Star quake with fear.',

  'region-lore-card.hakal-narr.card-name': 'Hakal Narr',
  'region-lore-card.hakal-narr.card-sub-name':
    'A fiendish landscape of horrific beauty',
  'region-lore-card.hakal-narr.card-description':
    'The land of Hakal Narr and the ancient volcano that makes up the bulk of this infernal region has a dark history steeped in ancient beliefs and practices. <br /> <br /> At the center of the peninsula lies the ominous and picturesque Mount De’vil, an active and immense composite volcano that dominates the skyline. Frequent lava flows once caused a dramatic reshaping of the peninsula on a regular basis. This gave rise to early All-Star beliefs that the volcano was an artistic instrument responsible for the creation of the world. <br /> <br /> All-Star historians claim in the pre-brainian days a sect of All-Stars sought answers from the volcano directly. Today, few believe in such things. Others claim to have felt a deep, formidable presence. Perhaps time will tell.',

  'region-lore-card.dancing-seas.card-name': 'Dancing Seas',
  'region-lore-card.dancing-seas.card-sub-name': 'Largest region, deepest lore',
  'region-lore-card.dancing-seas.card-description':
    "The Dancing Seas is an aquatic realm of majestic reefs, crystalline caverns, and a sprawling city, so named thanks to bioluminescent algae within its many reefs.  Like much of Alleriya, there are also stories of the mysteries below.<br /> <br />The Inhabitants of the Pola Ati Atoll perform annual ceremonies to keep the ancient spirit of Lohan’no, the volcano that formed the atoll, peacefully slumbering under the water despite recent rumblings.<br /> <br />Meanwhile, the watersages in Sokoriki City believe it is a giant sea creature whose tremors shake the waters.<br /> <br />Others disagree.  The deepseers of the Kohal'po Depths claim to hear the ocean's song of warnings: it is not something of this world.<br /> <br />Whatever the case, perhaps the secrets of Alleriya's past can be found in the depths beyond one's own imagination.",

  'region-lore-card.wyldsprite.card-name': 'Wyldsprite',
  'region-lore-card.wyldsprite.card-sub-name':
    'Primordial region of raw nature',
  'region-lore-card.wyldsprite.card-description':
    'The verdant region known as Wyldsprite hides many secrets within the primeval shadows cast by the canopies of its forests. From the rolling hills of Lystvale Thicket to the sprawling river plains of The Lowlands, the region has resisted ecological change. Unlike the cosmopolitan Megacity or the ever-changing seas of sand of Melascor, Wyldsprite has remained a raw primal landscape of greenery and natural beauty. <br /> <br /> Some say the reason for this natural resistance to change is a primordial energy within the land that protects it. Others claim it is the forests themselves that act as the largest living organism. <br /> <br /> Whatever the case, there is one reminder to all who live within Wyldsprite that nature oversees all: towering above even the highest of hills is a tree whose majesty is paralleled only by its mystery, one whose roots know no bounds of depth: the ancient oak known only as Thickeroot.',

  'region-lore-card.melascor.card-name': 'Melascor',
  'region-lore-card.melascor.card-sub-name': 'A land of mystery and adventure',
  'region-lore-card.melascor.card-description':
    'They say for every grain of sand in Melascor there is a legend of magic and adventure from eons past.<br /> <br />It starts with the smell of the air: warm and full with a hint of fruit, pepper, and vanilla.<br /> <br />Meanwhile, many claim the waters of the slow-moving Lakhadis River have curative properties.  Yet none know why the deserts that surround it remain seas of sand that glisten like jewels in the sun.<br /> <br />Even the clouds appear as mysterious arms of a great spiral hailing from Thrasis Hold, the mighty capital city.  And within its walls technomancers feud with practitioners of ancient sorcery, a well-known quarrel that once left Na Morgo Island bereft of trees.Indeed, Melascor is a land of mystery, wonderment, and ancient lore.',

  'auto-refresh-text': 'Update required. Auto refresh in ',
  'auto-refresh-button': 'Refresh page now',
  'auto-refresh-countdown_one': '{{count}} sec',
  'auto-refresh-countdown_other': '{{count}} secs',

  // LBA Migration
  'lba-migration.button': 'Migrate LP Tokens',
  'lba-migration.continue-button': 'Continue Migration',
  'lba-migration.finish-button': 'Finish Migration',
  'lba-migration.link': 'Migrate',
  'lba-migration.retry-approval': 'Retry approval',
  'lba-migration.step-2.description':
    'Approve contract to begin the migration process.',
  'lba-migration.step-2.title': 'Approve Interaction',
  'lba-migration.step-2.button': 'Confirm wallet to continue',
  'lba-migration.step-1.description':
    'Withdraw LP Tokens from ASTO Auction Participation Pool. Upon withdrawal, your Æ balance generated from the pool will momentarily be 0. This will be reinstated after the completion of the LP migration into the new contract.',
  'lba-migration.step-1.title': 'Withdraw LP \nfrom Auction Pool',
  'lba-migration.step-1.button': 'Confirm wallet to continue',
  'lba-migration.step-3.description':
    'Migrate LP Tokens from the ASTO Auction Participation contract to the ASTO-USDC LP Tokens contract. This step is <strong>required</strong> to reinstate your Æ balance.',
  'lba-migration.step-3.button': 'Confirm wallet to continue',
  'lba-migration.step-3.title': 'Migrate assets \nto new contract',
  'lba-migration.step-4.description':
    'Success! Your ASTO Auction LP tokens have been migrated into the ASTO-USDC LP staking contract. You will continue to generate ASTO-Energy and your Æ balance has been successfully secured.',
  'lba-migration.step-4.button': 'Back to dashboard',
  'lba-migration.step-4.title': 'Migration Complete',
  'lba-migration.ae-to-migrate': 'Æ to Migrate',
  'lba-migration.lp-to-migrate': 'LP to Migrate',

  // ASTO Aution Ennded Warning
  'asto-aution-ended-warning.title':
    'Action required: The ASTO Auction reward period has ended',
  'asto-aution-ended-warning.description':
    'We are upgrading our ASTO-Energy contracts. Your ASTO Auction LP Tokens from the ASTO Auction Participation contract need to be migrated to the ASTO-USDC Staking pool to continue generating ASTO-Energy.',
  'asto-aution-ended-warning.learn-more-button': 'Learn More',
  'asto-aution-ended-warning.migrate-button': 'Migrate LP token',
  'asto-aution-ended-warning.learn-more-popup-title': 'ASTO Auction Migration',
  'asto-aution-ended-warning.learn-more-popup-description': `
Migrate your LP tokens from the ASTO Auction Participation contract to keep generating Æ. <br /> <br />
The ASTO Auction Participation contract will soon stop generating ASTO-Energy. To keep generating Æ, LP token holders must migrate their assets from their current ASTO Auction Participation contract to the ASTO-USDC contract.<br /> <br />
Migration will also secure your unused Æ balance against any future loss from discharging. After migration, LP tokens can be discharged at any time without any risk to your Æ balance. Migration is the only way for ASTO Auction Participants to keep their unused Æ balance for mining Gen II Brains in Genome Mining as well as for future ASTO-Energy utility.<br /> <br />
Migration is a three-step process. All three steps MUST be completed in order to secure your Æ balance and get back to generating more Æ. Discharging ASTO-Auction Participation tokens from the Lunaris machine without completing all 3 steps will result in losing any unused Æ balance.<br /> <br />
The migration will transfer your LP tokens and remaining Æ balance to the new and improved ASTO-USDC contract, which now also offers a 20% multiplier on Æ generation.
    `,

  'genome-mining.overview.multiplier-tracker-lpa-ended-tooltip':
    'The ASTO Auction LP contract will soon close and will no longer generate Æ. Migration is required to continue generating Æ, and to reclaim previously generated Æ from the ASTO Auction pool.',

  'asto-auction-lp-migration-warning.title':
    'ASTO Auction LP token Migration warning',
  'asto-auction-lp-migration-warning.description':
    'If you are migrating LP tokens from the Auction contract, do not charge machine here. You will lose all you Æ that will be migrate with the LP that require a special function. Use migration flow. Use the migration button above to start or continue.',
  'asto-auction-lp-migration-notification':
    'The ASTO Auction Participation reward period has ended. LP Token migration is required to continue generating ASTO-Energy.',

  // Closing Soon
  'asto-auction-lp-migration.closing-soon-notification':
    'ASTO AUCTION LP TOKEN MIGRATION REQUIRED',
  'asto-aution-closing-soon-warning.title':
    'Action required: Migration incomplete',
  'asto-aution-closing-soon-warning.description':
    'We are upgrading our ASTO-Energy contracts. Your LP Tokens from the ASTO Auction Participation contract need to be migrated to the ASTO-USDC staking contract to continue generating ASTO-Energy.',
  // Migration Steps Warning
  'asto-aution-migration-step-1-warning.title':
    'Action required: Migration incomplete',
  'asto-aution-migration-step-1-warning.description':
    'You have only completed step 1 of the migration process. <strong>You must</strong> complete the migration process to secure your current Æ and to generate Æ in the future.',
  'asto-aution-migration-step-2-warning.title':
    'Action required: Migration incomplete',
  'asto-aution-migration-step-2-warning.description':
    'You have only completed step 2 of the migration process. <strong>You must</strong> complete the migration process to secure your current Æ and to generate Æ in the future.',

  // Void
  'void.status-bar.title': 'Void Protocol Status:',
  'void.status-bar.warning-message': 'ANOMALY DETECTED',
  'void.status-bar.success-message': 'Voidpools Neutralized',
  'void.status-bar.zero-message': 'All regions stable',
  'void.explainer-modal.title': 'Alleriya is under attack from the unknown!',
  'void.explainer-modal.paragraph-1':
    'Scans of the Alleriyan surface have revealed Alcherin Island as the primary location of Voidpools, suggesting a coordinated attack by an unknown source. Ravaged areas are being rapidly depleted of aifarite, the mineral required for Genome Mining.',
  'void.explainer-modal.paragraph-2':
    'Emergency experiments conducted by Brain 1 have revealed that deployments of high quantities of ASTO-Energy (Æ) can neutralize a Voidpool and re-stabilze the area of effect.',
  'void.explainer-modal.paragraph-3':
    'If you are reading this and you have stores of ASTO-Energy, we implore you to assist.',
  'void.explainer-modal.step-title-1': 'Target the Voidpool',
  'void.explainer-modal.step-title-2': 'Deploy ASTO-Energy',
  'void.explainer-modal.step-title-3': 'Confirm',
  'void.explainer-modal.button.cancel': 'Close',
  'void.explainer-modal.button.confirm': 'Investigate Alleriya',
  'void-league.dashboard-card.title': 'Void Protocol',
  'void-league.dashboard-card.subtitle': `Mysterious Voidpools are threatening Alleriya! \nDeploy your ASTO-Energy to help restabilize Alcherin Island and claim ASTO-USDC LP rewards for your contributions.`,
  'void-league.dashboard-card.zero.subtitle':
    'Brain 1 is diligently monitoring the Alleriyan surface for Voidpool activity. Keep ASTO-Energy at the ready to assist the inhabitants of Alcherin Island, and claim ASTO-USDC LP rewards for your contributions.',
  'void-league.dashboard-card.description':
    'Use ASTO-Energy to close the voids and free the Dark LP stored within, claiming the tokens as a reward.',
  'void-league.dashboard-card.void-title': '#Voidpool',
  'void-league.dashboard-card.investigate': 'Investigate Disturbances',
  'void-league.dashboard-card.available-contributions':
    'Your available contributions',
  'void-league.dashboard-card.total-energy': 'Your total ASTO-Energy',
  'void-league.dashboard-card.voidpool-stability': 'Voidpool Stability',
  'void-league.dashboard-card.voidpool-neutralized': 'Voidpools Neutralized',
  'void-league.dashboard-card.voidpool-detected': 'ANOMALY DETECTED',
  'void-league.dashboard-card.void-protocol-status': 'Void Protocol Status',
  'void-league.table.void-size-remains': 'Void size remaining',
  'void-league.table.claims-remaining': 'Claims remaining',
  'void-league.table.lp-available-pool': 'LP available in pool',
  'void-league.table.ae-per-transaction': 'Æ per transaction',
  'void.map-portal.void-size': 'VOID SIZE: {{amount}} Æ',
  'void.map-portal.claimable': 'CLAIMABLE: {{amount}} LP',
  // void options panel
  'void-options-panel.title': 'Voidpool Located',
  'void-options-panel.description':
    'Voidpools are terrorizing the area.  Use your ASTO-Energy to diffuse this Voidpool. <br /><br /> Each neutralizing reaction between ASTO-Energy and these anomalies will release LP tokens as a byproduct.',
  'void-options-panel.void-title': 'Voidpool #01',
  'void-options-panel.void-size': 'ASTO-Energy fallout',
  'void-options-panel.total-claim-remaining': 'Rewards remaining',
  'void-options-panel.claim-remaining': 'Deployments remaining',
  'void-options-panel.error-title': 'Insufficient ASTO-Energy',
  'void-options-panel.error':
    'You do not have enough available ASTO-Energy to safely contribute to a Voidpool',
  'void-options-panel.ae-cost': 'Æ Cost',
  'void-options-panel.lp-received': 'LP received',
  'void-options-panel.fill-void': 'Deploy ASTO-Energy',

  // void info panel
  'void-info-panel.available-mining-quantity': 'Your Available Deployments',
  'void-info-panel.void-history': 'Void Protocol Activity',
  // void transaction dialog
  'void-transaction-dialog.lp-claimed': 'LP claimed',
  'void-transaction-dialog.description':
    'You have successfully received LP tokens as a reward for your courage.',
}

export { enUS }
