import create from 'zustand'

interface useNavigationBarState {
  isNavigationBarOpen: boolean
  openNavigationBar: () => void
  closeNavigationBar: () => void
}

const useNavigationBar = create<useNavigationBarState>(set => ({
  isNavigationBarOpen: false,
  openNavigationBar: () => set({ isNavigationBarOpen: true }),
  closeNavigationBar: () => set({ isNavigationBarOpen: false }),
}))

export default useNavigationBar
