import { IconButton, IconButtonProps } from '@mui/material'
import { css, styled } from '@mui/material/styles'
import { transientPropCheck } from 'component-library/src/styles/utils'

import { ReactComponent as CopyIconSvg } from 'component-library/src/assets/icons/copy.svg'
import { ReactComponent as CheckIconSvg } from 'component-library/src/assets/icons/check-large.svg'

const IconContainer = styled(IconButton)`
  position: relative;
  height: 20px;
  width: 20px;
`

const copyIconCss = css`
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  stroke-dasharray: 50;
  transition: all 300ms ease-in-out;
`

const CopyIcon = styled(
  CopyIconSvg,
  transientPropCheck
)<{ $copied: boolean }>(
  ({ $copied }) => css`
    ${copyIconCss}
    stroke-dashoffset: ${$copied ? -50 : 0};
  `
)

const CheckIcon = styled(
  CheckIconSvg,
  transientPropCheck
)<{ $copied: boolean }>(
  ({ $copied }) => css`
    ${copyIconCss}
    stroke-dashoffset: ${$copied ? 0 : -50};
  `
)

interface CopyProps extends IconButtonProps {
  copied: boolean
}

const Copy: React.FC<CopyProps> = ({ copied, ...props }) => {
  return (
    <IconContainer {...props}>
      <CheckIcon $copied={copied} />
      <CopyIcon $copied={copied} />
    </IconContainer>
  )
}

export default Copy
