import { Chip as MuiChip, ChipProps as MuiChipProps } from '@mui/material'

import { ReactComponent as CloseIcon } from '../../assets/icons/close-filled.svg'

const Chip = (props: MuiChipProps) => {
  return (
    <MuiChip
      deleteIcon={
        // attributes copied over from mui default icon
        <CloseIcon data-testid="CancelIcon" focusable={false} aria-hidden />
      }
      {...props}
    />
  )
}

export default Chip
export type { MuiChipProps as ChipProps }
