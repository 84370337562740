import { css, styled, Typography } from '@mui/material'
import NoBrains from 'src/assets/images/inventory/no-brains.webp'
import Button from 'component-library/src/components/button/Button'
import Grid from 'component-library/src/components/grid/Grid'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'
import { Paths } from 'src/utils/routes'
import { reportEvent } from 'src/utils/ga'

const Container = styled('div')(
  ({ theme }) => css`
    display: flex;
    position: relative;
    border-radius: 8px;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 8px;
    width: 100%;
    min-height: 622px;
    border: 1px solid ${theme.palette.grey['700']};
    background-color: ${theme.palette.grey['900']};
    gap: 30px;
  `,
)

const StyledGrid = styled(Grid)`
  width: 100%;
  max-width: 420px;
`

const StyledLink = styled('a')(
  ({ theme }) => css`
    text-decoration: underline;
    text-align: center;
    cursor: pointer;

    :hover {
      color: ${theme.palette.primary.main};
    }
  `,
)

export const NoBrainFoundBox = () => {
  const navigate = useNavigate()

  const gotoGenomeMining = useCallback(() => {
    reportEvent('button_click', {
      button_name: 'Genome_Mining_InvNoBrains',
      page_title: 'Inventory',
    })
    navigate(`${Paths.GenomeMining}`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const gotoOpensea = useCallback(() => {
    reportEvent('button_click', {
      button_name: 'inv_NoBrains_View_OpenSea',
      page_title: 'Inventory',
    })
    window.open(
      'https://opensea.io/Altered_State_Machine?tab=created',
      'noopener',
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const gotoLearnMore = useCallback(() => {
    reportEvent('button_click', {
      button_name: 'inv_NoBrains_Learn_more',
      page_title: 'Inventory',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container data-testid="no-brain-found-box">
      <Typography
        variant="h3"
        color={'white'}
        data-cy={`no-brains`}
        sx={{
          fontFamily: 'fontFamilySecondary',
        }}
      >
        <Trans i18nKey="inventory.no-brains.title">No brains found</Trans>
      </Typography>
      <img src={NoBrains} alt="no brains" />
      <Typography mb={10}>
        <Trans
          i18nKey="inventory.no-brains.description"
          components={[
            <StyledLink
              href="https://medium.com/@alteredstatemachine"
              rel="noopener noreferrer"
              target="_blank"
              onClick={gotoLearnMore}
              key={0}
            />,
          ]}
        >
          Genesis Brains are available on OpenSea, or mine a Gen II brain by
          staking ASTO in Genome mining.
          <br /> <br /> Learn more about ASM Brains{' '}
          <StyledLink
            href="https://medium.com/@alteredstatemachine"
            rel="noopener noreferrer"
            target="_blank"
            onClick={gotoLearnMore}
          >
            here
          </StyledLink>
          .
        </Trans>
      </Typography>
      <Grid
        container
        justifyContent={'center'}
        alignItems={'center'}
        alignContent={'center'}
        gap={1}
      >
        <StyledGrid item>
          <Button variant={'outlined'} onClick={gotoOpensea} fullWidth>
            <Trans i18nKey="inventory.no-brains.view-opensea">
              View OpenSea
            </Trans>
          </Button>
        </StyledGrid>
        <StyledGrid item>
          <Button onClick={gotoGenomeMining} fullWidth>
            <Trans i18nKey="inventory.no-brains.genome-mining-button">
              Genome Mining
            </Trans>
          </Button>
        </StyledGrid>
      </Grid>
    </Container>
  )
}
