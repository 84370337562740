import { styled, css } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import { Link } from 'react-router-dom'
import { Paths } from 'src/utils/routes'

import Account from 'src/components/status/Account'
import { useWeb3React } from 'src/hooks/useWeb3React'
import { useBreakpoints } from 'src/hooks/useBreakpoints'
import { ASTOBalance } from 'component-library/src/components/status/ASTOBalance'
import { AEBalance } from 'component-library/src/components/status/AEBalance'

import { ReactComponent as RewardsSVG } from 'component-library/src/assets/icons/rewards.svg'
import { BreadcrumbsBar } from './BreadcrumbsBar'
import useAvailableASTOEnergyBalance from 'src/hooks/useAvailableASTOEnergyBalance'
import { useWallet } from 'src/hooks/useWallet'

const StatusBarContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: ${theme.spacing(1, 3)};
    background-color: transparent;
  `,
)

const StatusContainer = styled(StatusBarContainer)`
  justify-content: flex-end;
  padding: 0px;
`

const StyledAccount = styled(Account)(
  ({ theme }) => css`
    margin-left: ${theme.spacing(2)};
  `,
)

const BalanceContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;

    > * {
      padding-left: 20px;
      padding-right: 20px;
      flex: 1 0 0;
      border-right: 1px solid ${theme.palette.grey['600']};

      :first-of-type {
        padding-left: 0px;
      }

      :last-of-type {
        border-right: unset;
        padding-right: 0px;
      }
    }
  `,
)

export const StatusBar = () => {
  const { active } = useWeb3React()
  const { isSmallScreen } = useBreakpoints()
  const aeBalanceQuery = useAvailableASTOEnergyBalance()
  const { astoBalance } = useWallet()

  return (
    <StatusBarContainer>
      <BreadcrumbsBar />
      <StatusContainer>
        <IconButton
          sx={{ padding: 0 }}
          disableRipple
          component={Link}
          to={Paths.Rewards}
        >
          <RewardsSVG />
        </IconButton>
        {active && (
          <BalanceContainer>
            <AEBalance balance={parseInt(aeBalanceQuery.data || '0')} />
            <ASTOBalance balance={astoBalance} />
          </BalanceContainer>
        )}
        {!isSmallScreen && <StyledAccount />}
      </StatusContainer>
    </StatusBarContainer>
  )
}

export default StatusBar
