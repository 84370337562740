import { styled, css } from '@mui/material/styles'
import {
  Alert as MuiAlert,
  AlertProps as MuiAlertProps,
  ButtonBase,
  IconButton,
  Snackbar as MuiSnackbar,
  SnackbarProps as MuiSnackbarProps,
} from '@mui/material'
import { Close as MuiClose } from '@mui/icons-material'

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg'
import { ReactComponent as WarningIcon } from '../../assets/icons/warning.svg'
import { ReactComponent as SuccessIcon } from '../../assets/icons/success.svg'
import { ReactComponent as ErrorIcon } from '../../assets/icons/error.svg'

const ActionButton = styled(ButtonBase)(
  ({ theme }) => css`
    color: inherit;
    font-family: ${theme.typography.fontFamily};
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    margin-left: 24px;
  `
)

const CloseIcon = styled(MuiClose)`
  width: 20px;
  height: 20px;
`

const IconWrapper = styled(IconButton)`
  margin-left: 0.5rem;
`

const ActionContainer = styled('div')`
  display: flex;
  align-items: center;
`

interface AlertBarProps extends Omit<MuiAlertProps, 'action' | 'onClose'> {
  actionMessage?: string
  actionFn?: () => void
  onClose?: () => void
  open: boolean
  snackbarProps?: Partial<MuiSnackbarProps>
}

const Alert = ({
  actionMessage,
  actionFn,
  onClose,
  open,
  snackbarProps,
  ...props
}: AlertBarProps) => {
  const closeHandler = () => {
    onClose && onClose()
  }
  return (
    <MuiSnackbar open={open} onClose={closeHandler} {...snackbarProps}>
      <MuiAlert
        action={
          <ActionContainer>
            {actionMessage && actionFn && (
              <ActionButton onClick={actionFn}>{actionMessage}</ActionButton>
            )}
            {onClose && (
              <IconWrapper
                color="inherit"
                size="small"
                onClick={closeHandler}
                aria-label="Close Alert"
              >
                <CloseIcon />
              </IconWrapper>
            )}
          </ActionContainer>
        }
        iconMapping={{
          info: <InfoIcon />,
          success: <SuccessIcon />,
          warning: <WarningIcon />,
          error: <ErrorIcon />,
        }}
        {...props}
      />
    </MuiSnackbar>
  )
}
export default Alert

export type { AlertBarProps }
