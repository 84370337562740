import config from 'src/utils/config'

const openseaBaseURL = config.REACT_APP_OPENSEA_URL_BASE
const genIIBrainCollectionName = config.REACT_APP_OPENSEA_GEN_II_COLLECTION_NAME

export const makeOpenseaAssetURL = (address: string, tokenId: number) => {
  return `${openseaBaseURL}/assets/${address}/${tokenId}`
}

export const makeOpenseaGenIIBrainsURL = (walletAddress: string) => {
  return `${openseaBaseURL}/${walletAddress}/${genIIBrainCollectionName}/?search%5BresultModel%5D=ASSETS&search%5BsortBy%5D=LAST_TRANSFER_DATE`
}
