import { formatBalance } from '../../utils/number'

import { styled, Typography } from '@mui/material'
import IconFactory from 'component-library/src/components/icons/IconFactory'

export type AEBalanceProps = {
  balance?: number
}

const StyledIcon = styled(IconFactory)`
  background-color: black;
  border-radius: 99px;
  padding: 5px;
`

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const AEBalance = ({ balance = 0 }: AEBalanceProps) => (
  <Container>
    <StyledIcon name="energy" width={34} />
    <Typography
      variant="body2"
      color="white"
      ml={1}
      data-cy="ae-bal"
      data-percy-static-content="1,000,001 Æ"
      noWrap
    >
      {formatBalance(balance)} Æ
    </Typography>
  </Container>
)
