import { GetNftInfoResponse } from '../generated'

export type GetNftInfoQuery = {
  __typename: 'Query'
  getNftInfo: GetNftInfoResponse
}

export const GetNftInfoDocument = `
query GetNftInfo($hk: String!) {
  getNftInfo(hk: $hk) {
  ... on GetNftInfoResponseSuccess {
    nft {
      tokenId
      contractName
      blockNumber
      metadata {
        image_transparent
        brain_genus
        animation_url
        attributes {
          ... on NftAllStarsCharacterMetadataAttributeTrait {
            character_trait_type
            character_value
            __typename
          }
          ... on NftGenesisBrainMetadataAttributeTrait {
            genesis_trait_type
            genesis_value
            __typename
          }
          ... on NftGen2BrainMetadataAttributeTrait {
            gen2_trait_type
            gen2_value
            __typename
          }
        }
        glb_url
        image
      }
      contractAddress
    }
    __typename
  }
  ... on GetNftInfoResponseFailure {
    reason {
      __typename
    }
  }
}
}`
