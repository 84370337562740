import { Box, Typography } from '@mui/material'
import Select from 'component-library/src/components/select/Select'
import SelectItem from 'component-library/src/components/select/SelectItem'
import useInventoryStore, { filterOptions } from '../hooks/useInventoryStore'

export const FilterByTypeSelect = () => {
  const { filterValue, genusFilters, brainsCount, changeFilter } =
    useInventoryStore(state => ({
      filterValue: state.filterValue,
      brainsCount: state.brainsCount,
      genusFilters: state.genusFilters,
      changeFilter: state.changeFilter,
    }))

  return (
    <Select
      sx={{ textAlign: 'left', width: '303px' }}
      defaultValue={'1'}
      value={filterValue}
      renderValue={value => (
        <Box
          sx={{ display: 'flex', gap: 1 }}
          alignItems="center"
          alignContent="center"
        >
          <Typography color="grey.200" variant="body2">
            Filter by type:
          </Typography>{' '}
          {filterOptions.get(value as string)}
        </Box>
      )}
      onChange={event => {
        changeFilter(event.target.value as string)
      }}
    >
      {Array.from(filterOptions).map(([key, value]) => {
        let disabled = false
        switch (key) {
          case '2': {
            disabled = brainsCount.genesisCount === 0
            break
          }
          case '3': {
            disabled = !genusFilters.enableUnearthed
            break
          }
          case '4': {
            disabled = !genusFilters.enableAscended
            break
          }
        }
        return (
          <SelectItem
            key={key}
            value={key}
            data-cy={`inventory-select-${key}`}
            disabled={disabled}
          >
            {value}
          </SelectItem>
        )
      })}
    </Select>
  )
}
