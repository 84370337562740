import { useQuery } from 'react-query'

import useASMBrainContract from 'src/hooks/contracts/useASMBrainContract'
import useASMBrainGenIIContract from 'src/hooks/contracts/useASMBrainGenIIContract'
import { QueryGetNftInfoArgs } from 'src/graphql/generated'
import { gqlFetcher } from 'src/utils/gqlFetcher'
import {
  GetNftInfoQuery,
  GetNftInfoDocument,
} from 'src/graphql/query/getNftInfo'
import { BrainContractName } from 'src/utils/inventory'

interface Trait {
  traitType: string
  value: string
}

export type Brain = {
  animationUrl: string
  attributes: Trait[]
  contractAddress: string
}

const makeTrait = (trait: any): Trait => {
  switch (trait?.__typename) {
    case 'NftAllStarsCharacterMetadataAttributeTrait':
      return {
        traitType: trait.character_trait_type,
        value: trait.character_value,
      }
    case 'NftGen2BrainMetadataAttributeTrait':
      return {
        traitType: trait.gen2_trait_type,
        value: trait.gen2_value,
      }
    case 'NftGenesisBrainMetadataAttributeTrait':
      return {
        traitType: trait.genesis_trait_type,
        value: trait.genesis_value,
      }
    default:
      return {
        traitType: '',
        value: '',
      }
  }
}

const makeHk = (contractAddress: string, tokenId: number): string => {
  return `${contractAddress?.toLowerCase()}:${String(tokenId).padStart(8, '0')}`
}

export const useASMBrainMetadata = (
  tokenId: number,
  brainType: BrainContractName,
) => {
  const genesisBrainContract = useASMBrainContract()
  const genIIBrainContract = useASMBrainGenIIContract()

  const brainMetadataQuery = useQuery<Brain>(
    ['brain', 'info', tokenId, brainType],
    async () => {
      let contractAddress = ''

      if (brainType === BrainContractName.ASMBrain) {
        contractAddress = genesisBrainContract.address
      } else if (brainType === BrainContractName.ASMBrainGenII) {
        contractAddress = genIIBrainContract.address
      }

      const res = await gqlFetcher<GetNftInfoQuery, QueryGetNftInfoArgs>(
        GetNftInfoDocument,
        {
          hk: makeHk(contractAddress, tokenId),
        },
      )

      if (
        res.getNftInfo.__typename === 'GetNftInfoResponseSuccess' &&
        res.getNftInfo.nft
      ) {
        const { nft } = res.getNftInfo
        const attributes = nft?.metadata?.attributes || []

        return {
          animationUrl: nft?.metadata?.animation_url?.toString() || '',
          attributes: attributes.map(makeTrait),
          contractAddress: contractAddress,
        }
      }

      if (res.getNftInfo.__typename === 'GetNftInfoResponseFailure') {
        throw new Error(res.getNftInfo.__typename)
      }

      return {
        animationUrl: '',
        attributes: [],
        contractAddress: '',
      }
    },
    { retry: false },
  )

  return { brainMetadataQuery }
}

export default useASMBrainMetadata
