import { css, styled, Typography } from '@mui/material'
import transientPropCheck from 'src/utils/transientPropCheck'

const Container = styled('div')(
  ({ theme }) => css`
    display: flex;
    position: relative;
    border-radius: 8px;
    align-content: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 8px;
    width: 286px;
    height: 80px;
    border: 1px solid ${theme.palette.grey['700']};

    ${theme.breakpoints.up('lg')} {
      min-height: 80px;
    }
  `,
)

const BoxItemContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex: 1 0;
    border-right: 1px solid ${theme.palette.grey['500']};
    padding: 0 8px;
    &:last-child {
      border: none;
    }
  `,
)

const BoxItemTitle = styled(Typography)(
  ({ theme }) => css`
    color: ${theme.palette.grey['500']};
    font-size: 12px;
  `,
)

interface BoxItemNumberProps {
  $roseBackground?: boolean
}

const BoxItemNumber = styled(
  Typography,
  transientPropCheck,
)<BoxItemNumberProps>(
  ({ $roseBackground = false, theme }) => css`
    color: white;
    font-size: 24px;
    font-weight: 700;
    ${$roseBackground &&
    `
    background-image: ${theme.palette.gradients.rosePurple};
    background-clip: text;
    text-fill-color: transparent;
  `}
  `,
)

interface BoxItemProps {
  title: string
  count: number
  roseBackground?: boolean
}

const BoxItem = ({ title, count, roseBackground = false }: BoxItemProps) => {
  return (
    <BoxItemContainer>
      <BoxItemNumber
        data-cy={`total-brains-${title.toLocaleLowerCase().replace(' ', '-')}`}
        $roseBackground={roseBackground}
      >
        {count}
      </BoxItemNumber>
      <BoxItemTitle>{title}</BoxItemTitle>
    </BoxItemContainer>
  )
}

interface BrainTotalBoxProps {
  genesisCount: number
  genIICount: number
}

export const BrainTotalBox = ({
  genesisCount = 0,
  genIICount = 0,
}: BrainTotalBoxProps) => {
  return (
    <Container>
      <BoxItem
        title={'Total Brains'}
        count={genesisCount + genIICount}
        roseBackground
      />
      <BoxItem title={'Genesis'} count={genesisCount} />
      <BoxItem title={'Gen II'} count={genIICount} />
    </Container>
  )
}
