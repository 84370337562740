import { styled } from '@mui/material/styles'

import { ReactComponent as InfoIcon } from '../../assets/icons/info.svg'
import Tooltip, { TooltipProps } from '../tooltip/Tooltip'

interface InfoIconProps extends Omit<TooltipProps, 'children'> {
  color?: string
}

const Span = styled('span')`
  white-space: pre-line;
`

const TooltipInfoIcon = ({ color = 'white', ...props }: InfoIconProps) => {
  return (
    <Tooltip {...props} title={<Span>{props.title}</Span>}>
      <InfoIcon color={color} />
    </Tooltip>
  )
}

export default TooltipInfoIcon
