import { useTranslation } from 'react-i18next'

import { Paths } from 'src/utils/routes'
import { MainNavItem } from '../MainNavItem'

import { ReactComponent as InventorySvg } from 'component-library/src/assets/icons/puzzle.svg'

const InventoryNavigation = () => {
  const { t } = useTranslation()

  return (
    <MainNavItem
      data-cy="nav-inventory"
      to={Paths.Inventory}
      text={t('navigation.inventory')}
      icon={InventorySvg}
      exact={false}
    />
  )
}

export default InventoryNavigation
