import { css, styled, Typography } from '@mui/material'
import Skeleton from 'component-library/src/components/skeleton/Skeleton'
import { Trans } from 'react-i18next'
import { useQuery } from 'react-query'
import BrainBeTuringVideo from 'src/assets/videos/brain-be-turning.mp4'

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const StyledCenteredVideo = styled('video')`
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const VideoWrapper = styled('div')(
  ({ theme }) => css`
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    border: 10px solid #000;
    ${theme.breakpoints.down('sm')} {
      width: 60px;
      height: 60px;
      border: 5px solid #000;
    }
  `,
)
const BackgroundVideoWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  filter: blur(4px);
`

const FrontVideoWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  flex-direction: column;
`

const VideoMask = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  opacity: 0.4;
`
const StyledText = styled(Typography)(
  () => css`
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    text-shadow: rgb(0 0 0 / 25%) 0px 4px 4px;
  `,
)

export const ContentNotAvailablePlaceholder = () => {
  // preload the video before it is played
  const videoQuery = useQuery(['video', BrainBeTuringVideo], async () => {
    const res = await fetch(BrainBeTuringVideo)
    const blob = await res.blob()
    return URL.createObjectURL(blob)
  })
  const { data: videoUrl, isLoading } = videoQuery

  return (
    <Container data-testid="content-not-available-placeholder">
      {!isLoading ? (
        <>
          <BackgroundVideoWrapper>
            <StyledCenteredVideo playsInline autoPlay muted loop preload="auto">
              <source src={videoUrl} type="video/mp4" />
            </StyledCenteredVideo>
            <VideoMask />
          </BackgroundVideoWrapper>
          <FrontVideoWrapper>
            <VideoWrapper>
              <StyledCenteredVideo
                playsInline
                autoPlay
                muted
                loop
                preload="auto"
              >
                <source src={videoUrl} type="video/mp4" />
              </StyledCenteredVideo>
            </VideoWrapper>
            <StyledText color="white" variant="body2" mt={{ xs: 0, sm: 2 }}>
              <Trans key={'inventory.content-not-available-yet'}>
                Content not available yet
              </Trans>
            </StyledText>
          </FrontVideoWrapper>
        </>
      ) : (
        <Skeleton
          variant="circular"
          sx={{
            bgcolor: 'grey.900',
            borderRadius: {
              xs: '0',
              sm: '50%',
            },
            width: {
              xs: '100%',
              sm: '140px',
            },
            height: {
              xs: '100%',
              sm: '140px',
            },
          }}
        />
      )}
    </Container>
  )
}
