import { css, styled, Select as MuiSelect, SelectProps } from '@mui/material'
import IconFactory from '../icons/IconFactory'

const StyledSelect: React.FC<any> = styled(MuiSelect)(
  ({ theme }) => css`
    border-radius: 8px;
    color: ${theme.palette.text.secondary};
    .MuiSelect-select {
      padding: 8px 12px;
    }
    .MuiSelect-icon {
      top: unset;
      svg {
        fill: ${theme.palette.grey['300']};
      }
    }
  `
)

const Select: React.FC<SelectProps> = ({ children, ...props }) => {
  return (
    <StyledSelect
      fullWidth
      variant="filled"
      disableUnderline
      IconComponent={(props: any) => (
        <IconFactory name="chevron-down" width={24} {...props} />
      )}
      {...props}
    >
      {children}
    </StyledSelect>
  )
}

export default Select
