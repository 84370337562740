import { JsonRpcProvider, JsonRpcSigner } from '@ethersproject/providers'
import { useWeb3React } from '@web3-react/core'
import { useMemo } from 'react'
import { useSimpleJsonRpcProvider } from './useRPCUrl'

export const useWeb3LibraryProvider = (): JsonRpcSigner | JsonRpcProvider => {
  const { library } = useWeb3React()
  const jsonRPCProvider = useSimpleJsonRpcProvider()

  const signer = useMemo(() => library?.getSigner(), [library])

  return signer ?? jsonRPCProvider
}
