import {
  TablePagination as MuiTablePagination,
  Typography,
} from '@mui/material'

interface TablePaginationProps {
  count: number
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void
  page: number
  disabled: boolean
}

export const TablePagination = ({
  count,
  page,
  onPageChange,
  disabled,
}: TablePaginationProps) => {
  return (
    <MuiTablePagination
      component="div"
      SelectProps={{
        disabled,
      }}
      count={count}
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={12}
      rowsPerPageOptions={[12]}
      labelDisplayedRows={({ from, to, count }) => (
        <>
          <Typography color="secondary.main" component="span">
            {from} -{to}
          </Typography>
          <Typography
            color={'white'}
            component="span"
            data-cy={`pagination-of-count`}
          >
            {' of '}
            {count}
          </Typography>
        </>
      )}
    />
  )
}

export default TablePagination
export type { TablePaginationProps }
