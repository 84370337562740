import {
  Breadcrumbs as MuiBreadcrumbs,
  BreadcrumbsProps as MuiBreadcrumbsProps,
  IconButton,
} from '@mui/material'
import { styled, css } from '@mui/material/styles'

import { ReactComponent as ChevronLeft } from '../../assets/icons/chevron-left.svg'

const Container = styled('div')`
  display: flex;
  align-items: center;
`

const StyledIconButton = styled(IconButton)(
  ({ theme }) => css`
    width: 28px;
    height: 28px;
    color: ${theme.palette.text.secondary};
    background-color: ${theme.palette.grey['700']};
    margin-right: ${theme.spacing(2.5)};
  `
)

interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  onGoBack?: () => void
}

const Breadcrumbs = ({ onGoBack, ...props }: BreadcrumbsProps) => {
  return (
    <Container>
      {onGoBack && (
        <StyledIconButton onClick={onGoBack}>
          <ChevronLeft />
        </StyledIconButton>
      )}
      <MuiBreadcrumbs maxItems={3} {...props} />
    </Container>
  )
}

export default Breadcrumbs
export { BreadcrumbsProps }
