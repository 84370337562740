import { useTranslation } from 'react-i18next'
import { MainNavItem } from '../MainNavItem'
import { ReactComponent as GenomeMining } from 'component-library/src/assets/icons/genome-mining.svg'
import { Paths } from 'src/utils/routes'

const LunarisMachineNavigation = () => {
  const { t } = useTranslation()
  return (
    <MainNavItem
      data-cy="nav-genome-mining"
      to={Paths.LunarisMachine}
      text={t('navigation.lunaris-machine')}
      icon={GenomeMining}
      exact={false}
    />
  )
}

export default LunarisMachineNavigation
