import { useTranslation } from 'react-i18next'
import { styled, Typography } from '@mui/material'
import Tooltip from 'component-library/src/components/icons/TooltipInfoIcon'
import { POPUP_LEAVE_TOUCH_DELAY } from 'src/utils/constants'
import professor from 'src/assets/images/professor-jenny-brain.png'

const ProjectionTooltipContent = styled('div')`
  display: flex;
  flex-direction: row;
  padding: 4px 0px;
  gap: 8px;
  max-width: 190px;
  > img {
    margin: 0px;
    height: 40px;
    width: 40px;
  }
`

const StyledTooltip = styled(Tooltip)`
  width: 16px;
  height: 16px;
  margin-bottom: -2px;
  margin-left: 8px;
  flex-shrink: 0;
`

const StyledTypography = styled(Typography)`
  font-weight: 400px;
`

const ProjectionTooltip: React.FC = ({ children }) => {
  const { t } = useTranslation()
  return (
    <StyledTooltip
      placement="right"
      leaveTouchDelay={POPUP_LEAVE_TOUCH_DELAY}
      title={
        <ProjectionTooltipContent>
          <img src={professor} alt={t('lunaris-machine.jenny-brain')} />
          <div>
            <StyledTypography variant="captionSmall">
              {children}
            </StyledTypography>
          </div>
        </ProjectionTooltipContent>
      }
    />
  )
}

export default ProjectionTooltip
