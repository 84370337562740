import { useTheme, useMediaQuery } from '@mui/material'

export const useBreakpoints = (breakpoint: number = 0) => {
  const theme = useTheme()
  const isBelowSmall = useMediaQuery(theme.breakpoints.down('sm'))
  const isBelowMedium = useMediaQuery(theme.breakpoints.down('md'))

  const customBreakpoint = useMediaQuery(theme.breakpoints.down(breakpoint))

  const isSmallScreen = isBelowSmall
  const isMediumScreen = isBelowMedium && !isBelowSmall
  const isLargeScreen = !isBelowMedium && !isBelowSmall

  return {
    theme,
    useMediaQuery,
    customBreakpoint: breakpoint ? customBreakpoint : false,
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
  }
}
