import asmLogo from 'component-library/src/assets/icons/asm-logo.png'

interface ASMLogoProps {
  width?: string | number
  height?: string | number
  style?: React.CSSProperties
}

const ASMLogo: React.FC<ASMLogoProps> = ({
  width = 'auto',
  height = 'auto',
  style,
  ...props
}) => {
  return (
    <img
      src={asmLogo}
      alt="ASM Logo"
      style={{ width, height, ...style }}
      {...props}
    />
  )
}

export default ASMLogo
