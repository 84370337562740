import { useMemo, useEffect, useCallback } from 'react'
import { Box, css, styled } from '@mui/material'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FilterByTypeSelect } from './FilterByTypeSelect'
import { SortingSelect } from './SortingSelect'
import Grid from 'component-library/src/components/grid/Grid'
import IconFactory from 'component-library/src/components/icons/IconFactory'
import Input from 'component-library/src/components/input/Input'
import TablePagination from 'component-library/src/components/tablePagination/TablePagination'
import { AssetList } from './AssetList'
import { AssetListSkeleton } from './AssetListSkeleton'
import { makeOpenseaAssetURL } from 'src/utils/openseaURL'
import { reportEvent } from 'src/utils/ga'
import { useASMBrainsList } from '../hooks/useASMBrainsList'
import { ErrorLoadingNFTs } from './ErrorLoadingNFTs'
import { NoBrainFoundBox } from '../NoBrainFoundBox'
import { useTranslation } from 'react-i18next'
import { useAlertbar } from 'src/hooks/store/useAlertbar'
import useInventoryStore from '../hooks/useInventoryStore'
import useASMBrainContract from 'src/hooks/contracts/useASMBrainContract'
import useASMBrainGenIIContract from 'src/hooks/contracts/useASMBrainGenIIContract'
import { BrainContractName } from 'src/utils/inventory'

const Container = styled('div')(
  ({ theme }) => css`
    display: flex;
    position: relative;
    border-radius: 8px;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    padding: 8px;
    width: 100%;
    border: 1px solid ${theme.palette.grey['700']};
    background-color: ${theme.palette.grey['900']};
    gap: 30px;
  `,
)

const PaginationContainer = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    height: 40px;
    background-color: ${theme.palette.grey['900']};
  `,
)

export const AssetsPanel = () => {
  const {
    brainsQuery,
    totalBrainsCount,
    currentPageBrains,
    totalPageCount,
    currentPage,
  } = useASMBrainsList()
  const genesisBrainContract = useASMBrainContract()
  const genIIBrainContract = useASMBrainGenIIContract()
  const { changePage, pageLastKey } = useInventoryStore(state => ({
    pageLastKey: state.pageLastKey,
    changePage: state.changePage,
  }))

  const { isError, isLoading } = brainsQuery
  const { t } = useTranslation()
  const { openAlertbar } = useAlertbar()

  useEffect(() => {
    if (isError) {
      openAlertbar({
        severity: 'warning',
        message: t('inventory.error.notification'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError])

  const handleChangePage = useCallback(
    (_: any, newPage: number) => {
      reportEvent('pagination', {
        button_name: 'Pagination_Inv',
        page_title: 'Inventory',
        page_number: (newPage + 1).toString(),
      })
      changePage(newPage)
    },
    [changePage],
  )

  // track page view when navigating between pages
  useEffect(() => {
    reportEvent('page_view', {
      page_title: 'Inventory | ASM',
    })
  }, [])

  const assets = useMemo(() => {
    const assets = currentPageBrains?.map(nft => {
      let nftAddress = ''
      if (nft.contractName === BrainContractName.ASMBrain) {
        nftAddress = genesisBrainContract.address
      } else if (nft.contractName === BrainContractName.ASMBrainGenII) {
        nftAddress = genIIBrainContract.address
      }
      return {
        id: nft?.tokenId?.toString(),
        blockNumber: nft?.blockNumber?.toString(),
        name: nft?.contractName,
        imageURL: nft?.metadata?.image_transparent?.toString(),
        externalURL: makeOpenseaAssetURL(nftAddress, nft.tokenId),
      }
    })
    return assets ?? []
  }, [
    currentPageBrains,
    genIIBrainContract.address,
    genesisBrainContract.address,
  ])

  const { inventorySearchBarTemp041022: enableSearchBar } = useFlags()

  let content = null
  if (isLoading && !pageLastKey) {
    // Loading
    content = <AssetListSkeleton />
  } else if (isError) {
    // Error
    content = <ErrorLoadingNFTs onRefreshClick={brainsQuery.refetch} />
  } else {
    if (totalBrainsCount === 0) {
      // No Brains
      return <NoBrainFoundBox />
    } else {
      // Show Brains
      content = <AssetList assets={assets} />
    }
  }

  return (
    <Container data-testid="assets-panel">
      <Grid
        container
        direction="row"
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        alignItems="center"
        flexGrow={0}
        gap={1}
        wrap="wrap-reverse"
      >
        <Grid
          gap={1}
          container
          justifyContent={{ xs: 'center', sm: 'flex-start' }}
          sx={{ width: 'auto' }}
          data-testid="assets-filter-header"
        >
          <FilterByTypeSelect />
          <SortingSelect />
        </Grid>
        {enableSearchBar && (
          <Input
            sx={{
              height: 42,
              borderRadius: 2,
              margin: { xs: '30px 0', sm: '0' },
            }}
            startAdornment={
              <Box
                mr={1.5}
                sx={theme => ({ color: theme.palette.grey['600'] })}
              >
                <IconFactory name="search" width={20} color="white" />
              </Box>
            }
            data-testid="assets-search"
          />
        )}
      </Grid>
      {content}
      <PaginationContainer>
        <TablePagination
          disabled={isLoading}
          count={totalPageCount}
          page={currentPage}
          onPageChange={handleChangePage}
        />
      </PaginationContainer>
    </Container>
  )
}
