import { pipe } from 'fp-ts/function'
import * as Decoder from 'io-ts/Decoder'

export const BooleanFromStringD: Decoder.Decoder<unknown, boolean> = pipe(
  Decoder.string,
  Decoder.map(str => str.toLowerCase().trim()),
  Decoder.parse(str => {
    if (str === 'true') {
      return Decoder.success(true)
    }

    if (str === 'false') {
      return Decoder.success(false)
    }

    return Decoder.failure(str, 'valid boolean')
  }),
)
