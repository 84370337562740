import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { enUS } from './locales'

export const defaultNS = 'en-US'

// list of available resources files
export const resources = {
  'en-US': {
    translation: enUS,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en-US',
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })

export default i18n
