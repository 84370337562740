import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
} from '@mui/material'

const Skeleton = (props: MuiSkeletonProps) => {
  return <MuiSkeleton {...props} />
}

export default Skeleton
export type { MuiSkeletonProps as SkeletonProps }
