import { useTranslation } from 'react-i18next'
import { MainNavItem } from '../MainNavItem'
import { ReactComponent as CookieSvg } from 'component-library/src/assets/icons/cookie.svg'
import { Paths } from 'src/utils/routes'

const GenomeMiningNavigation = () => {
  const { t } = useTranslation()
  return (
    <MainNavItem
      data-cy="nav-genome-mining"
      to={Paths.GenomeMining}
      text={t('navigation.genome-mining')}
      icon={CookieSvg}
      exact={false}
    />
  )
}

export default GenomeMiningNavigation
