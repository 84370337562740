export const POPUP_LEAVE_TOUCH_DELAY = 4500

export const LBA_NOTIFICATION_KEY = 'asm-cortex:notifications-shown:lba'
export const REVERSE_CLAIM_POPUP_KEY =
  'asm-cortex:notifications-shown:reverse-claim-education'
export const GM_STORYTELLING_POPUP_KEY = 'asm-cortex:genome-mining:storytelling'
export const GM_MONITORING_STORYTELLING_POPUP_KEY =
  'asm-cortex:genome-mining:monitoring-storytelling'
export const GM_MINT_BEGAN_POPUP_KEY = 'asm-cortex:popups-shown:gm-mint'
export const GM_MINT_MAP_INTERACTION_MODAL_KEY =
  'asm-cortex:gm-mint:map-interaction'

export const GM_MINT_MAP_STORYTELLING_POPUP_KEY =
  'asm-cortex:genome-mining:map-storytelling'

export const GM_MINING_MAP_AE_SUMMARY_POPUP_KEY =
  'asm-cortex:genome-mining:map-ae-summary-popup'
