import { css } from '@mui/material'
import { styled } from '@mui/material/styles'
import { useTranslation } from 'react-i18next'

import { Paths } from 'src/utils/routes'
import { MainNavItem } from './MainNavItem'
import Account from 'src/components/status/Account'
import { useBreakpoints } from 'src/hooks/useBreakpoints'
import useNavigationBar from 'src/hooks/store/useNavigationBar'
import ASMLogo from 'component-library/src/components/icons/ASMLogo'
import { ReactComponent as HomeSvg } from 'component-library/src/assets/icons/home.svg'
import { ReactComponent as DrawerSvg } from 'component-library/src/assets/icons/drawer.svg'
import { ReactComponent as RewardSvg } from 'component-library/src/assets/icons/reward.svg'
import Drawer from 'component-library/src/components/drawer/Drawer'
import EducationNavigation from './navigation/EducationNavigation'
import GenomeMiningNavigation from './navigation/GenomeMiningNavigation'
import InventoryNavigation from './navigation/InventoryNavigation'
import LunarisMachineNavigation from './navigation/LunarisMachineNavigation'

const Container = styled('div')(
  ({ theme }) => css`
    width: 100%;
    display: flex;
    padding: 0 6px;
    max-width: 230px;
    align-items: center;
    flex-direction: column;
    background-color: ${theme.palette.background.dark};
  `,
)

const Nav = styled('nav')`
  width: 100%;
`

const DrawerIconContainer = styled('div')(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacing(2)};
    left: ${theme.spacing(2)};
    line-height: 0;
    > svg {
      position: relative;
      top: 0px;
      left: 0px;
    }
  `,
)

const StyledAccount = styled(Account)(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 3)};
  `,
)

const Navigation = () => {
  const { t } = useTranslation()
  const { isSmallScreen } = useBreakpoints()
  return (
    <Container>
      <ASMLogo width={64} style={{ margin: 24 }} />
      {isSmallScreen && <StyledAccount />}
      <Nav>
        <MainNavItem
          to={Paths.Home}
          text={t('navigation.dashboard')}
          icon={HomeSvg}
        />
        <LunarisMachineNavigation />
        <GenomeMiningNavigation />
        <InventoryNavigation />
        <MainNavItem
          data-cy="nav-rewards"
          to={Paths.Rewards}
          text={t('navigation.rewards')}
          icon={RewardSvg}
          exact={false}
        />
        <EducationNavigation />
      </Nav>
    </Container>
  )
}

export const MainNav: React.FC = () => {
  const { t } = useTranslation()
  const { theme, useMediaQuery } = useBreakpoints()

  // 900 is the number same as Cortex Theme MD breakpoint
  const showFullNav = useMediaQuery(theme.breakpoints.up(900))

  const { isNavigationBarOpen, openNavigationBar, closeNavigationBar } =
    useNavigationBar()

  const openDrawer = () => openNavigationBar()

  const closeDrawer = () => closeNavigationBar()

  if (showFullNav) {
    return <Navigation />
  }

  return (
    <>
      <DrawerIconContainer
        role="button"
        onClick={openDrawer}
        aria-label={t('navigation.open-menu')}
        data-cy="open-navigation"
      >
        <DrawerSvg />
      </DrawerIconContainer>
      <Drawer open={isNavigationBarOpen} onClose={closeDrawer}>
        <Navigation />
      </Drawer>
    </>
  )
}

export default MainNav
