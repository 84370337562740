/* eslint-disable camelcase */
import { useMemo } from 'react'

import { ChainENVs, CHAIN_ENV } from 'src/utils/web3'
import { useWeb3LibraryProvider } from '../useWeb3LibraryProvider'
import { Staking__factory } from 'smart-contracts/gen/factories/Staking__factory'

import mainnetABI from 'smart-contracts/abi/mainnet.json'
import localhostABI from 'smart-contracts/abi/localhost.json'
import goerliStagingABI from 'smart-contracts/abi/goerli-staging.json'
import goerliABI from 'smart-contracts/abi/goerli.json'
// import kovanABI from 'smart-contracts/abi/kovan.json'

export const addresses: Record<ChainENVs, string> = {
  localhost: localhostABI.contracts.Staking.address,
  mainnet: mainnetABI.contracts.Staking.address,
  goerliStaging: goerliStagingABI.contracts.Staking.address,
  goerli: goerliABI.contracts.Staking.address,
  kovan: '',
}

const factory = Staking__factory

const useStakingContract = () => {
  const address = addresses[CHAIN_ENV]
  const rpcProvider = useWeb3LibraryProvider()

  const contract = useMemo(
    () => factory.connect(address, rpcProvider),
    [address, rpcProvider],
  )
  return contract
}

export default useStakingContract
