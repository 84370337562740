import { styled, Typography, ButtonProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { useLBAMigration } from 'src/hooks/useLBAMigration'
import Button from 'component-library/src/components/button/Button'
import { useLBAMigrationPopup } from 'src/modules/lunaris-machine/hooks/useLBAMigrationPopup'
import { reportEvent } from 'src/utils/ga'

const StyledLinkText = styled(Typography)`
  text-align: center;
  cursor: pointer;
  color: white;
  letter-spacing: 0.46px;
  user-select: none;
  line-height: 1;
  flex: 0 0 auto;
  font-weight: 600;
  text-transform: uppercase;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: white;
  }
`

interface MigrationButtonProps extends ButtonProps {}

const MigrationButton = (props: MigrationButtonProps) => {
  const { t } = useTranslation()
  const { currentStep, canMigrate } = useLBAMigration()
  const { openLBAMigrationPopup } = useLBAMigrationPopup()

  const buttonName = () => {
    if (currentStep === 0) {
      return t('lba-migration.button')
    } else if (currentStep === 1) {
      return t('lba-migration.finish-button')
    } else if (currentStep === 2) {
      return t('lba-migration.finish-button')
    }
    return ''
  }

  const handleOpen = () => {
    reportEvent('button_click', {
      button_name: buttonName(),
      page_title: 'LBA Migration',
    })
    openLBAMigrationPopup()
  }

  if (!canMigrate) {
    return null
  }

  return (
    <>
      {props?.variant !== 'text' && (
        <Button
          variant="outlined"
          onClick={handleOpen}
          data-testid="migrate-lba-lp-tokens"
          sx={{ minWidth: 'max-content' }}
          {...props}
        >
          {buttonName()}
        </Button>
      )}
      {props?.variant === 'text' && (
        <StyledLinkText
          onClick={handleOpen}
          data-testid="migrate-lba-lp-tokens"
        >
          {t('lba-migration.link')}
        </StyledLinkText>
      )}
    </>
  )
}

export default MigrationButton
