import { Children } from 'react'
import Skeleton, { SkeletonProps } from './Skeleton'

export interface LoadingSkeletonProps extends SkeletonProps {
  loading?: boolean
}

export const LoadingSkeleton: React.FC<LoadingSkeletonProps> = ({
  loading,
  children,
  sx,
  ...props
}) => {
  const arrayChildren = Children.toArray(children)
  return (
    <>
      {loading
        ? Children.map(arrayChildren, child => {
            return (
              <Skeleton
                sx={{ borderRadius: '0px', backgroundColor: 'grey.700', ...sx }}
                {...props}
              >
                {child}
              </Skeleton>
            )
          })
        : children}
    </>
  )
}
