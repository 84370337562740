import {
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  css,
  styled,
} from '@mui/material'

const StyledDrawer = styled(MuiDrawer)(
  ({ theme }) => css`
  width: 230px,
  backgroundImage: 'none',
  backgroundColor: ${theme.palette.background.dark},
  `
)

const Drawer = ({ children, ...props }: MuiDrawerProps) => {
  return <StyledDrawer {...props}>{children}</StyledDrawer>
}

export default Drawer
export type { MuiDrawerProps as DrawerProps }
