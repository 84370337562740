import { useState, MouseEvent } from 'react'
import { Button, css, Menu, MenuItem, styled } from '@mui/material'
import openseaLogo from 'component-library/src/assets/icons/opensea.png'
import { reportEvent } from 'src/utils/ga'

const BREAKPOINT = 475

const StyledButton = styled(Button)(
  ({ theme }) => css`
    color: #fff;
    width: 200px;
    background: #1c1b28;

    :first-of-type {
      margin-right: ${theme.spacing(2)};
    }

    svg {
      margin-right: ${theme.spacing(1)};
    }

    ${theme.breakpoints.down(BREAKPOINT)} {
      width: 100%;

      :first-of-type {
        margin: ${theme.spacing(0, 0, 2)};
      }
    }
  `,
)

const StyledMenu = styled(Menu)(
  ({ theme }) => css`
    .MuiPaper-root {
      width: 200px;
    }

    ${theme.breakpoints.down(BREAKPOINT)} {
      .MuiPaper-root {
        width: 100%;
      }
    }
  `,
)

const StyledMenuItem = styled(MenuItem)(
  ({ theme }) => css`
    padding: 0;

    > a {
      width: 100%;
      text-align: center;
      padding: ${theme.spacing(1.5)};
    }
  `,
)

interface OpenSeaMenuProps {
  pageTitle?: string
  buttonSuffix?: string
}

export const OpenSeaMenu = ({
  pageTitle = 'Inventory',
  buttonSuffix = 'Inv',
}: OpenSeaMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const openMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <StyledButton variant="outlined" onClick={openMenu}>
        <img
          src={openseaLogo}
          alt="Opensea Logo"
          style={{ width: 22, marginRight: 8 }}
        />
        Buy on Opensea
      </StyledButton>
      <StyledMenu
        anchorEl={anchorEl}
        onClose={closeMenu}
        open={Boolean(anchorEl)}
      >
        <StyledMenuItem
          onClick={() => {
            reportEvent('button_click', {
              button_name: `Buy_OpenSea_ASMBrains_${buttonSuffix}`,
              page_title: pageTitle,
            })
            closeMenu()
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://opensea.io/collection/asm-brains"
          >
            ASM Genesis Brains
          </a>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            reportEvent('button_click', {
              button_name: `Buy_OpenSea_ASMGen2Brains_${buttonSuffix}`,
              page_title: pageTitle,
            })
            closeMenu()
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://opensea.io/collection/asm-gen-ii-brains"
          >
            ASM Gen II Brains
          </a>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            reportEvent('button_click', {
              button_name: `Buy_OpenSea_AIFAAllStars_${buttonSuffix}`,
              page_title: pageTitle,
            })
            closeMenu()
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://opensea.io/collection/asm-aifa-all-stars"
          >
            AIFA All-Stars
          </a>
        </StyledMenuItem>
        <StyledMenuItem
          onClick={() => {
            reportEvent('button_click', {
              button_name: `Buy_OpenSea_AIFABoxes_${buttonSuffix}`,
              page_title: pageTitle,
            })
            closeMenu()
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://opensea.io/collection/asm-aifa-genesis"
          >
            ASM Boxes
          </a>
        </StyledMenuItem>
      </StyledMenu>
    </>
  )
}
