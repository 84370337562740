/* eslint-disable camelcase */
/* eslint-disable no-import-assign */
import { useMemo } from 'react'

import { ChainENVs, CHAIN_ENV } from 'src/utils/web3'
import { ASMBrain__factory } from 'smart-contracts/gen/factories/ASMBrain__factory'
import { useWeb3LibraryProvider } from '../useWeb3LibraryProvider'

import localhostABI from 'smart-contracts/abi/localhost.json'
import goerliStagingABI from 'smart-contracts/abi/goerli-staging.json'
import goerliABI from 'smart-contracts/abi/goerli.json'
import kovanABI from 'smart-contracts/abi/kovan.json'

const mainnetAddress = '0xd0318da435dbce0b347cc6faa330b5a9889e3585'
export const addresses: Record<ChainENVs, string> = {
  localhost: localhostABI.contracts.MockedASMBrain.address,
  mainnet: mainnetAddress,
  goerliStaging: goerliStagingABI.contracts.MockedASMBrain.address,
  goerli: goerliABI.contracts.MockedASMBrain.address,
  kovan: kovanABI.contracts.MockedASMBrain.address,
}

const factory = ASMBrain__factory

const useASMBrainContract = () => {
  const rpcProvider = useWeb3LibraryProvider()

  const contract = useMemo(() => {
    return factory.connect(addresses[CHAIN_ENV], rpcProvider)
  }, [rpcProvider])

  return contract
}

export default useASMBrainContract
