import {
  Fade,
  IconButton,
  DialogContent,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
} from '@mui/material'
import { styled, css } from '@mui/material/styles'

import { ReactComponent as Close } from 'component-library/src/assets/icons/close.svg'

const CloseButton = styled(IconButton)(
  ({ theme }) => css`
    top: 8px;
    right: 8px;
    position: absolute;

    svg {
      stroke: ${theme.palette.grey[200]};
    }

    ${theme.breakpoints.down('sm')} {
      top: 4px;
      right: 4px;

      svg {
        width: 18px;
      }
    }
  `
)

export interface DialogProps extends MuiDialogProps {
  disableCloseButton?: boolean
}

const Dialog: React.FC<DialogProps> = ({
  onClose,
  children,
  disableCloseButton = false,
  ...props
}) => {
  const handleCloseClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    onClose && onClose(e, 'escapeKeyDown')
  }

  return (
    <MuiDialog
      {...props}
      TransitionComponent={Fade}
      onClose={disableCloseButton ? undefined : onClose}
    >
      {!disableCloseButton && (
        <CloseButton
          aria-label="Close"
          onClick={handleCloseClick}
          data-cy="close-dialog"
        >
          <Close />
        </CloseButton>
      )}
      <DialogContent>{children}</DialogContent>
    </MuiDialog>
  )
}

export default Dialog
