import { lazy, Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import { styled, css } from '@mui/material/styles'

import LogRocket from 'logrocket'
import * as Sentry from '@sentry/browser'

import { Paths } from 'src/utils/routes'
import StatusBar from 'src/components/status'
import NotFound from 'src/modules/core/NotFound'
import PageLoading from 'src/modules/core/PageLoading'
import SkipLink from 'component-library/src/components/link/SkipLink'
import { useAlertbar } from 'src/hooks/store/useAlertbar'
import { useEagerConnect } from 'src/hooks/useEagerConnect'
import MainNavigation from 'src/modules/core/MainNavigation'
import ConnectWalletDialog from 'src/modules/core/ConnectWalletDialog'
import { useWeb3React } from 'src/hooks/useWeb3React'
import Statusbar from 'src/components/feedback/Statusbar'
import Alertbar from 'component-library/src/components/alertbar/AlertBar'
import { FeatureFlags, getFeatureValue } from 'src/utils/featureFlags'
import useStatusbar from 'src/hooks/store/useStatusbar'
import { useLBA } from 'src/hooks/useLBA'
import { useTranslation } from 'react-i18next'
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk'
import Inventory from './inventory'
import AutoRefreshPopup from './core/AutoRefreshPopup'
import { useLBAMigration } from 'src/hooks/useLBAMigration'
import MigrationButton from 'src/components/LBAMigration'
import { LBAMigrationPopup } from 'src/components/LBAMigrationPopup'

const AllStarClaim = lazy(() => import('src/modules/all-star-claim'))
const BrainClaimCheck = lazy(() => import('src/modules/brain-claim-check'))
const DiscoveryAuction = lazy(() => import('src/modules/discovery'))
const Home = lazy(() => import('src/modules/cortex-hub'))
const Rewards = lazy(() => import('src/modules/rewards'))
const TermsAndConditions = lazy(
  () => import('src/modules/core/TermsAndConditions'),
)
const GenomeMining = lazy(() => import('src/modules/mining'))
const LunarisMachine = lazy(() => import('src/modules/lunaris-machine'))

const MainApp = styled('div')`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  position: relative;
`

const Main = styled('div')`
  flex-grow: 1;
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
  background-image: linear-gradient(
    to top right,
    rgb(0, 0, 0) 62%,
    rgba(156, 98, 218, 0.1),
    rgba(235, 130, 234, 0.1),
    rgba(252, 138, 230, 0.15)
  );
  background-size: auto 100%;
  background-position: right;
  background-repeat: no-repeat;
`

const StyledAlertbar = styled(Alertbar)(
  ({ theme }) => css`
    min-width: 500px;

    ${theme.breakpoints.down('sm')} {
      min-width: 100%;
    }
  `,
)

const enableAllStarRoute = getFeatureValue(FeatureFlags.AllStarClaim)

const App = () => {
  useEagerConnect()
  const { message, severity, closeAlertbar, isAlertbarOpen } = useAlertbar()
  const ldClient = useLDClient()
  const { account } = useWeb3React()
  const { openStatusbar, closeStatusbar } = useStatusbar()
  const { showLBAEventEndingNotificationMessage } = useLBA()
  const { t } = useTranslation()
  const { isLBAEnergyFinished, canMigrate } = useLBAMigration()

  useEffect(() => {
    if (account) {
      LogRocket.identify(account, {
        address: account,
      })
      Sentry.setUser({ id: account, address: account })
    }
  }, [account])

  useEffect(() => {
    if (ldClient && account) {
      ldClient.identify({ key: account })
    }
  }, [account, ldClient])

  useEffect(() => {
    if (showLBAEventEndingNotificationMessage) {
      openStatusbar({
        message: t('lpa-event-end-notification'),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLBAEventEndingNotificationMessage])

  const { lbaMigrationTemp221201: enableLBAMigration } = useFlags()

  useEffect(() => {
    if (enableLBAMigration && canMigrate) {
      openStatusbar({
        message: isLBAEnergyFinished
          ? t('asto-auction-lp-migration-notification')
          : t('asto-auction-lp-migration.closing-soon-notification'),
        actionComponent: <MigrationButton variant="text" />,
      })
    } else {
      closeStatusbar()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableLBAMigration, canMigrate])

  return (
    <>
      <SkipLink href="#main-pass">Skip to main content</SkipLink>
      <Statusbar />
      <MainApp>
        <MainNavigation />
        <Main id="main-pass">
          <StatusBar />
          <Suspense fallback={<PageLoading />}>
            {/* TODO(CW-593): Move routes to a separate file */}
            <Routes>
              <Route path={Paths.Home} element={<Home />} />
              {enableAllStarRoute && (
                <Route
                  path={`${Paths.AllStarClaim}/*`}
                  element={<AllStarClaim />}
                />
              )}
              <Route
                path={`${Paths.LunarisMachine}/*`}
                element={<LunarisMachine />}
              />
              <Route
                path={`${Paths.GenomeMining}/*`}
                element={<GenomeMining />}
              />
              <Route path={`${Paths.Inventory}/*`} element={<Inventory />} />
              <Route
                path={`${Paths.Discovery}/*`}
                element={<DiscoveryAuction />}
              />
              <Route path={`${Paths.Rewards}/*`} element={<Rewards />} />
              <Route
                path={`${Paths.BrainClaimCheck}`}
                element={<BrainClaimCheck />}
              />
              <Route
                path={Paths.TermsAndConditions}
                element={<TermsAndConditions />}
              />
              <Route path={`*`} element={<NotFound />} />
            </Routes>
          </Suspense>
        </Main>
      </MainApp>
      <StyledAlertbar
        variant="filled"
        severity={severity}
        open={isAlertbarOpen}
        onClose={closeAlertbar}
        snackbarProps={{
          autoHideDuration: 4000,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }}
      >
        {message}
      </StyledAlertbar>
      <ConnectWalletDialog />
      <AutoRefreshPopup />
      <LBAMigrationPopup />
    </>
  )
}

export default App
