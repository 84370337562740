import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import shallow from 'zustand/shallow'
import { Vector2d } from 'konva/lib/types'
import {
  TileType,
  MapRegion,
  NonMineableAreaSubregions,
} from 'common/src/mining-map/types'

export type PendingSlot = { slotId: number; tiles: Vector2d[] }

interface MiningMapState {
  miningSlotId: number
  setMiningSlotId: (slotId: number) => void
  hoverTile: TileType
  setHoverTile: (tile: TileType) => void
  selectedTile?: Vector2d
  setSelectedTile: (tile?: Vector2d) => void
  selectedTiles: Vector2d[]
  setSelectedTiles: (tiles: Vector2d[]) => void
  pendingSlots: PendingSlot[]
  setPendingSlots: (tiles: PendingSlot[]) => void
  revealedTiles: Vector2d[]
  setRevealedTiles: (tiles: Vector2d[]) => void
  radius: number
  setRadius: (radius: number) => void
  sweep: number
  setSweep: (sweep: number) => void
  loadingTaskCount: number
  startALoadingTask: () => void
  endALoadingTask: () => void
  isLoading: boolean
  searchTile?: Vector2d
  setSearchTile: (tile: Vector2d) => void
  clearSearchTile: () => void
  resetOptions: () => void
  numberOfBrainRushBrains: number | undefined
  setBrainRushBrains: (numberOfBrains: number | undefined) => void
  voidTile?: Vector2d
  setVoidTile: (tile?: Vector2d) => void
  selectedVoidPoolTile?: Vector2d
  isBeingFilled: boolean
  setIsBeingFilled: (isBeingFilled: boolean) => void
}

const useMiningMap = create(
  subscribeWithSelector<MiningMapState>(set => ({
    miningSlotId: 0,
    setMiningSlotId: slotId => set({ miningSlotId: slotId }),
    hoverTile: {
      x: 0,
      y: 0,
      isSelectable: false,
      region: MapRegion.NonMineableArea,
      subregion: NonMineableAreaSubregions.NonMineableArea,
    },
    setHoverTile: tile => set({ hoverTile: tile }),
    selectedTile: undefined,
    setSelectedTile: tile => {
      set({ radius: 1, selectedTile: tile })
    },
    selectedTiles: [],
    setSelectedTiles: selectedTiles => {
      set({ selectedTiles })
    },
    pendingSlots: [],
    setPendingSlots: tiles => {
      set({ pendingSlots: tiles })
    },
    searchTile: undefined,
    setSearchTile: tile => set({ searchTile: tile }),
    clearSearchTile: () => set({ searchTile: undefined }),
    revealedTiles: [],
    setRevealedTiles: tiles => {
      set({ revealedTiles: tiles })
    },
    radius: 1,
    setRadius: radius => set({ radius }),
    sweep: 1,
    setSweep: sweep => set({ sweep }),
    loadingTaskCount: 0,
    startALoadingTask: () =>
      set(state => ({ loadingTaskCount: state.loadingTaskCount + 1 })),
    endALoadingTask: () =>
      set(state => ({ loadingTaskCount: state.loadingTaskCount - 1 })),
    isLoading: false,
    resetOptions: () => {
      set({
        radius: 1,
        sweep: 1,
        selectedTile: undefined,
        selectedTiles: [],
      })
    },
    numberOfBrainRushBrains: undefined,
    setBrainRushBrains: numberOfBrainRushBrains =>
      set({ numberOfBrainRushBrains }),
    voidTile: undefined,
    setVoidTile: tile => set({ voidTile: tile }),
    selectedVoidPoolTile: undefined,
    isBeingFilled: false,
    setIsBeingFilled: isBeingFilled => set({ isBeingFilled }),
  })),
)

useMiningMap.subscribe(
  s => ({
    loadingTaskCount: s.loadingTaskCount,
    voidTile: s.voidTile,
    selectedTile: s.selectedTile,
  }), // only compute when loadingTaskCount change
  ({ loadingTaskCount, voidTile, selectedTile }) => {
    const selectedVoidPoolTile =
      voidTile &&
      selectedTile &&
      voidTile.x === selectedTile.x &&
      voidTile.y === selectedTile.y
        ? voidTile
        : undefined

    useMiningMap.setState({
      selectedVoidPoolTile: selectedVoidPoolTile,
      isLoading: loadingTaskCount > 0,
    })
  },
  { equalityFn: shallow, fireImmediately: true },
)

export default useMiningMap
