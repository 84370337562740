import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useWeb3React } from 'src/hooks/useWeb3React'
import ScreenLayout from 'src/components/layout/ScreenLayout'
import DisconnectedWallet from 'src/components/status/DisconnectedWallet'

import { ConnectedWallet } from './ConnectedWallet'

const Inventory = () => {
  const { account } = useWeb3React()
  const { t } = useTranslation()

  return (
    <ScreenLayout title={t('inventory.title')} description="">
      {account ? (
        <ConnectedWallet />
      ) : (
        <DisconnectedWallet>
          <Typography mb={'1rem'} mt={'1rem'}>
            {t('inventory.connect-wallet.description')}
          </Typography>
        </DisconnectedWallet>
      )}
    </ScreenLayout>
  )
}

export default Inventory
