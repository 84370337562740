import { useTranslation } from 'react-i18next'

import { MainNavItem, MainNavItemGroup } from '../MainNavItem'

import { ReactComponent as EducationSvg } from 'component-library/src/assets/icons/education.svg'
import { GENOME_MINING_ARTICLE_URL } from 'src/utils/urls'
import { css, styled } from '@mui/material/styles'

const UpdatedLabel = styled('span')(
  () => css`
    color: #7c6efb;
    border: 2px solid #7c6efb;
    border-radius: 4px;
    padding: 0.25em 0.5em;
    margin-left: 0.5em;
  `,
)

const EducationNavigation = () => {
  const { t } = useTranslation()

  return (
    <MainNavItemGroup text="Education" icon={EducationSvg}>
      <MainNavItem
        data-cy="nav-education-blog"
        text={t('navigation.blog')}
        to="https://medium.com/@alteredstatemachine"
        isExternalLink
      ></MainNavItem>
      <MainNavItem
        data-cy="nav-education-void-faq"
        text={t('navigation.void-faq')}
        to={
          'https://medium.com/@alteredstatemachine/asm-void-protocol-faq-5f98746ffa84'
        }
        isExternalLink
      ></MainNavItem>
      <MainNavItem
        data-cy="nav-education-gm-faq"
        text={
          <div>
            {t('navigation.genome-mining-faqs')}
            <UpdatedLabel>Updated</UpdatedLabel>
          </div>
        }
        to={GENOME_MINING_ARTICLE_URL}
        isExternalLink
        disableExternalLinkIcon
      ></MainNavItem>
    </MainNavItemGroup>
  )
}

export default EducationNavigation
