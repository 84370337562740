import create from 'zustand'

interface UseWalletsDialogState {
  isWalletsDialogOpen: boolean
  redirect: string | undefined
  openWalletsDialog: (redirect?: string) => void
  closeWalletsDialog: () => void
  clearRedirect: () => void
}

export const useWalletsDialog = create<UseWalletsDialogState>(set => ({
  isWalletsDialogOpen: false,
  redirect: undefined,
  clearRedirect: () => set({ redirect: undefined }),
  openWalletsDialog: (redirect?: string) =>
    set({ isWalletsDialogOpen: true, redirect }),
  closeWalletsDialog: () => set({ isWalletsDialogOpen: false }),
}))
