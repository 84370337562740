import gilroyThinWoff from '../assets/fonts/Gilroy/Gilroy-Thin.woff'
import gilroyBoldWoff from '../assets/fonts/Gilroy/Gilroy-Bold.woff'
import gilroyLightWoff from '../assets/fonts/Gilroy/Gilroy-Light.woff'
import gilroyMediumWoff from '../assets/fonts/Gilroy/Gilroy-Medium.woff'
import gilroyRegularWoff from '../assets/fonts/Gilroy/Gilroy-Regular.woff'
import gilroyRegularItalicWoff from '../assets/fonts/Gilroy/Gilroy-RegularItalic.woff'
import gilroySemiBoldWoff from '../assets/fonts/Gilroy/Gilroy-SemiBold.woff'

import gilroyThinWoff2 from '../assets/fonts/Gilroy/Gilroy-Thin.woff2'
import gilroyBoldWoff2 from '../assets/fonts/Gilroy/Gilroy-Bold.woff2'
import gilroyLightWoff2 from '../assets/fonts/Gilroy/Gilroy-Light.woff2'
import gilroyMediumWoff2 from '../assets/fonts/Gilroy/Gilroy-Medium.woff2'
import gilroyRegularWoff2 from '../assets/fonts/Gilroy/Gilroy-Regular.woff2'
import gilroyRegularItalicWoff2 from '../assets/fonts/Gilroy/Gilroy-RegularItalic.woff2'
import gilroySemiBoldWoff2 from '../assets/fonts/Gilroy/Gilroy-SemiBold.woff2'

import adieuBoldWoff from '../assets/fonts/Adieu/Adieu-Bold.woff'
import adieuLightWoff from '../assets/fonts/Adieu/Adieu-Light.woff'
import adieuRegularWoff from '../assets/fonts/Adieu/Adieu-Regular.woff'
import adieuRegularItalicWoff from '../assets/fonts/Adieu/Adieu-RegularSlanted.woff'

import adieuBoldWoff2 from '../assets/fonts/Adieu/Adieu-Bold.woff2'
import adieuLightWoff2 from '../assets/fonts/Adieu/Adieu-Light.woff2'
import adieuRegularWoff2 from '../assets/fonts/Adieu/Adieu-Regular.woff2'
import adieuRegularItalicWoff2 from '../assets/fonts/Adieu/Adieu-RegularSlanted.woff2'

interface FontStyle {
  woff: string
  woff2: string
  family: string
  weight: number
  style?: 'italic' | 'normal'
}

export const gilroy = 'Gilroy'
export const adieu = 'Adieu'

const generateFontFace = ({
  woff,
  woff2,
  family,
  weight,
  style = 'normal',
}: FontStyle) => `
  @font-face {
    font-display: swap;
    font-style: ${style};
    font-weight: ${weight};
    font-family: '${family}';
    src: url(${woff}) format('woff'), url(${woff2}) format('woff2');
  }
`

// Gilroy

export const gilroyThin = generateFontFace({
  weight: 200,
  family: gilroy,
  woff: gilroyThinWoff,
  woff2: gilroyThinWoff2,
})

export const gilroyLight = generateFontFace({
  weight: 300,
  family: gilroy,
  woff: gilroyLightWoff,
  woff2: gilroyLightWoff2,
})

export const gilroyRegular = generateFontFace({
  weight: 400,
  family: gilroy,
  woff: gilroyRegularWoff,
  woff2: gilroyRegularWoff2,
})

export const gilroyMedium = generateFontFace({
  weight: 500,
  family: gilroy,
  woff: gilroyMediumWoff,
  woff2: gilroyMediumWoff2,
})

export const gilroySemiBold = generateFontFace({
  weight: 600,
  family: gilroy,
  woff: gilroySemiBoldWoff,
  woff2: gilroySemiBoldWoff2,
})

export const gilroyBold = generateFontFace({
  weight: 700,
  family: gilroy,
  woff: gilroyBoldWoff,
  woff2: gilroyBoldWoff2,
})

export const gilroyItalic = generateFontFace({
  weight: 400,
  family: gilroy,
  style: 'italic',
  woff: gilroyRegularItalicWoff,
  woff2: gilroyRegularItalicWoff2,
})

// Adieu

export const adieuLight = generateFontFace({
  weight: 300,
  family: adieu,
  woff: adieuLightWoff,
  woff2: adieuLightWoff2,
})

export const adieuRegular = generateFontFace({
  weight: 400,
  family: adieu,
  woff: adieuRegularWoff,
  woff2: adieuRegularWoff2,
})

export const adieuBold = generateFontFace({
  weight: 700,
  family: adieu,
  woff: adieuBoldWoff,
  woff2: adieuBoldWoff2,
})

export const adieuItalic = generateFontFace({
  weight: 400,
  family: adieu,
  style: 'italic',
  woff: adieuRegularItalicWoff,
  woff2: adieuRegularItalicWoff2,
})
