import { useWalletsDialog } from 'src/hooks/store/useWalletsDialog'
import Button, {
  ButtonProps,
} from 'component-library/src/components/button/Button'
import { useTranslation } from 'react-i18next'

interface ConnectWalletButtonProps extends ButtonProps {}

const ConnectWalletButton: React.FC<ConnectWalletButtonProps> = props => {
  const { openWalletsDialog } = useWalletsDialog()
  const { t } = useTranslation()

  const handleClick = () => {
    openWalletsDialog()
  }

  return (
    <Button data-testid="connect-wallet" onClick={handleClick} {...props}>
      {t('connect-wallet')}
    </Button>
  )
}

export default ConnectWalletButton
