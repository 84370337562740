import create from 'zustand'

import { AlertColor } from '@mui/material'

export interface AlertbarData {
  message: string
  severity: AlertColor
}

interface UseAlertbar extends AlertbarData {
  isAlertbarOpen: boolean
  closeAlertbar: () => void
  openAlertbar: (data: AlertbarData) => void
}

export const useAlertbar = create<UseAlertbar>(set => ({
  message: '',
  severity: 'error',
  isAlertbarOpen: false,
  closeAlertbar: () => set(state => ({ ...state, isAlertbarOpen: false })),
  openAlertbar: data =>
    set(state => ({ ...state, ...data, isAlertbarOpen: true })),
}))
