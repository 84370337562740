import { Button as MuiButton, ButtonProps } from '@mui/material'
import { css, styled, useTheme } from '@mui/material/styles'
import { formatAccount } from '../../utils/helpers'

import { ReactComponent as ConnectSvg } from '../../assets/icons/connect.svg'
import { ReactComponent as ChevronDownSvg } from '../../assets/icons/chevron-down.svg'

const Button = styled(MuiButton)`
  font-family: Gilroy;
  border-width: 1px !important;
  border-radius: 999px;
  background-color: black;
  padding-left: 0.5rem;
`

const IconContainerSmall = styled('div')(
  ({ theme }) => css`
    background: ${theme.palette.grey['700']};
    border-radius: 999px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  `
)

export type WalletButtonProps = {
  accountNumber?: string | null
} & ButtonProps

export const WalletButton = ({
  accountNumber,
  ...props
}: WalletButtonProps) => {
  const theme = useTheme()
  return (
    <Button
      startIcon={
        <IconContainerSmall data-cy="wallet-account-number">
          {accountNumber ? (
            <ConnectSvg fill={theme.palette.primary.main} />
          ) : (
            <ChevronDownSvg fill="white" />
          )}
        </IconContainerSmall>
      }
      {...props}
      variant="outlined"
    >
      <span
        data-percy-static-content={
          accountNumber &&
          formatAccount('0x0123456789012345678901234567890123456789')
        }
      >
        {accountNumber ? formatAccount(accountNumber) : 'Connect Wallet'}
      </span>
    </Button>
  )
}

export default WalletButton
