import { BigNumber, constants } from 'ethers'
import { useCallback, useMemo } from 'react'
import { useQuery, useMutation } from 'react-query'
import { useWeb3React } from 'src/hooks/useWeb3React'
import useASTOTokenContract from 'src/hooks/contracts/useASTOTokenContract'
import useStakingContract from 'src/hooks/contracts/useStakingContract'
import useEnergyConverterContract from 'src/hooks/contracts/useEnergyConverterContract'
import { parseEther, formatUnits } from 'ethers/lib/utils'
import { useLBAContract } from 'src/hooks/contracts/useLBAContract'
import useStakingHistory, {
  StakingContractTokenId,
} from 'src/hooks/useStakingHistory'
import useLPTokenContract from 'src/hooks/contracts/useLPTokenContract'

export const useGenomeMining = () => {
  const { account } = useWeb3React()
  const stakingContract = useStakingContract()
  const energyConverterContract = useEnergyConverterContract()
  const astoContract = useASTOTokenContract()
  const lbaContract = useLBAContract()
  const lpTokenContract = useLPTokenContract()

  // staking history and balance
  const stakingHistoryAsto = useStakingHistory('asto')
  const stakingHistoryLp = useStakingHistory('lp')

  const getStakingBalance = (
    stakingHistory: { time: number; amount: string }[],
  ) => {
    const len = stakingHistory.length
    if (len === 0) return '0'
    return stakingHistory[len - 1].amount
  }

  const stakingBalanceAsto = useMemo(() => {
    return getStakingBalance(stakingHistoryAsto.data || [])
  }, [stakingHistoryAsto.data])

  const stakingBalanceLp = useMemo(() => {
    return getStakingBalance(stakingHistoryLp.data || [])
  }, [stakingHistoryLp.data])

  // stake asto
  const stakeToken = useCallback(
    async (token: string, amount: string) => {
      const tokenId =
        token === 'asto'
          ? StakingContractTokenId.ASTO
          : StakingContractTokenId.LP
      const tx = await stakingContract.stake(
        tokenId,
        parseEther(amount.toString()),
      )
      return tx
    },
    [stakingContract],
  )

  const unstakeToken = useCallback(
    async (token: string, amount: string) => {
      const tokenId =
        token === 'asto'
          ? StakingContractTokenId.ASTO
          : StakingContractTokenId.LP
      const tx = await stakingContract.unstake(
        tokenId,
        parseEther(amount.toString()),
      )
      return tx
    },
    [stakingContract],
  )

  // withdraw LP auction participation
  const withdrawLpParticipation = useCallback(async () => {
    const tx = await lbaContract.claimLPToken()
    return tx
  }, [lbaContract])
  // amount ASTO energy generated

  const amountAstoEnergyGeneratedQuery = useQuery(
    ['asto-generated', 'balance', account],
    async () => {
      if (!account) {
        return
      }

      const [totalGenerated, totalGeneratedFromAsto, totalGeneratedFromLp] =
        await Promise.all([
          energyConverterContract.getEnergy(account),
          energyConverterContract.calculateAstoEnergy(account),
          energyConverterContract.calculateLpEnergy(account),
        ])

      return {
        totalGenerated: formatUnits(totalGenerated, 36),
        totalGeneratedFromAsto: formatUnits(totalGeneratedFromAsto, 36),
        totalGeneratedFromLp: formatUnits(totalGeneratedFromLp, 36),
      }
    },
  )

  const astoAuctionParticipationQuery = useQuery(
    ['asto-auction-participation', 'balance', account],
    async () => {
      if (!account) {
        return
      }

      const [dailyEnergyProduction, totalEnergyProduction, lbaAmount] =
        await Promise.all([
          energyConverterContract.getDailyLBAEnergyProduction(account),
          energyConverterContract.calculateAvailableLBAEnergy(account),
          lbaContract.claimableLPAmount(account),
        ])

      return {
        lbaAmount: formatUnits(lbaAmount, 18),
        dailyEnergyProduction: formatUnits(dailyEnergyProduction, 36),
        totalEnergyProduction: formatUnits(totalEnergyProduction, 36),
      }
    },
  )

  const dailyEnergyProductionQuery = useQuery(
    ['asto-energy', 'production-daily', account],
    async () => {
      if (!account) return
      const [
        dailyASTOEnergyProduction,
        dailyLBAEnergyProduction,
        dailyLPEnergyProduction,
      ] = await Promise.all([
        energyConverterContract.getDailyASTOEnergyProduction(account),
        energyConverterContract.getDailyLBAEnergyProduction(account),
        energyConverterContract.getDailyLPEnergyProduction(account),
      ])
      const dailyTotalEnergyProduction = dailyASTOEnergyProduction
        .add(dailyLBAEnergyProduction)
        .add(dailyLPEnergyProduction)

      return {
        dailyTotalEnergyProduction: formatUnits(dailyTotalEnergyProduction, 36),
        dailyASTOEnergyProduction: formatUnits(dailyASTOEnergyProduction, 36),
        dailyLBAEnergyProduction: formatUnits(dailyLBAEnergyProduction, 36),
        dailyLPEnergyProduction: formatUnits(dailyLPEnergyProduction, 36),
      }
    },
    { enabled: !!account },
  )

  const totalAstoEnergyDailyProduction =
    dailyEnergyProductionQuery.data?.dailyTotalEnergyProduction || '0'
  const totalLpAstoEnergyGeneratedDaily =
    dailyEnergyProductionQuery.data?.dailyLPEnergyProduction || '0'

  const astoAllowanceQuery = useQuery(
    ['staking', 'asto', 'allowance', account],
    async () => {
      if (!account) return BigNumber.from(0)
      const allowance = await astoContract.allowance(
        account,
        stakingContract.address,
      )
      return allowance
    },
    { enabled: !!account, placeholderData: BigNumber.from(0) },
  )

  const approveAstoStakingContract = useMutation(
    async () => {
      const tx = await astoContract.approve(
        stakingContract.address,
        constants.MaxUint256,
      )
      await tx.wait()
      return true
    },
    {
      onSuccess: () => {
        astoAllowanceQuery.refetch()
      },
    },
  )

  const lpAllowanceQuery = useQuery(
    ['staking', 'lp', 'allowance', account],
    async () => {
      if (!account) return BigNumber.from(0)
      const allowance: BigNumber = await lpTokenContract.allowance(
        account,
        stakingContract.address,
      )
      return allowance
    },
    { enabled: !!account, placeholderData: BigNumber.from(0) },
  )

  const approveLpStakingContract = useMutation(
    async () => {
      const tx = await lpTokenContract.approve(
        stakingContract.address,
        constants.MaxUint256,
      )
      await tx.wait()
      return true
    },
    {
      onSuccess: () => {
        lpAllowanceQuery.refetch()
      },
    },
  )

  const currentTimeQuery = useQuery(
    ['current-time'],
    async () => {
      const currentTime = await energyConverterContract.currentTime()
      return currentTime.toNumber() * 1000
    },
    { placeholderData: 0 },
  )

  const consumedEnergyQuery = useQuery(
    ['energy', 'consumed', account],
    async () => {
      if (!account) return '0'
      const consumedEnergyP = energyConverterContract.getConsumedEnergy(account)
      const consumedLBAEnergyP =
        energyConverterContract.getConsumedLBAEnergy(account)

      const [consumedEnergyBN, consumedLBAEnergyBN] = await Promise.all([
        consumedEnergyP,
        consumedLBAEnergyP,
      ])
      const totalConsumedEnergyBN = consumedEnergyBN.add(consumedLBAEnergyBN)
      const consumedEnergy = formatUnits(totalConsumedEnergyBN, 36)

      return consumedEnergy
    },
    { enabled: !!account },
  )

  return {
    stakingBalanceAsto,
    amountAstoEnergyGeneratedQuery,
    astoAuctionParticipationQuery,
    totalAstoEnergyDailyProduction,
    currentTimeQuery,
    stakeToken,
    unstakeToken,
    stakingHistoryAsto,
    astoAllowanceQuery,
    approveAstoStakingContract,
    stakingHistoryLp,
    stakingBalanceLp,
    withdrawLpParticipation,
    lpAllowanceQuery,
    approveLpStakingContract,
    totalLpAstoEnergyGeneratedDaily,
    consumedEnergyQuery,
    dailyEnergyProductionQuery,
  }
}
