import { css, styled } from '@mui/material/styles'
import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from '@mui/material'

export type { ButtonProps } from '@mui/material'

const StyledCircularProgress = styled(CircularProgress)(
  ({ theme }) => css`
    z-index: 100;
    color: #fff;
    position: absolute;
    left: calc(50% - 12px);

    ${theme.breakpoints.down('sm')} {
      top: -6px;
    }
  `
)

export const Button = <C extends React.ElementType>({
  to,
  children,
  loading = false,
  rounded = false,
  disabled = false,
  ...props
}: ButtonProps<C, { component?: C }>) => {
  return (
    <MuiButton
      to={to}
      role={to && 'link'}
      variant="contained"
      sx={rounded ? { borderRadius: '999px' } : undefined}
      disabled={loading || disabled}
      {...props}
    >
      {children}
      {loading && <StyledCircularProgress size={30} />}
    </MuiButton>
  )
}

export default Button
