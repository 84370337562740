import create from 'zustand'

interface UseLBAMigrationPopupProps {
  open: boolean
  closeLBAMigrationPopup: () => void
  openLBAMigrationPopup: () => void
}

export const useLBAMigrationPopup = create<UseLBAMigrationPopupProps>(set => ({
  open: false,
  closeLBAMigrationPopup: () => set({ open: false }),
  openLBAMigrationPopup: () => set({ open: true }),
}))
