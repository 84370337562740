/* eslint-disable camelcase */
import { useMemo } from 'react'

import { isMainnet, ChainENVs, CHAIN_ENV } from 'src/utils/web3'
import { useWeb3LibraryProvider } from '../useWeb3LibraryProvider'
import { LiquidityBootstrapAuction__factory } from 'smart-contracts/gen/factories/LiquidityBootstrapAuction__factory'
import { LiquidityBootstrapAuctionTest__factory } from 'smart-contracts/gen/factories/LiquidityBootstrapAuctionTest__factory'

import localhostABI from 'smart-contracts/abi/localhost.json'
import mainnetABI from 'smart-contracts/abi/mainnet.json'
import goerliStagingABI from 'smart-contracts/abi/goerli-staging.json'
import goerliABI from 'smart-contracts/abi/goerli.json'
import kovanABI from 'smart-contracts/abi/kovan.json'

export const addresses: Record<ChainENVs, string> = {
  localhost: localhostABI.contracts.LiquidityBootstrapAuctionTest.address,
  mainnet: mainnetABI.contracts.LiquidityBootstrapAuction.address,
  goerliStaging:
    goerliStagingABI.contracts.LiquidityBootstrapAuctionTest.address,
  goerli: goerliABI.contracts.LiquidityBootstrapAuctionTest.address,
  kovan: kovanABI.contracts.LiquidityBootstrapAuctionTest.address,
}

const address = addresses[CHAIN_ENV]
// Use test factory on envs other than mainnet
const factory = isMainnet
  ? LiquidityBootstrapAuction__factory
  : LiquidityBootstrapAuctionTest__factory

export const useLBAContract = () => {
  const rpcProvider = useWeb3LibraryProvider()

  const contract = useMemo(
    () => factory.connect(address, rpcProvider),
    [rpcProvider],
  )

  return contract
}
