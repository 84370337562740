import { Web3Provider } from '@ethersproject/providers'

export enum ChainENVs {
  Mainnet = 'mainnet',
  goerliStaging = 'goerliStaging',
  Goerli = 'goerli',
  Localhost = 'localhost',
  Kovan = 'kovan',
}

export const CHAIN_ENV = process.env.REACT_APP_CHAIN_ENV as ChainENVs
export const EARLIEST_BLOCK = process.env.EARLIEST_BLOCK || 'earliest'

export const isMainnet = CHAIN_ENV === ChainENVs.Mainnet

export const ChainENVToID: Record<ChainENVs, number> = {
  mainnet: 1,
  goerliStaging: 5,
  goerli: 5,
  localhost: 31337,
  kovan: 42,
}

export const CHAIN_ID = ChainENVToID[CHAIN_ENV]

export enum Chains {
  Mainnet = 'mainnet',
  Goerli = 'goerli',
  Localhost = 'localhost',
  Kovan = 'kovan',
}

export const ChainIdToName: Record<number, Chains> = {
  1: Chains.Mainnet,
  5: Chains.Goerli,
  31337: Chains.Localhost,
  42: Chains.Kovan,
}

export const CHAIN_NAME = ChainIdToName[CHAIN_ID]
export const POLLING_INTERVAL = 12000

const url: Record<number, string> = {
  1: '',
  5: 'goerli.',
  31337: '',
  42: 'kovan.',
}

export const getEtherscanUrl = (): string =>
  `https://${url[CHAIN_ID] ?? ''}etherscan.io`

export const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL

  return library
}
