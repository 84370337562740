import config from 'src/utils/config'
import { GenerateLbaMigrationTokenResponse } from 'common/src/types/apis/generateSignature'

export const getSignature = async ({
  account,
  throwOnError,
}: {
  account: string
  throwOnError?: boolean
}) => {
  try {
    const res = await fetch(config.REACT_APP_LBA_MIGRATION_API_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        walletAddress: account,
      }),
    })
    const { data } = (await res.json()) as GenerateLbaMigrationTokenResponse
    return data
  } catch (e) {
    if (throwOnError) {
      throw new Error('Error getting signature')
    }
    return undefined
  }
}
