import { createTheme, PaletteOptions } from '@mui/material/styles'
import { alpha, darken } from '@mui/material'
import type {} from '@mui/x-data-grid/themeAugmentation'

import {
  gilroy,
  gilroyBold,
  gilroyThin,
  gilroyLight,
  gilroyItalic,
  gilroyMedium,
  gilroyRegular,
  gilroySemiBold,
  adieu,
  adieuBold,
  adieuLight,
  adieuItalic,
  adieuRegular,
} from './fonts'

const gilroyFontFamily = `${gilroy}, Roboto, Helvetica, Arial, sans-serif`
const adieuFontFamily = `${adieu}, Roboto, Helvetica, Arial, sans-serif`

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightSemiBold = 600
const fontWeightBold = 700

export const palette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: '#7C6EFB',
    dark: '#B7AFFD', // tint for dark mode
    '900': '#4B37FF',
    '800': '#5B49FF',
    '700': '#6D5CFF',
    '600': '#7C6EFB', // main
    '500': '#8477F6',
    '400': '#8B7FFC',
    '300': '#9A8FFC',
    '200': '#B7AFFD', // dark
    '100': '#C5BFFD',
    '50': '#282440',
  },
  secondary: {
    main: '#FCC4E4',
    dark: '#FDE2F2', // tint for dark mode
    '900': '#FF69C0',
    '800': '#FF8CCF',
    '700': '#FFACD8',
    '600': '#FCC4E4', // main
    '500': '#FFC9E8',
    '400': '#FCCFE9',
    '300': '#FDD8ED',
    '200': '#FDE2F2', // dark
    '100': '#FEECF6',
    '50': '#FEF5FB',
  },
  grey: {
    '900': '#18171D',
    '800': '#1F1F25',
    '700': '#333339',
    '600': '#4A494F',
    '500': '#626268',
    '400': '#807F85',
    '300': '#99989E',
    '200': '#B3B3B9',
    '100': '#D0CFD5',
    '50': '#EEEEF4',
  },
  gradientSingle: {
    primary: '#FEE8CE',
    secondary: '#E6A8E2',
    tertiary: '#5F7FC1',
    dark: '#0C0C0E',
  },
  gradients: {
    background:
      'linear-gradient(180deg, #37869B 0%, #B6E9F1 560px, #B6E9F1 100%)',
    dark: 'linear-gradient(180deg, #1A1B29 0%, #27283A 100%)',
    light: 'linear-gradient(180deg, #37869B 0%, #B6E9F1 100%)',
    moody: 'linear-gradient(180deg, #1A1B29 0%, #42435A 100%)',
    teal: 'linear-gradient(180deg, #37869B 0%, rgba(55, 134, 155, 0) 100%)',
    primary: 'linear-gradient(180deg, #8B7AFE 0%, #917EFD 100%)',
    rosePurple:
      'linear-gradient(62deg, #FEE8CE 20%, #E6A8E2 60%, #5F7FC1 100%)',
    rosePurple2:
      'linear-gradient(45deg, #fEECCE 0%, #E6A8E2 50%, #5F7FC1 100%)',
  },
  background: {
    paper: '#fff',
    default: '#fff',
    teal: '#227991',
    dark: '#18171e', // #37869B',
    light: '#ADE2EB',
    darkTeal: '#105365',
    xlight: 'rgba(192, 239, 252, 0.5)',
    purple: '#9C84F6',
  },
  disabled: {
    main: '#73717E',
    light: '#f4f4f4',
  },
  success: {
    main: '#4CAF50',
    light: '#CFFFD2',
  },
  warning: {
    main: '#FF9800',
    light: '#fffaea',
  },
  error: {
    main: '#F44336',
    light: '#FBE3EE',
  },
  text: {
    primary: '#000',
    secondary: '#fff',
    mid: '#42435A',
    grey: '#99989E', // grey.300
  },
  divider: '#dcdcdc',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    error: '#D2003C',
    success: '#84D584',
  },
}

const theme = createTheme({
  palette,
  typography: {
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
  },
})

theme.shadows[1] = '0px 0px 20px rgba(0, 0, 0, 0.15)'

// We can only use typography function such as pxToRem after the createMuiTheme
theme.typography = {
  ...theme.typography,
  fontWeightBold,
  fontWeightLight,
  fontWeightMedium,
  fontWeightRegular,
  fontWeightSemiBold,
  fontFamily: gilroyFontFamily,
  fontFamilySecondary: adieuFontFamily,
  h1: {
    ...theme.typography.h1,
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(50),
    lineHeight: theme.typography.pxToRem(72),
    letterSpacing: theme.typography.pxToRem(0),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(48),
    },
  },
  h2: {
    ...theme.typography.h2,
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(40),
    lineHeight: theme.typography.pxToRem(54),
    letterSpacing: theme.typography.pxToRem(0),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(32),
      lineHeight: theme.typography.pxToRem(42),
    },
  },
  h3: {
    ...theme.typography.h3,
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  h4: {
    ...theme.typography.h4,
    fontWeight: fontWeightBold,
    fontFamily: gilroyFontFamily,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: theme.typography.pxToRem(28),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  h5: {
    ...theme.typography.h5,
    fontWeight: fontWeightBold,
    fontFamily: gilroyFontFamily,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  h6: {
    ...theme.typography.h6,
    fontWeight: fontWeightBold,
    fontFamily: gilroyFontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(12),
  },
  subtitle1: {
    ...theme.typography.subtitle1,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(28),
    letterSpacing: theme.typography.pxToRem(0.15),
    fontFamily: gilroyFontFamily,
  },
  subtitle2: {
    ...theme.typography.subtitle2,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    letterSpacing: theme.typography.pxToRem(0.1),
    fontWeight: fontWeightBold,
    fontFamily: gilroyFontFamily,
  },
  body1: {
    ...theme.typography.body1,
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(24),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  body2: {
    ...theme.typography.body2,
    fontWeight: fontWeightRegular,
    fontFamily: gilroyFontFamily,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  body3: {
    fontWeight: fontWeightRegular,
    fontFamily: gilroyFontFamily,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
    letterSpacing: theme.typography.pxToRem(0.15),
  },
  button: {
    ...theme.typography.button,
    fontFamily: adieuFontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(26),
  },
  caption: {
    ...theme.typography.caption,
    display: 'block',
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(20),
  },
  captionSmall: {
    ...theme.typography.caption,
    display: 'block',
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightSemiBold,
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(14),
  },
  overline: {
    ...theme.typography.overline,
  },
}

// Overrides are assigned here so we can use theme.breakpoints
// Defaults can be deleted. These are used to test that Storybook Mui theming works

theme.components = {
  MuiCssBaseline: {
    styleOverrides: [
      gilroyBold,
      gilroyThin,
      gilroyLight,
      gilroyItalic,
      gilroyMedium,
      gilroyRegular,
      gilroySemiBold,
      adieuBold,
      adieuLight,
      adieuItalic,
      adieuRegular,
    ].join('\n'),
  },
  MuiContainer: {
    styleOverrides: {
      root: {
        [theme.breakpoints.up('lg')]: {
          maxWidth: 1280,
        },
      },
    },
  },
  MuiListItemText: {},
  MuiButton: {
    defaultProps: {
      variant: 'outlined',
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'unset',
        borderRadius: 8,
        letterSpacing: 0.46,
        padding: '0.5rem 1rem',
      },
      sizeSmall: {
        fontSize: theme.typography.pxToRem(10),
        lineHeight: theme.typography.pxToRem(22),
        height: theme.typography.pxToRem(30),
        fontWeight: fontWeightBold,
      },
      sizeMedium: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(24),
        height: theme.typography.pxToRem(38),
      },
      sizeLarge: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(26),
        height: theme.typography.pxToRem(42),
      },
      contained: {
        '&:hover': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.22), rgba(0, 0, 0, 0.22)), ${theme.palette.primary.main}`,
        },

        '&:active': {
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.45), rgba(0, 0, 0, 0.45)),  ${theme.palette.primary.main}`,
        },
      },
      outlined: {
        borderWidth: '2px !important',
      },
      outlinedPrimary: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,

        '&:hover': {
          background: 'rgba(124, 110, 251, 0.18)',
        },

        '&:active': {
          background: 'rgba(124, 110, 251, 0.4)',
        },
      },
      text: {
        border: '1px solid transparent !important',
      },
      textPrimary: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '&:hover': {
          background: 'rgba(124, 110, 251, 0.08)',
        },
      },
      textSecondary: { color: theme.palette.grey['300'] },
      startIcon: {
        marginRight: 12,
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: 4,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.25)',
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        fontWeight: fontWeightBold,
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
        fontFamily: theme.typography.fontFamily,
        letterSpacing: 0.4,
        color: theme.palette.text.grey,
        textDecorationColor: theme.palette.text.grey,
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: { borderRadius: 12 },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        maxWidth: 700,
        borderRadius: 8,
        backgroundImage: 'none',
        backgroundColor: theme.palette.grey[900],
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(1.5),
        },
      },
      paperWidthSm: {
        width: '100%',
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: theme.spacing(6, 5),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(3, 2),
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      elevation: 1,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    },
    styleOverrides: {
      paper: {
        marginTop: 6,
        borderRadius: '6px !important',
      },
      list: {
        backgroundColor: theme.palette.grey[800],
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        padding: 12,
        color: 'white',
        justifyContent: 'center',
        fontSize: theme.typography.pxToRem(12),
        fontFamily: theme.typography.fontFamilySecondary,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      elevation: 1,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        width: 1280,
        maxWidth: '96%',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '98%',
        },
      },
    },
  },
  MuiTooltip: {
    defaultProps: {
      arrow: true,
      // Tap to open tooltip on touch devices
      enterTouchDelay: 0,
    },
    styleOverrides: {
      tooltip: {
        background: theme.palette.disabled.main,
        ...theme.typography.captionSmall,
      },
      arrow: {
        width: '2em',
        color: theme.palette.disabled.main,
        height: '1.4em',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        fontSize: theme.typography.pxToRem(16),
        lineHeight: theme.typography.pxToRem(24),
        letterSpacing: 0.15,
      },
    },
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(12),
        letterSpacing: 0.15,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: theme.typography.pxToRem(12),
        lineHeight: theme.typography.pxToRem(20),
        letterSpacing: 0.4,
      },
    },
  },
  MuiLinearProgress: {
    defaultProps: { variant: 'determinate' },
    styleOverrides: {
      root: {
        width: '100%',
        borderRadius: '999px',
        backgroundColor: alpha(theme.palette.disabled.main, 0.3),
      },
      bar: { borderRadius: '999px' },
      barColorPrimary: {
        background: theme.palette.gradients.rosePurple,
      },
      bar2Indeterminate: {
        background: 'pink',
      },
      indeterminate: {
        backgroundColor: theme.palette.primary.light,
        barColorPrimary: {
          background: `linear-gradient(
            90deg,
            transparent 5%,
            #fee8ce 25%,
            #e6a8e2 60%,
            #5f7fc1 95%,
            transparent
          )`,
        },
      },
    },
  },
  MuiAlert: {
    styleOverrides: {
      root: {
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
      },
      message: {
        fontSize: theme.typography.pxToRem(14),
        lineHeight: theme.typography.pxToRem(20),
        fontWeight: theme.typography.fontWeightRegular,
      },
      action: { padding: 0, marginRight: 0 },
      filledInfo: {
        backgroundColor: theme.palette.primary.main,
      },
      icon: {
        display: 'flex',
        alignItems: 'center',
        width: '20px',
        height: '20px',
      },
      outlinedInfo: {
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
        '.MuiAlert-icon': {
          color: theme.palette.primary.main,
        },
      },
      outlinedSuccess: {
        color: theme.palette.success.main,
        borderColor: theme.palette.success.main,
        '.MuiAlert-icon': {
          color: theme.palette.success.main,
        },
      },
      outlinedWarning: {
        color: theme.palette.warning.main,
        borderColor: theme.palette.warning.main,
        '.MuiAlert-icon': {
          color: theme.palette.warning.main,
        },
      },
      outlinedError: {
        color: theme.palette.error.main,
        borderColor: theme.palette.error.main,
        '.MuiAlert-icon': {
          color: theme.palette.error.main,
        },
      },
      standardInfo: {
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: darken(theme.palette.primary.main, 0.6),
        '.MuiAlert-icon': {
          color: theme.palette.primary.main,
        },
      },
      standardError: {
        backgroundColor: alpha(theme.palette.error.main, 0.1),
        color: darken(theme.palette.error.main, 0.6),
        '.MuiAlert-icon': {
          color: theme.palette.error.main,
        },
      },
      standardWarning: {
        backgroundColor: alpha(theme.palette.warning.main, 0.1),
        color: darken(theme.palette.warning.main, 0.6),
        '.MuiAlert-icon': {
          color: theme.palette.warning.main,
        },
      },
      standardSuccess: {
        backgroundColor: alpha(theme.palette.success.main, 0.1),
        color: darken(theme.palette.success.main, 0.6),
        '.MuiAlert-icon': {
          color: theme.palette.success.main,
        },
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      track: {
        backgroundColor: theme.palette.grey['500'],
        borderRadius: '99px',
        opacity: 1,
      },
      root: {
        padding: '0px',
        '& .MuiSwitch-switchBase': {
          padding: 0,
          margin: '2px',
        },
        '& .Mui-checked.MuiSwitch-switchBase': {
          '+ .MuiSwitch-track': {
            backgroundColor: 'rgba(56, 50, 113, 1)',
            opacity: 1,
          },
        },
      },
      sizeMedium: {
        width: '38px',
        height: '20px',
        input: {
          left: `${0 - 38}px`,
          width: `${2 * 38}px`,
        },
        '& .MuiSwitch-thumb': { width: '16px', height: '16px' },
        '& .Mui-checked.MuiSwitch-switchBase': {
          '&.MuiButtonBase-root': {
            transform: 'translateX(18px)',
          },
        },
      },
      sizeSmall: {
        width: '30px',
        height: '16px',
        input: { left: `${0 - 30}px`, width: `${2 * 30}px` },
        '& .MuiSwitch-thumb': {
          width: '12px',
          height: '12px',
        },
        '& .Mui-checked.MuiSwitch-switchBase': {
          '&.MuiButtonBase-root': {
            transform: 'translateX(14px)',
          },
        },
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        color: theme.palette.text.grey,
        li: {
          display: 'flex',
          alignItems: 'center',
          '& .MuiButtonBase-root': {
            color: theme.palette.text.secondary,
            backgroundColor: 'transparent',
            '&:hover': {
              backgroundColor: 'transparent',
              color: theme.palette.text.grey,
            },
          },
        },
      },
      separator: { fontSize: '10px' },
      ol: {
        'li:nth-last-of-type(2)': {
          color: theme.palette.text.secondary,
          fontSize: '14px',
        },
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paper: {
        width: 230,
        backgroundImage: 'none',
        backgroundColor: theme.palette.background.dark,
      },
    },
  },
  MuiChip: {
    defaultProps: { color: 'primary', size: 'medium' },
    styleOverrides: {
      root: {
        borderRadius: '16px',
        fontSize: '12px',
        fontWeight: fontWeightSemiBold,
        lineHeight: '20px',
      },
      label: { padding: '0px' },
      sizeSmall: { padding: '3px 8px' },
      sizeMedium: { padding: '7px 10px' },
      colorPrimary: {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      filled: {
        '&.MuiChip-colorPrimary': {
          backgroundColor: theme.palette.primary.main,
        },
        '&.MuiChip-colorSecondary': {
          border: `1px solid ${theme.palette.grey['100']}`,
          background: theme.palette.grey['100'],
          color: theme.palette.text.primary,
        },
      },
      outlinedPrimary: {
        border: `1px solid ${theme.palette.primary.main}`,
      },
      outlinedSecondary: {
        border: `1px solid ${theme.palette.disabled.main}`,
        color: theme.palette.disabled.main,
      },
      deleteIcon: {
        transition: 'color 0.15s',
      },
      deleteIconSmall: {
        width: '16px',
        height: '16px',
        marginLeft: '6px',
        marginRight: '-5px',
      },
      deleteIconMedium: {
        margin: '0px',
        marginLeft: '6px',
        marginRight: '-6px',
      },
      deleteIconColorSecondary: {
        color: theme.palette.grey['200'],
        '&:hover': {
          color: theme.palette.grey['100'],
        },
      },
    },
  },

  MuiDataGrid: {
    styleOverrides: {
      root: {
        fontSize: 16,
        borderWidth: 0,
      },
      // TODO: needed if you want ordering etc - consider putting in own variant
      columnSeparator: {
        display: 'none',
      },
      menu: {
        display: 'none',
      },
      menuIcon: {
        display: 'none',
      },
      columnHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeightBold,
        paddingLeft: theme.spacing(3),
      },
      cell: {
        backgroundColor: theme.palette.grey['800'],
        color: theme.palette.grey['400'],
        borderColor: theme.palette.primary['50'],
        paddingLeft: theme.spacing(3),
      },
    },
  },
  MuiTablePagination: {
    styleOverrides: {
      root: {
        color: theme.palette.grey['400'],
      },
    },
  },
  MuiSkeleton: {
    defaultProps: {
      variant: 'rectangular',
    },
    styleOverrides: {
      root: {
        borderRadius: 6,
        backgroundColor: theme.palette.grey[800],
      },
    },
  },
}

export default theme
