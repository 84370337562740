/* eslint-disable camelcase */
import { useMemo } from 'react'

import { ChainENVs, CHAIN_ENV } from 'src/utils/web3'
import { useWeb3LibraryProvider } from '../useWeb3LibraryProvider'
import { ASTOToken__factory } from 'smart-contracts/gen/factories/ASTOToken__factory'

import localhostABI from 'smart-contracts/abi/localhost.json'
import mainnetABI from 'smart-contracts/abi/mainnet.json'
import goerliStagingABI from 'smart-contracts/abi/goerli-staging.json'
import goerliABI from 'smart-contracts/abi/goerli.json'
import kovanABI from 'smart-contracts/abi/kovan.json'

export const addresses: Record<ChainENVs, string> = {
  localhost: localhostABI.contracts.ASTOTokenTest.address,
  mainnet: mainnetABI.contracts.ASTOToken.address,
  goerliStaging: goerliStagingABI.contracts.ASTOTokenTest.address,
  goerli: goerliABI.contracts.ASTOTokenTest.address,
  kovan: kovanABI.contracts.ASTOTokenTest.address,
}

export const address = addresses[CHAIN_ENV]

const factory = ASTOToken__factory

const useASTOTokenContract = () => {
  const rpcProvider = useWeb3LibraryProvider()

  const contract = useMemo(
    () => factory.connect(addresses[CHAIN_ENV], rpcProvider),
    [rpcProvider],
  )

  return contract
}

export default useASTOTokenContract
