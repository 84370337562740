import numeral from 'numeral'

const fillChars = (char: string, times: number) => {
  let val = ''
  for (let i = 0; i < times; i++) {
    val += char
  }

  return val
}

export const trimTrailingInsignificationZeros = (str: string) => {
  const parts = str.split('.')
  const decimalPart = parts[1] ?? ''
  if (decimalPart.length === 0) return parts[0]

  const arr = decimalPart.split('')
  while (arr[arr.length - 1] === '0') {
    arr.pop()
  }

  if (arr.length === 0) return parts[0]

  return `${parts[0]}.${arr.join('')}`
}

export const formatBalance = (balance: number, dp: number = 0) => {
  const num = numeral(balance)
  if (balance > 0 && balance < 100000) {
    const decimalPadding = dp === 0 ? '' : `.${fillChars('0', dp)}`
    const result = num.format(`0,0${decimalPadding}`)
    return trimTrailingInsignificationZeros(result)
  }

  return num.format('0,0')
}

export const formatPercentage = (x: number, dp: number = 2) =>
  isNaN(x) || !isFinite(x) ? '0' : parseFloat((x * 100).toFixed(dp)).toString()
