import * as D from 'io-ts/Decoder'
import {
  booleanWithDefaultD,
  numberWithDefaultD,
} from 'common/src/types/default'
import { getEnvOrThrow } from 'common/src/env'

const config = getEnvOrThrow({
  REACT_APP_FEATURE_FLAG_ALLSTAR_CLAIM: booleanWithDefaultD(false),
  REACT_APP_FEATURE_FLAG_GENOME_MINING_MINT_PAGE: booleanWithDefaultD(false),
  REACT_APP_GENOME_MINING_TOTAL_ENERGY_API_URL: D.string,
  REACT_APP_GENOME_MINING_TOTAL_ENERGY_FALLBACK_AMOUNT:
    numberWithDefaultD(3217450123),
  REACT_APP_GENOME_MINING_GQL_API_URL: D.string,
  REACT_APP_OPENSEA_GEN_II_COLLECTION_NAME: D.string,
  REACT_APP_OPENSEA_URL_BASE: D.string,
  REACT_APP_IPFS_URL_BASE: D.string,
  REACT_APP_MAP_REVEAL_REFRESH_INTERVAL: numberWithDefaultD(1800000),
  REACT_APP_LBA_MIGRATION_API_URL: D.string,
  REACT_APP_VOID_PROTOCOL_SIGNATURE_URL: D.string,
})

export default config
