import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWC,
} from '@web3-react/walletconnect-connector'
import { formatEther } from 'ethers/lib/utils'
import { captureException } from '@sentry/react'
import { AbstractConnector } from '@web3-react/abstract-connector'

import {
  connectors,
  ConnectorNames,
  setConnectorToStorage,
  removeConnectorFromStorage,
} from 'src/utils/connectors'
import { CHAIN_NAME } from 'src/utils/web3'
import { useWeb3React } from 'src/hooks/useWeb3React'
import { useAlertbar } from 'src/hooks/store/useAlertbar'
import { useRpcUrlResolution } from 'src/hooks/useRPCUrl'
import useASTOTokenContract from 'src/hooks/contracts/useASTOTokenContract'

type WalletContextType = {
  astoBalance: number
  disconnectWallet: () => void
  getAstoBalance: () => Promise<void>
  connectWallet: (connectorName: ConnectorNames) => void
}

const WalletContext = createContext<WalletContextType>({
  astoBalance: 0,
  getAstoBalance: () => {
    throw new Error('Cannot getAstoBalance outside of <WalletProvider />')
  },
  connectWallet: () => {
    throw new Error('Cannot connectWallet outside of <WalletProvider />')
  },
  disconnectWallet: () => {
    throw new Error('Cannot disconnectWallet outside of <WalletProvider />')
  },
})

export const WalletProvider: React.FC = ({ children }) => {
  const [astoBalance, setAstoBalance] = useState(0)

  const rpcUrl = useRpcUrlResolution()
  const { openAlertbar } = useAlertbar()
  const astoContract = useASTOTokenContract()
  const { account, activate, deactivate } = useWeb3React()
  const [connector, setConnector] = useState<null | AbstractConnector>(null)

  const connectWallet = useCallback(
    (connectorName: ConnectorNames) => {
      setConnectorToStorage(connectorName)

      if (rpcUrl.type !== 'resolved') {
        openAlertbar({
          message: 'RPC Urls not resolved',
          severity: 'error',
        })

        return captureException(new Error('RPC Urls not resolved'))
      }

      const connector = connectors(rpcUrl.url, connectorName)

      if (connector) {
        setConnector(connector)
        activate(connector, async (error: Error) => {
          removeConnectorFromStorage()

          if (error instanceof NoEthereumProviderError) {
            openAlertbar({
              message: 'No provider was found',
              severity: 'error',
            })
          } else if (
            error instanceof UserRejectedRequestErrorWC ||
            error instanceof UserRejectedRequestErrorInjected ||
            // For wallet-link connector
            error.message === 'User denied account authorization'
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            openAlertbar({
              severity: 'error',
              message: 'Please authorise to connect wallet',
            })
          } else if (error.message.includes('Unsupported chain id')) {
            openAlertbar({
              severity: 'error',
              message: `Sorry, human error, please change wallet to Ethereum ${CHAIN_NAME}`,
            })
          } else {
            openAlertbar({
              message: error.message,
              severity: 'error',
            })
            captureException(error)
          }
        })
      } else {
        openAlertbar({ message: 'Unable to find connector', severity: 'error' })
      }
    },
    [activate, openAlertbar, rpcUrl, setConnector],
  )

  const disconnectWallet = useCallback(() => {
    deactivate()

    // This key is set by @web3-react/walletconnect-connector
    if (localStorage.getItem('walletconnect') && connector) {
      const walletconnect = connector as WalletConnectConnector

      walletconnect.close()
      walletconnect.walletConnectProvider = null
    }

    removeConnectorFromStorage()
  }, [deactivate, connector])

  const getAstoBalance = useCallback(async () => {
    if (account) {
      try {
        const astoBigNum = await astoContract.balanceOf(account)
        const balance = parseInt(formatEther(astoBigNum))

        setAstoBalance(balance)
      } catch (error) {
        setAstoBalance(0)
        openAlertbar({
          severity: 'error',
          message: 'Unable to get ASTO Token balance',
        })
        captureException(error)
      }
    } else {
      setAstoBalance(0)
    }
  }, [account, astoContract, openAlertbar])

  useEffect(() => {
    getAstoBalance()
  }, [getAstoBalance])

  return (
    <WalletContext.Provider
      value={{
        astoBalance,
        connectWallet,
        getAstoBalance,
        disconnectWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export const useWallet = () => useContext<WalletContextType>(WalletContext)
