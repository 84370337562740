export const formatAccount = (account: string | null | undefined) =>
  account
    ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
    : ''

export const zeroPad = (num: number, places: number = 5) =>
  String(num).padStart(places, '0')

export const delay = (ms: number) =>
  new Promise(resolve => setTimeout(resolve, ms))
