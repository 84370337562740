import { Box, Typography } from '@mui/material'
import Select from 'component-library/src/components/select/Select'
import SelectItem from 'component-library/src/components/select/SelectItem'
import useInventoryStore, { sortOptions } from '../hooks/useInventoryStore'

export const SortingSelect = () => {
  const { sortValue, changeSort } = useInventoryStore(state => ({
    sortValue: state.sortValue,
    changeSort: state.changeSort,
  }))
  return (
    <Select
      sx={{ textAlign: 'left', width: '235px' }}
      defaultValue={'1'}
      value={sortValue}
      renderValue={value => {
        return (
          <Box
            sx={{ display: 'flex', gap: 1 }}
            alignItems="center"
            alignContent="center"
          >
            <Typography color="grey.200" variant="body2">
              Sort by:
            </Typography>{' '}
            {sortOptions.get(value as string)}
          </Box>
        )
      }}
      onChange={event => {
        changeSort(event.target.value as string)
      }}
    >
      {Array.from(sortOptions).map(([key, value]) => (
        <SelectItem key={key} value={key}>
          {value}
        </SelectItem>
      ))}
    </Select>
  )
}
