import { Helmet } from 'react-helmet'
import { Typography } from '@mui/material'
import { css, styled } from '@mui/material/styles'

import Footer from 'src/components/footer'
import ProjectionTooltip from 'src/modules/lunaris-machine/ProjectionTooltip'

const Container = styled('div')(
  ({ theme }) => css`
    flex: 1;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    padding: ${theme.spacing(3)};

    ${theme.breakpoints.down('sm')} {
      padding: ${theme.spacing(1)};
    }
  `,
)

const Header = styled('header')(
  ({ theme }) => css`
    margin: ${theme.spacing(0, 0, 6)};

    ${theme.breakpoints.down('sm')} {
      margin: ${theme.spacing(0, 0, 4)};
    }
  `,
)

const Content = styled('div')`
  flex: 1;
  position: relative;
`

export interface ScreenLayoutProps {
  title: string
  shouldHideTitle?: boolean
  description: string
  titleVariant?: 'h1' | 'h2' | 'h3'
  tooltip?: string
}

const ScreenLayout: React.FC<ScreenLayoutProps> = ({
  title,
  shouldHideTitle = false,
  children,
  description,
  titleVariant = 'h3',
  tooltip,
  ...props
}) => {
  return (
    <Container {...props}>
      <Helmet>
        <title>{title} | ASM</title>
        <meta name="description" content={description} />
      </Helmet>
      <Header data-cy="app-header">
        {!shouldHideTitle && (
          <Typography
            variant={titleVariant}
            component="h1"
            color="text.secondary"
            sx={{
              fontFamily: 'fontFamilySecondary',
              fontWeight: '400',
            }}
          >
            {title}{' '}
            {tooltip && <ProjectionTooltip>{tooltip}</ProjectionTooltip>}
          </Typography>
        )}
        <Typography color="grey.200" variant="body2">
          {description}
        </Typography>
      </Header>
      <Content>{children}</Content>
      <Footer />
    </Container>
  )
}

export default ScreenLayout
