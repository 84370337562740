import { pipe } from 'fp-ts/function'
import * as Codec from 'io-ts/Codec'
import * as Decoder from 'io-ts/Decoder'

export const ISOStringDateD: Decoder.Decoder<unknown, Date> = pipe(
  Decoder.string,
  Decoder.map(str => str.trim()),
  Decoder.parse(str => {
    const date = new Date(str)
    if (Number.isNaN(date.getTime)) {
      return Decoder.failure(str, 'ISO Date')
    }

    return Decoder.success(date)
  }),
)

export const ISOStringDateC: Codec.Codec<unknown, string, Date> = Codec.make(
  ISOStringDateD,
  { encode: (date: Date) => date.toISOString() },
)
