import * as D from 'io-ts/Decoder'
import { pipe } from 'fp-ts/function'

import { BooleanFromStringD } from './boolean'
import { ISOStringDateD } from './date'
import { NumberFromStringD } from './number'

export const withDefaultD =
  <T>(decoder: D.Decoder<unknown, T>) =>
  <T>(defaultValue: T) =>
    pipe(
      D.nullable(decoder),
      D.map(r => r ?? defaultValue),
    )

export const booleanWithDefaultD = withDefaultD(BooleanFromStringD)
export const numberWithDefaultD = withDefaultD(NumberFromStringD)
export const dateWithDefaultD = withDefaultD(ISOStringDateD)
