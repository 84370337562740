import { Typography, IconButton } from '@mui/material'
import { styled, css } from '@mui/material/styles'
import { useCallback } from 'react'

import useStatusbar from 'src/hooks/store/useStatusbar'

import { ReactComponent as InfoIcon } from 'component-library/src/assets/icons/info.svg'
import { ReactComponent as CloseIcon } from 'component-library/src/assets/icons/close.svg'

const Alertbar = styled('div')(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    background-color: ${theme.palette.primary.main};
    color: white;
    padding: 8px 12px;
    .MuiTypography-root {
      margin: 0px 12px;
    }

    .MuiIconButton-root {
      :hover {
        background-color: transparent;
        opacity: 80%;
      }
    }

    svg {
      min-width: 24px;
      min-height: 24px;
    }
  `,
)

const TextWrapper = styled('div')`
  flex: 1 0 0;
`

// when required create various styles based on status
const Statusbar = () => {
  const { isStatusbarOpen, message, actionComponent, closeStatusbar } =
    useStatusbar()

  const handleClose = useCallback(() => {
    closeStatusbar()
  }, [closeStatusbar])

  return (
    <>
      {isStatusbarOpen && (
        <Alertbar data-testid="status-bar">
          <InfoIcon />
          <TextWrapper>
            <Typography data-cy="status_bar_message" variant="body2">
              {message}
            </Typography>
          </TextWrapper>
          {actionComponent}
          <IconButton disableRipple onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Alertbar>
      )}
    </>
  )
}

export default Statusbar
