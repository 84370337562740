import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useLBAContract } from './contracts/useLBAContract'

export const useLBA = () => {
  const lbaContract = useLBAContract()
  const lpTokenReleaseTimeQuery = useQuery(
    ['lba', 'lp-token', 'release-time'],
    async () => {
      return lbaContract.lpTokenReleaseTime()
    },
  )

  const currentTimeQuery = useQuery(
    ['lba', 'lp-token', 'current-time'],
    async () => {
      return lbaContract.currentTime()
    },
  )

  const [
    showLBAEventEndingNotificationMessage,
    setShowLBAEventEndingNotificationMessage,
  ] = useState(false)

  useEffect(() => {
    const releaseTime = lpTokenReleaseTimeQuery.data
    const currentTime = currentTimeQuery.data

    setShowLBAEventEndingNotificationMessage(
      !!(
        releaseTime &&
        currentTime &&
        releaseTime.lte(currentTime) &&
        // the message bar will just show in two weeks after the LP token release
        currentTime.sub(releaseTime).lt(2 * 7 * 24 * 60 * 60)
      ),
    )
  }, [lpTokenReleaseTimeQuery.data, currentTimeQuery.data])

  return {
    lpTokenReleaseTimeQuery,
    currentTimeQuery,
    showLBAEventEndingNotificationMessage,
  }
}
