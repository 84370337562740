import { styled } from '@mui/material'
import { Route, Routes } from 'react-router-dom'

import { AssetsPanel } from './assets/AssetsPanel'
import { InventoryControlBar } from './InventoryControlBar'
import { FocusedView } from './FocusedView'
import NotFound from 'src/modules/core/NotFound'
import { Slug } from 'src/utils/routes'

const ConnectedWalletContainer = styled('div')`
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const InventoryView = () => {
  return (
    <>
      <InventoryControlBar />
      <AssetsPanel />
    </>
  )
}

export const ConnectedWallet = () => {
  return (
    <ConnectedWalletContainer data-testid="connected-wallet">
      <Routes>
        <Route index element={<InventoryView />} />
        <Route
          path={`:${Slug.ContractName}/:${Slug.TokenId}`}
          element={<FocusedView />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </ConnectedWalletContainer>
  )
}
