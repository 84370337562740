import { sortBy, filter, some } from 'fp-ts/Array'
import { contramap, reverse } from 'fp-ts/Ord'
import * as N from 'fp-ts/number'
import { pipe } from 'fp-ts/function'

import { NftItem } from 'src/graphql/generated'

export enum BrainContractName {
  ASMBrainGenII = 'ASMBrainGenII',
  ASMBrain = 'ASMBrain',
}

export const isValidBrainContractName = (
  contractName: BrainContractName | string,
): contractName is BrainContractName => {
  const brains = Object.values(BrainContractName)
  return brains.includes(contractName as BrainContractName)
}

type GenusType = 'Unearthed' | 'Ascended'

const gen2GenusMapping = (genus: GenusType) => (nftItem: NftItem) => {
  const isGenusMatch = nftItem.metadata?.brain_genus === genus
  return isGenusMatch
}

const filterGen2Genus = (nftsList: NftItem[], genus: GenusType) => {
  const executor = filter(gen2GenusMapping(genus))
  return executor(nftsList)
}

export const checkGen2Genus = (nftsList: NftItem[], genus: GenusType) => {
  const executor = some(gen2GenusMapping(genus))
  return executor(nftsList)
}

export const setBrainsFilter = (nftsList: NftItem[], filterValue: string) => {
  switch (filterValue) {
    case '1': {
      return nftsList
    }
    case '2': {
      return nftsList?.filter(
        nftItem => nftItem.contractName === BrainContractName.ASMBrain,
      )
    }
    case '3': {
      return filterGen2Genus(nftsList, 'Unearthed')
    }
    case '4': {
      return filterGen2Genus(nftsList, 'Ascended')
    }
  }
  return nftsList
}

const byBlockNumber = pipe(
  N.Ord,
  reverse,
  contramap((nft: NftItem) => nft.blockNumber),
)
const sortByBlockNumber = sortBy([byBlockNumber])

const byTokenId = pipe(
  N.Ord,
  contramap((nft: NftItem) => nft.tokenId),
)
const sortByTokenId = sortBy([byTokenId])

const byTokenIdReverse = pipe(byTokenId, reverse)
const sortByTokenIdReverse = sortBy([byTokenIdReverse])

export const sortBrains = (
  nftsList: NftItem[],
  sortValue: string,
): NftItem[] => {
  switch (sortValue) {
    case '1': {
      return nftsList
    }
    case '2': {
      return sortByBlockNumber(nftsList)
    }
    case '3': {
      return sortByTokenId(nftsList)
    }
    case '4': {
      return sortByTokenIdReverse(nftsList)
    }
  }
  return nftsList
}
