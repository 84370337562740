import { Web3ReactProvider } from '@web3-react/core'

import { getLibrary } from 'src/utils/web3'
import { RPCUrlProvider, WithInitializedRPCUrl } from './hooks/useRPCUrl'
import { WalletProvider } from './hooks/useWallet'

const Web3Providers: React.FC = ({ children }) => {
  return (
    <RPCUrlProvider>
      <WithInitializedRPCUrl>
        <Web3ReactProvider getLibrary={getLibrary}>
          <WalletProvider>{children}</WalletProvider>
        </Web3ReactProvider>
      </WithInitializedRPCUrl>
    </RPCUrlProvider>
  )
}

export default Web3Providers
