import { formatBalance } from 'component-library/src/utils/number'
import { BigNumber } from 'ethers'
import { formatUnits, commify } from 'ethers/lib/utils'

const commifyAndTruncate = (stringValue: string, dp: number) => {
  const parsedValue = commify(stringValue)
  const [integer, decimal] = parsedValue.split('.', 2)
  if (dp === 0 || decimal === '0' || !decimal) {
    return integer
  }
  const truncatedDecimal = decimal.slice(0, dp)
  return `${integer}.${truncatedDecimal}`
}

export const formatTokenValue = (
  value: string | number | BigNumber,
  dp: number = 0,
  tokenDecimals: number = 18,
) => {
  if (typeof value === 'string') {
    return commifyAndTruncate(value, dp)
  }

  if (BigNumber.isBigNumber(value)) {
    return commifyAndTruncate(formatUnits(value, tokenDecimals), dp)
  }

  return formatBalance(value, dp)
}
