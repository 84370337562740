import { css, styled, Typography } from '@mui/material'

export const GradientText = styled(Typography)(
  ({ theme }) => css`
    background: ${theme.palette.gradients.rosePurple2};
    -webkit-text-fill-color: transparent;
    background-clip: text;
    width: fit-content;
  `,
) as typeof Typography
