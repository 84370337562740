import {
  Box,
  css,
  Step,
  StepLabel,
  Stepper,
  styled,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { BigNumber } from 'ethers/lib/ethers'

import { useLBAMigration } from 'src/hooks/useLBAMigration'
import { formatTokenValue } from 'src/utils/formatTokenValue'
import Button from 'component-library/src/components/button/Button'
import Chip from 'component-library/src/components/chip/Chip'
import Dialog, {
  DialogProps,
} from 'component-library/src/components/dialog/Dialog'
import backgroundImage from 'src/assets/images/asto_usdc_background_v2.webp'
import { useLBAMigrationPopup } from 'src/modules/lunaris-machine/hooks/useLBAMigrationPopup'
import transientPropCheck from 'src/utils/transientPropCheck'
import { reportEvent } from 'src/utils/ga'

const BREAKPOINT = 1200

interface MigrationModalProps extends DialogProps {
  handleClose: () => void
}

const StyledDialog = styled(Dialog)(
  ({ theme }) => css`
    .MuiPaper-root {
      background-image: linear-gradient(
          20deg,
          ${theme.palette.grey['900']} 50%,
          ${alpha(theme.palette.grey['900'], 0.8)} 80%,
          ${alpha(theme.palette.grey['900'], 0.6)} 100%
        ),
        linear-gradient(
          70deg,
          ${theme.palette.grey['900']} 50%,
          transparent 100%
        ),
        url(${backgroundImage});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: top right;
      max-width: 100%;
      min-height: 600px;
    }

    .MuiDialogContent-root {
      margin: 1rem 2rem;
    }

    .MuiStepLabel-root {
      justify-content: flex-start;
    }

    .MuiStepLabel-labelContainer {
    }

    .MuiStep-root {
      padding: 0px;
      align-self: flex-start;
    }
    .MuiStep-root:not(.Mui-completed) {
      filter: grayscale(1);
      opacity: 80%;
    }

    .MuiSvgIcon-root.Mui-completed {
      background-color: white;
      border-radius: 99px;
    }

    .MuiStepConnector-root {
      align-self: flex-start;
      margin-top: 10px;
      margin-right: 1rem;
    }

    ${theme.breakpoints.down(BREAKPOINT)} {
      .MuiStepConnector-root {
        display: none;
      }
    }
  `,
)

const StyledButton = styled(Button)`
  border-radius: 0px;
`

const StyledChip = styled(Chip)(
  ({ theme }) => css`
    &.MuiChip-root.MuiChip-colorSuccess.MuiChip-outlined {
      background: ${alpha(theme.palette.success.main, 0.18)};
    }
  `,
)

const MigrationStepTypography = styled(Typography)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacing(2)};
  `,
)

const MigrationStepTitle = ({ ...props }) => {
  return (
    <Typography
      variant="h2"
      color="text.secondary"
      fontSize="2rem"
      {...props}
    />
  )
}

interface MigrationStepProps {
  handleStep: () => void
  status?: 'loading' | 'complete' | 'error'
}

const MigrationStepOne = ({ handleStep, status }: MigrationStepProps) => {
  const { t } = useTranslation()

  reportEvent('page_view', {
    page_title: 'LBA Migration Step 1',
  })

  useEffect(() => {
    handleStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <MigrationStepTitle data-testid="lba-migration-step-1">
        {t('lba-migration.step-1.title')}
      </MigrationStepTitle>
      <MigrationStepTypography
        variant="body1"
        color="text.secondary"
        gutterBottom
      >
        {t('lba-migration.step-1.description')}
      </MigrationStepTypography>
      <StyledButton
        onClick={handleStep}
        loading={status === 'loading'}
        disabled={status === 'loading'}
      >
        {status !== 'error' && t('lba-migration.step-1.button')}
        {status === 'error' && t('lba-migration.retry-approval')}
      </StyledButton>
    </>
  )
}
const MigrateAmountBlock = ({ ...props }) => {
  const { t } = useTranslation()

  const {
    claimableLPAmountQuery,
    aeToMigrateQuery,
    signatureQuery,
    currentStep,
  } = useLBAMigration()

  const [lpAmount, setLPAmount] = useState('0')
  const [aeAmount, setAEAmount] = useState('0')

  // get/set lpAmount from smart contract
  useEffect(() => {
    if (currentStep !== 1) return
    if (!claimableLPAmountQuery.isSuccess) return
    if (claimableLPAmountQuery.data.isZero()) return

    const newLPAmount = formatTokenValue(claimableLPAmountQuery.data, 7)
    setLPAmount(newLPAmount)
  }, [claimableLPAmountQuery, currentStep])

  // get/set lpAmount from API
  useEffect(() => {
    if (currentStep !== 2) return
    if (!signatureQuery.isSuccess) return
    if (signatureQuery.data?.amount === '0') return

    const newLPAmount = formatTokenValue(
      BigNumber.from(signatureQuery.data?.amount || 0),
      7,
      18,
    )
    setLPAmount(newLPAmount)
  }, [signatureQuery, currentStep])

  // set aeToMigrate to state to persist it through the entire flow
  useEffect(() => {
    if (currentStep !== 1) return
    if (!aeToMigrateQuery.isSuccess) return
    if (aeToMigrateQuery.data === '0') return
    setAEAmount(aeToMigrateQuery.data)
  }, [aeToMigrateQuery, currentStep])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        color: 'text.secondary',
      }}
      {...props}
    >
      <Box sx={{ flex: '1' }}>
        <Typography variant="h5" color="grey.300" fontWeight="regular">
          {t('lba-migration.lp-to-migrate')}
        </Typography>
        <Typography variant="h3" paragraph>
          {lpAmount} LP
        </Typography>
      </Box>
      {aeAmount !== '0' && (
        <Box sx={{ flex: '1' }}>
          <Typography variant="h5" color="grey.300" fontWeight="regular">
            {t('lba-migration.ae-to-migrate')}
          </Typography>
          <Typography variant="h3" paragraph>
            {aeAmount} Æ
          </Typography>
        </Box>
      )}
    </Box>
  )
}

const MigrationStepTwo = ({ handleStep, status }: MigrationStepProps) => {
  const { t } = useTranslation()

  reportEvent('page_view', {
    page_title: 'LBA Migration Step 2',
  })

  useEffect(() => {
    handleStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <MigrationStepTitle data-testid="lba-migration-step-2">
        {t('lba-migration.step-2.title')}
      </MigrationStepTitle>
      <MigrationStepTypography
        variant="body1"
        color="text.secondary"
        gutterBottom
      >
        {t('lba-migration.step-2.description')}
      </MigrationStepTypography>
      <MigrateAmountBlock />
      <StyledButton
        onClick={handleStep}
        loading={status === 'loading'}
        disabled={status === 'loading'}
      >
        {status !== 'error' && t('lba-migration.step-2.button')}
        {status === 'error' && t('lba-migration.retry-approval')}
      </StyledButton>
    </>
  )
}

const MigrationStepThree = ({ handleStep, status }: MigrationStepProps) => {
  const { t } = useTranslation()

  reportEvent('page_view', {
    page_title: 'LBA Migration Step 3',
  })

  useEffect(() => {
    handleStep()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <MigrationStepTitle data-testid="lba-migration-step-3">
        {t('lba-migration.step-3.title')}
      </MigrationStepTitle>
      <MigrationStepTypography
        variant="body1"
        color="text.secondary"
        gutterBottom
      >
        <Trans i18nKey="lba-migration.step-3.description" />
      </MigrationStepTypography>
      <MigrateAmountBlock />
      <StyledButton
        onClick={handleStep}
        loading={status === 'loading'}
        disabled={status === 'loading'}
      >
        {status !== 'error' && t('lba-migration.step-3.button')}
        {status === 'error' && t('lba-migration.retry-approval')}
      </StyledButton>
    </>
  )
}

const MigrationStepComplete = ({ handleStep }: MigrationStepProps) => {
  const { t } = useTranslation()
  return (
    <>
      <MigrationStepTitle data-testid="lba-migration-step-4">
        {t('lba-migration.step-4.title')}
      </MigrationStepTitle>
      <MigrationStepTypography
        variant="body1"
        color="text.secondary"
        gutterBottom
      >
        {t('lba-migration.step-4.description')}
      </MigrationStepTypography>
      <MigrateAmountBlock />
      <Button data-testid="migration-back-to-dashboard" onClick={handleStep}>
        {t('lba-migration.step-4.button')}
      </Button>
    </>
  )
}

const LabelDivider = styled('div')`
  flex: 1 0 0;
  background: #4a494f;
  margin-top: 0.5rem;
  margin-left: 0.5em;
  height: 1px;
  border: 0px;
`

const LabelDividerMobile = styled(
  'div',
  transientPropCheck,
)(
  ({ $isLast }: { $isLast: boolean }) => css`
    background: #4a494f;
    padding-bottom: 0px;
    margin-right: 1rem;
    height: 100%;
    width: 1px;
    margin-left: 11px;
    border: 0px;

    ${$isLast &&
    css`
      visibility: hidden;
    `}
  `,
)

interface ModalStepProps {
  name: string
  currentStep: number
  stepIndex: number
  totalSteps: number
  status?: 'loading' | 'complete' | 'error'
}

const ModalStepDesktop: React.FC<ModalStepProps> = ({
  name,
  currentStep,
  stepIndex,
  totalSteps,
  status,
  ...props
}) => {
  return (
    <Step key={name} completed={status === 'complete'} {...props}>
      <StepLabel>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box mr={1}>
            <Typography variant="body2" color="text.secondary">
              {`Step ${stepIndex + 1}`}
            </Typography>
          </Box>
          {stepIndex + 1 !== totalSteps && <LabelDivider />}
        </Box>
      </StepLabel>
      <Typography
        color="text.secondary"
        sx={{ whiteSpace: 'pre', minHeight: '2.5em' }}
        variant="h4"
        mt={1}
      >
        {name}
      </Typography>
      <Box
        sx={{
          height: '2rem',
          width: '100%',
        }}
      >
        {status === 'loading' && (
          <Box mt={2}>
            <StyledChip
              color="success"
              variant="outlined"
              label={'Loading'}
            ></StyledChip>
          </Box>
        )}
        {status === 'complete' && (
          <Box mt={2}>
            <StyledChip
              color="success"
              variant="outlined"
              label={'Complete'}
            ></StyledChip>
          </Box>
        )}
        {status === 'error' && (
          <Box mt={2}>
            <StyledChip
              color="warning"
              variant="outlined"
              label={'Error'}
            ></StyledChip>
          </Box>
        )}
      </Box>
    </Step>
  )
}

const ModalStepMobile: React.FC<ModalStepProps> = ({
  name,
  currentStep,
  stepIndex,
  totalSteps,
  status,
  ...props
}) => {
  return (
    <Step key={name} completed={status === 'complete'} {...props}>
      <StepLabel>
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Box mr={1}>
            <Typography color="text.secondary" variant="h4" mt={1}>
              {name}
            </Typography>
          </Box>
        </Box>
      </StepLabel>
      <Box
        sx={{
          height: '4rem',
          minHeight: '2rem',
          width: '100%',
          flexDirection: 'row',
          display: 'flex',
        }}
      >
        <LabelDividerMobile $isLast={stepIndex === totalSteps - 1} />
        {status === 'loading' && (
          <Box>
            <StyledChip
              color="success"
              variant="outlined"
              label={'Loading'}
            ></StyledChip>
          </Box>
        )}
        {status === 'complete' && (
          <Box>
            <StyledChip
              color="success"
              variant="outlined"
              label={'Complete'}
            ></StyledChip>
          </Box>
        )}
        {status === 'error' && (
          <Box>
            <StyledChip
              color="warning"
              variant="outlined"
              label={'Error'}
            ></StyledChip>
          </Box>
        )}
      </Box>
    </Step>
  )
}

const ModalStep: React.FC<ModalStepProps> = props => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(BREAKPOINT))
  return isSmall ? (
    <ModalStepMobile {...props} />
  ) : (
    <ModalStepDesktop {...props} />
  )
}

const MigrationModal: React.FC<MigrationModalProps> = ({
  handleClose,
  ...props
}) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down(BREAKPOINT))
  const {
    currentStep,
    approveContractMutation,
    claimMutation,
    migrateMutation,
    // isLoadingInitial,
  } = useLBAMigration()

  const currentStepMutation = useMemo(() => {
    switch (currentStep) {
      case 0:
        return approveContractMutation
      case 1:
        return claimMutation
      case 2:
        return migrateMutation
    }
  }, [approveContractMutation, currentStep, migrateMutation, claimMutation])

  const stepStatus = (stepIndex: number) => {
    const hasCompletedStep = (stepIndex: number) => {
      return currentStep > stepIndex
    }

    const isCurrentStep = (stepIndex: number) => {
      return currentStep === stepIndex
    }

    if (hasCompletedStep(stepIndex)) {
      return 'complete'
    }
    if (isCurrentStep(stepIndex) && currentStepMutation?.isLoading) {
      return 'loading'
    }
    if (isCurrentStep(stepIndex) && currentStepMutation?.isError) {
      return 'error'
    }
  }

  const handleStepOne = () => {
    approveContractMutation.mutate()
  }

  const handleStepTwo = () => {
    claimMutation.mutate()
  }

  const handleStepThree = () => {
    migrateMutation.mutate()
  }

  const handleStepFour = () => {
    handleClose()
  }

  return (
    <StyledDialog {...props} data-testid="lba-migration-popup">
      <Box
        sx={{
          width: '100%',
          maxWidth: '600px',
          alignSelf: 'flex-start',
          flex: '1',
        }}
      >
        {currentStep === 0 && (
          <MigrationStepOne handleStep={handleStepOne} status={stepStatus(0)} />
        )}
        {currentStep === 1 && (
          <MigrationStepTwo handleStep={handleStepTwo} status={stepStatus(1)} />
        )}
        {currentStep === 2 && (
          <MigrationStepThree
            handleStep={handleStepThree}
            status={stepStatus(2)}
          />
        )}
        {currentStep === 3 && (
          <MigrationStepComplete handleStep={handleStepFour} />
        )}
      </Box>
      <Box sx={{ width: '100%', justifySelf: 'flex-end' }}>
        <Stepper
          activeStep={currentStep}
          orientation={isSmall ? 'vertical' : 'horizontal'}
        >
          <ModalStep
            name={t('lba-migration.step-1.title')}
            currentStep={currentStep}
            stepIndex={0}
            status={stepStatus(0)}
            totalSteps={3}
          />
          <ModalStep
            name={t('lba-migration.step-2.title')}
            currentStep={currentStep}
            stepIndex={1}
            status={stepStatus(1)}
            totalSteps={3}
          />
          <ModalStep
            name={t('lba-migration.step-3.title')}
            currentStep={currentStep}
            stepIndex={2}
            status={stepStatus(2)}
            totalSteps={3}
          />
        </Stepper>
      </Box>
    </StyledDialog>
  )
}

export const LBAMigrationPopup = () => {
  const { open, closeLBAMigrationPopup } = useLBAMigrationPopup()

  const handleClose = () => {
    closeLBAMigrationPopup()
  }

  return (
    <MigrationModal
      open={open}
      handleClose={handleClose}
      onClose={handleClose}
    />
  )
}
