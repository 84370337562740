import Grid from 'component-library/src/components/grid/Grid'
import { AssetCard, AssetModel } from './AssetCard'

interface AssetListProps {
  assets: AssetModel[]
}

export const AssetList = ({ assets = [] }: AssetListProps) => {
  return (
    <Grid container spacing={1}>
      {assets.map(asset => (
        <Grid
          item
          key={`${asset.id}_${asset.blockNumber}`}
          {...{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }}
        >
          <AssetCard
            id={asset.id}
            name={asset.name}
            imageURL={asset.imageURL}
            externalURL={asset.externalURL}
          />
        </Grid>
      ))}
    </Grid>
  )
}
