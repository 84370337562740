import { styled, css, alpha } from '@mui/material/styles'
import { ButtonBase, Typography } from '@mui/material'

import { useWallet } from 'src/hooks/useWallet'
import { ConnectorNames } from 'src/utils/connectors'
import Dialog from 'component-library/src/components/dialog/Dialog'
import { useWalletsDialog } from 'src/hooks/store/useWalletsDialog'

import coinbaseSrc from 'src/assets/images/coinbase.png'
import metamaskSrc from 'src/assets/images/metamask.png'
import walletConnectSrc from 'src/assets/images/walletconnect.png'
import { useWeb3React } from 'src/hooks/useWeb3React'
import { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { reportEvent } from 'src/utils/ga'

const BREAKPOINT = 350

const ProvidersContainer = styled('div')(
  ({ theme }) =>
    css`
      display: flex;
      margin-left: auto;
      margin-right: auto;
      justify-content: center;
      margin: ${theme.spacing(4, 0, 0)};

      ${theme.breakpoints.down(BREAKPOINT)} {
        align-items: center;
        flex-direction: column;
      }
    `,
)

const ProviderItem = styled('div')(
  ({ theme }) =>
    css`
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      margin: ${theme.spacing(0, 4, 0, 0)};

      ${theme.breakpoints.down(BREAKPOINT)} {
        margin: ${theme.spacing(0, 0, 3)};
      }

      &:last-child {
        margin: 0;
      }
    `,
)

const Button = styled(ButtonBase)(
  ({ theme }) => css`
    display: flex;
    min-height: 81px;
    border-radius: 6px;
    justify-content: center;
    padding: ${theme.spacing(1)};
    background-color: ${theme.palette.grey[700]};

    img {
      width: 65px;
    }
  `,
)

const Warning = styled('div')(
  ({ theme }) => css`
    background: ${alpha(theme.palette.error.main, 0.1)};
    border: 1px solid ${theme.palette.error.main};
    padding: 1rem;
    border-radius: 0.5rem;
    margin-top: 0.5rem;
    max-width: 470px;
  `,
)

const connectorImageMap = {
  [ConnectorNames.MetaMask]: metamaskSrc,
  [ConnectorNames.Coinbase]: coinbaseSrc,
  [ConnectorNames.WalletConnect]: walletConnectSrc,
}

const ConnectWalletDialog = () => {
  const { connectWallet } = useWallet()
  const { isWalletsDialogOpen, closeWalletsDialog, redirect, clearRedirect } =
    useWalletsDialog()
  const { account } = useWeb3React()
  const { t } = useTranslation()
  const navigate = useNavigate()
  // Memoizing due to https://github.com/remix-run/react-router/issues/7634
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoNavigate = useMemo(() => navigate, [])

  useEffect(() => {
    if (account && redirect) {
      memoNavigate(redirect)
      clearRedirect()
    }
  }, [account, redirect, memoNavigate, clearRedirect])

  const generateConnectWalletHandler =
    (connectorName: ConnectorNames) => () => {
      reportEvent('wallet_connected', { wallet: connectorName })

      closeWalletsDialog()
      connectWallet(connectorName)
    }

  // @ts-ignore
  const hasMultipleWallets = !!window?.ethereum?.overrideIsMetaMask

  return (
    <Dialog open={isWalletsDialogOpen} onClose={closeWalletsDialog}>
      <Typography
        variant="h3"
        sx={{ mb: 1 }}
        textAlign="center"
        color="textSecondary"
      >
        {t('connect-wallet.title')}
      </Typography>
      <Typography
        variant="body2"
        sx={{ mb: 1 }}
        textAlign="center"
        color="textSecondary"
      >
        {t('connect-wallet.description')}
      </Typography>
      {hasMultipleWallets && (
        <Warning>
          <Typography variant="body2" textAlign="center" color="textSecondary">
            {t('connect-wallet.multiple-wallet-warning')}
          </Typography>
        </Warning>
      )}

      <ProvidersContainer>
        {Object.values(ConnectorNames).map(key => (
          <ProviderItem key={key}>
            <Button
              data-cy={`connect-wallet-${key}`}
              aria-label={`Connect to ${key}`}
              onClick={generateConnectWalletHandler(key)}
            >
              <img
                src={connectorImageMap[key as ConnectorNames]}
                alt={`${key} logo`}
              />
            </Button>
            <Typography variant="subtitle2" sx={{ color: 'grey.500', mt: 0.5 }}>
              {key}
            </Typography>
          </ProviderItem>
        ))}
      </ProvidersContainer>
    </Dialog>
  )
}

export default ConnectWalletDialog
