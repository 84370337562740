/* eslint-disable camelcase */
import { useMemo } from 'react'

import { ChainENVs, CHAIN_ENV } from 'src/utils/web3'
import { Contract } from 'ethers'
import { useWeb3LibraryProvider } from '../useWeb3LibraryProvider'

import localhostABI from 'smart-contracts/abi/localhost.json'
//
import goerliStagingABI from 'smart-contracts/abi/goerli-staging.json'
import goerliABI from 'smart-contracts/abi/goerli.json'
import mainnetExternalABI from 'smart-contracts/abi/mainnet-external.json'

export const addresses: Record<ChainENVs, string> = {
  localhost: localhostABI.contracts.LPTokenTest.address,
  mainnet: mainnetExternalABI.contracts.LPToken.address,
  goerliStaging: goerliStagingABI.contracts.LPTokenTest.address,
  goerli: goerliABI.contracts.LPTokenTest.address,
  kovan: '',
}

export const address = addresses[CHAIN_ENV]
const abi = [
  'function approve(address spender, uint256 amount)',
  'function allowance(address owner, address spender) view returns (uint256 allowance)',
  'function balanceOf(address account) view returns (uint256 balance)',
]

const useLPTokenContract = () => {
  const rpcProvider = useWeb3LibraryProvider()

  const contract = useMemo(() => {
    return new Contract(address, abi, rpcProvider)
  }, [rpcProvider])

  return contract
}

export default useLPTokenContract
