import { NftItem } from 'src/graphql/generated'
import create from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'

export const filterOptions = new Map<string, string>([
  ['1', 'All'],
  ['2', 'Genesis'],
  ['3', 'Gen II (Unearthed)'],
  ['4', 'Gen II (Ascended)'],
])

export const sortOptions = new Map<string, string>([
  ['1', 'All'],
  ['2', 'Latest'],
  ['3', 'Lowest to highest'],
  ['4', 'Highest to lowest'],
])

interface BrainsCount {
  genesisCount: number
  genIICount: number
}

interface GenusFilters {
  enableUnearthed: boolean
  enableAscended: boolean
}
interface InventoryStoreState {
  currentPage: number
  changePage: (pageNumber: number) => void
  pageLastKey?: string | null
  setPageLastKey: (key?: string | null) => void
  lastAvailablePage: number
  setLastAvailablePage: (pageNumber: number) => void
  filterValue: string
  changeFilter: (filterValue: string) => void
  sortValue: string
  changeSort: (sortValue: string) => void
  brainsCount: BrainsCount
  setBrainsCount: (countObj: BrainsCount) => void
  genusFilters: GenusFilters
  setGenusFilters: (countObj: GenusFilters) => void
  fullPageList: NftItem[]
  setFullPageList: (fullPageList: NftItem[]) => void
}

const useInventoryStore = create(
  subscribeWithSelector<InventoryStoreState>(set => ({
    currentPage: 0,
    changePage: (pageNumber: number) => set({ currentPage: pageNumber }),
    lastAvailablePage: 0,
    setLastAvailablePage: (pageNumber: number) =>
      set({ lastAvailablePage: pageNumber }),
    filterValue: '1',
    changeFilter: (filterValue: string) => set({ filterValue, currentPage: 0 }),
    sortValue: '1',
    changeSort: (sortValue: string) => set({ sortValue, currentPage: 0 }),
    brainsCount: {
      genesisCount: 0,
      genIICount: 0,
    },
    setBrainsCount: brainsCount => set({ brainsCount }),
    genusFilters: {
      enableUnearthed: false,
      enableAscended: false,
    },
    setGenusFilters: genusFilters => set({ genusFilters }),
    fullPageList: [],
    setFullPageList: (fullPageList: NftItem[]) => set({ fullPageList }),
    setPageLastKey: pageLastKey => set({ pageLastKey }),
  })),
)

export default useInventoryStore
