import { styled, Typography } from '@mui/material'
import Button from 'component-library/src/components/button/Button'
import { Trans } from 'react-i18next'
import { ReactComponent as ArrowsReload } from 'src/assets/icons/arrows-reload.svg'

const Container = styled('div')`
  display: flex;
  position: relative;
  border-radius: 8px;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 8px;
  width: 100%;
`

interface ErrorLoadingNFTsProps {
  onRefreshClick: () => void
}

export const ErrorLoadingNFTs = ({ onRefreshClick }: ErrorLoadingNFTsProps) => {
  return (
    <Container data-testid="error-loading-nfts">
      <Typography
        variant="h3"
        color={'white'}
        sx={{
          fontFamily: 'fontFamilySecondary',
        }}
        mb={2}
      >
        <Trans i18nKey="inventory.error.title">An error has occurred</Trans>
      </Typography>
      <Typography variant="body1" mb={6}>
        <Trans i18nKey="inventory.error.description">
          Failed to load your assets in the Inventory, please refresh your
          browser.
        </Trans>
      </Typography>
      <Button onClick={onRefreshClick} size="large">
        <Trans i18nKey="inventory.error.refresh">Refresh Page</Trans>&nbsp;
        <ArrowsReload />
      </Button>
    </Container>
  )
}
