import config from './config'

export const gqlFetcher = async <TData, TVariables>(
  query: string,
  variables: TVariables,
): Promise<TData> => {
  const res = await fetch(config.REACT_APP_GENOME_MINING_GQL_API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })
  const json = await res.json()

  if (json.errors) {
    const { message } = json.errors[0]
    throw new Error(message)
  }

  return json.data
}
