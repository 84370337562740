import { useQuery } from 'react-query'
import { useWeb3React } from 'src/hooks/useWeb3React'
import useStakingContract from 'src/hooks/contracts/useStakingContract'
import { formatEther } from 'ethers/lib/utils'
import { StakingToken } from 'src/types/Token'

export enum StakingContractTokenId {
  ASTO = 0,
  LP = 1,
}

const tokenNametoStakingTokenId = (token: StakingToken) => {
  switch (token) {
    case 'asto':
      return StakingContractTokenId.ASTO
    case 'lp':
      return StakingContractTokenId.LP
  }
}

const useStaking = (token: StakingToken) => {
  const { account } = useWeb3React()
  const stakingContract = useStakingContract()
  const tokenId = tokenNametoStakingTokenId(token)

  const stakingHistory = useQuery(
    ['staking', 'history', tokenId, account],
    async () => {
      const now = Date.now()
      const stakesHistory = await stakingContract.getHistory(
        tokenId,
        account!,
        now,
      )
      const formattedStakesHistory = stakesHistory.map(stake => {
        return {
          time: stake.time.toNumber(),
          amount: formatEther(stake.amount),
        }
      })
      return formattedStakesHistory
    },
    {
      enabled: !!account,
      placeholderData: [],
      onError: () => {
        return []
      },
    },
  )

  return stakingHistory
}
export default useStaking
